<template>
  <div class="intro-y mt-8 flex items-center">
    <h2 class="mr-auto text-lg font-medium">Update Profile</h2>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Profile Menu -->
    <div
      class="col-span-12 flex flex-col-reverse lg:col-span-4 lg:block 2xl:col-span-3"
    >
      <div class="intro-y box mt-5">
        <div class="relative flex items-center p-5">
          <div class="image-fit h-12 w-12">
            <img
              alt="Gromura - SCM"
              class="rounded-full"
              :src="$f()[0].users[0].image"
            />
          </div>
          <div class="ml-4 mr-auto">
            <div class="text-base font-medium">
              {{ $f()[0].users[0].name }}
            </div>
            <div class="text-slate-500">{{ $f()[0].jobs[0] }}</div>
          </div>
          <Dropdown>
            <DropdownToggle tag="a" class="block h-5 w-5" href="javascript:;">
              <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
            </DropdownToggle>
            <DropdownMenu class="w-56">
              <DropdownContent>
                <DropdownHeader> Export Options</DropdownHeader>
                <DropdownDivider />
                <DropdownItem>
                  <ActivityIcon class="mr-2 h-4 w-4" />
                  English
                </DropdownItem>
                <DropdownItem>
                  <BoxIcon class="mr-2 h-4 w-4" />
                  Indonesia
                  <div
                    class="ml-auto rounded-full bg-danger px-1 text-xs text-white"
                  >
                    10
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <LayoutIcon class="mr-2 h-4 w-4" />
                  English
                </DropdownItem>
                <DropdownItem>
                  <SidebarIcon class="mr-2 h-4 w-4" />
                  Indonesia
                </DropdownItem>
                <DropdownDivider />
                <DropdownFooter>
                  <button type="button" class="btn btn-primary py-1 px-2">
                    Settings
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary ml-auto py-1 px-2"
                  >
                    View Profile
                  </button>
                </DropdownFooter>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="border-t border-slate-200/60 p-5 dark:border-darkmode-400">
          <a class="flex items-center font-medium text-primary" href="">
            <ActivityIcon class="mr-2 h-4 w-4" /> Personal Information
          </a>
          <a class="mt-5 flex items-center" href="">
            <BoxIcon class="mr-2 h-4 w-4" /> Account Settings
          </a>
          <a class="mt-5 flex items-center" href="">
            <LockIcon class="mr-2 h-4 w-4" /> Change Password
          </a>
          <a class="mt-5 flex items-center" href="">
            <SettingsIcon class="mr-2 h-4 w-4" /> User Settings
          </a>
        </div>
        <div class="border-t border-slate-200/60 p-5 dark:border-darkmode-400">
          <a class="flex items-center" href="">
            <ActivityIcon class="mr-2 h-4 w-4" /> Email Settings
          </a>
          <a class="mt-5 flex items-center" href="">
            <BoxIcon class="mr-2 h-4 w-4" /> Saved Credit Cards
          </a>
          <a class="mt-5 flex items-center" href="">
            <LockIcon class="mr-2 h-4 w-4" /> Social Networks
          </a>
          <a class="mt-5 flex items-center" href="">
            <SettingsIcon class="mr-2 h-4 w-4" /> Tax Information
          </a>
        </div>
        <div
          class="flex border-t border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <button type="button" class="btn btn-primary py-1 px-2">
            New Group
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary ml-auto py-1 px-2"
          >
            New Quick Link
          </button>
        </div>
      </div>
    </div>
    <!-- END: Profile Menu -->
    <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
      <!-- BEGIN: Display Information -->
      <div class="intro-y box lg:mt-5">
        <div
          class="flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <h2 class="mr-auto text-base font-medium">Display Information</h2>
        </div>
        <div class="p-5">
          <div class="flex flex-col flex-col-reverse xl:flex-row">
            <div class="mt-6 flex-1 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 2xl:col-span-6">
                  <div>
                    <label for="update-profile-form-1" class="form-label"
                      >Display Name</label
                    >
                    <input
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      placeholder="Input text"
                      :value="$f()[0].users[0].name"
                      disabled
                    />
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-2" class="form-label"
                      >Nearest MRT Station</label
                    >
                    <TomSelect
                      id="update-profile-form-2"
                      v-model="select"
                      class="w-full"
                    >
                      <option value="1">Admiralty</option>
                      <option value="2">Aljunied</option>
                      <option value="3">Ang Mo Kio</option>
                      <option value="4">Bartley</option>
                      <option value="5">Beauty World</option>
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-12 2xl:col-span-6">
                  <div class="mt-3 2xl:mt-0">
                    <label for="update-profile-form-3" class="form-label"
                      >Postal Code</label
                    >
                    <TomSelect
                      id="update-profile-form-3"
                      v-model="select"
                      class="w-full"
                    >
                      <option value="1">
                        018906 - 1 STRAITS BOULEVARD SINGA...
                      </option>
                      <option value="2">
                        018910 - 5A MARINA GARDENS DRIVE...
                      </option>
                      <option value="3">
                        018915 - 100A CENTRAL BOULEVARD...
                      </option>
                      <option value="4">
                        018925 - 21 PARK STREET MARINA...
                      </option>
                      <option value="5">
                        018926 - 23 PARK STREET MARINA...
                      </option>
                    </TomSelect>
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-4" class="form-label"
                      >Phone Number</label
                    >
                    <input
                      id="update-profile-form-4"
                      type="text"
                      class="form-control"
                      placeholder="Input text"
                      value="65570828"
                    />
                  </div>
                </div>
                <div class="col-span-12">
                  <div class="mt-3">
                    <label for="update-profile-form-5" class="form-label"
                      >Address</label
                    >
                    <textarea
                      id="update-profile-form-5"
                      class="form-control"
                      placeholder="Adress"
                    >
10 Anson Road, International Plaza, #10-11, 079903 Singapore, Singapore</textarea
                    >
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary mt-3 w-20">
                Save
              </button>
            </div>
            <div class="mx-auto w-52 xl:mr-0 xl:ml-6">
              <div
                class="rounded-md border-2 border-dashed border-slate-200/60 p-5 shadow-sm dark:border-darkmode-400"
              >
                <div
                  class="image-fit zoom-in relative mx-auto h-40 cursor-pointer"
                >
                  <img
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="$f()[0].users[0].image"
                  />
                  <Tippy
                    tag="div"
                    content="Remove this profile photo?"
                    class="absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                  >
                    <xIcon class="h-4 w-4" />
                  </Tippy>
                </div>
                <div class="relative mx-auto mt-5 cursor-pointer">
                  <button type="button" class="btn btn-primary w-full">
                    Change Photo
                  </button>
                  <input
                    type="file"
                    class="absolute top-0 left-0 h-full w-full opacity-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Display Information -->
      <!-- BEGIN: Personal Information -->
      <div class="intro-y box mt-5">
        <div
          class="flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <h2 class="mr-auto text-base font-medium">Personal Information</h2>
        </div>
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12 xl:col-span-6">
              <div>
                <label for="update-profile-form-6" class="form-label"
                  >Email</label
                >
                <input
                  id="update-profile-form-6"
                  type="text"
                  class="form-control"
                  placeholder="Input text"
                  :value="$f()[0].users[0].email"
                  disabled
                />
              </div>
              <div class="mt-3">
                <label for="update-profile-form-7" class="form-label"
                  >Name</label
                >
                <input
                  id="update-profile-form-7"
                  type="text"
                  class="form-control"
                  placeholder="Input text"
                  :value="$f()[0].users[0].name"
                  disabled
                />
              </div>
              <div class="mt-3">
                <label for="update-profile-form-8" class="form-label"
                  >ID Type</label
                >
                <select id="update-profile-form-8" class="form-select">
                  <option>IC</option>
                  <option>FIN</option>
                  <option>Passport</option>
                </select>
              </div>
              <div class="mt-3">
                <label for="update-profile-form-9" class="form-label"
                  >ID Number</label
                >
                <input
                  id="update-profile-form-9"
                  type="text"
                  class="form-control"
                  placeholder="Input text"
                  value="357821204950001"
                />
              </div>
            </div>
            <div class="col-span-12 xl:col-span-6">
              <div class="mt-3 xl:mt-0">
                <label for="update-profile-form-10" class="form-label"
                  >Phone Number</label
                >
                <input
                  id="update-profile-form-10"
                  type="text"
                  class="form-control"
                  placeholder="Input text"
                  value="65570828"
                />
              </div>
              <div class="mt-3">
                <label for="update-profile-form-11" class="form-label"
                  >Address</label
                >
                <input
                  id="update-profile-form-11"
                  type="text"
                  class="form-control"
                  placeholder="Input text"
                  value="10 Anson Road, International Plaza, #10-11, 079903 Singapore, Singapore"
                />
              </div>
              <div class="mt-3">
                <label for="update-profile-form-12" class="form-label"
                  >Bank Name</label
                >
                <TomSelect
                  id="update-profile-form-12"
                  v-model="select"
                  class="w-full"
                >
                  <option value="1">SBI - STATE BANK OF INDIA</option>
                  <option value="2">CITI BANK - CITI BANK</option>
                </TomSelect>
              </div>
              <div class="mt-3">
                <label for="update-profile-form-13" class="form-label"
                  >Bank Account</label
                >
                <input
                  id="update-profile-form-13"
                  type="text"
                  class="form-control"
                  placeholder="Input text"
                  value="DBS Current 011-903573-0"
                />
              </div>
            </div>
          </div>
          <div class="mt-4 flex justify-end">
            <button type="button" class="btn btn-primary mr-auto w-20">
              Save
            </button>
            <a href="" class="flex items-center text-danger">
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete Account
            </a>
          </div>
        </div>
      </div>
      <!-- END: Personal Information -->
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const select = ref('1')
</script>
