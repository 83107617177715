<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-4 md:mt-8">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-base md:text-lg font-medium">Laporan Harian</h2>
            <div @click="getSummaryData" class="cursor-pointer ml-auto flex items-center text-primary">
              <RefreshCcwIcon class="mr-3 h-4 w-4" /> Perbarui Data
            </div>
          </div>
          <div class="mt-5 grid grid-cols-12 gap-x-2 gap-y-6 md:gap-6">
            <div class="intro-y col-span-6 xl:col-span-3">
              <div class="report-box zoom-in">
                <div
                  v-if="activeData.sales_total !== undefined"
                  class="box py-2 px-3 md:p-5"
                >
                  <div class="flex">
                    <div class="text-sm md:text-base text-slate-500">
                      Penjualan
                    </div>
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator cursor-pointer bg-success"
                        :class="
                          activeData.sales_total.is_up == true
                            ? 'bg-success'
                            : 'bg-danger'
                        "
                        :content="`${activeData.sales_total.percent} ${
                          activeData.sales_total.is_up == true
                            ? 'Higher'
                            : 'Lower'
                        } than last month`"
                      >
                        {{ activeData.sales_total.percent}}
                        <ChevronUpIcon v-if="activeData.sales_total.is_up == true" class="ml-0.5 h-4 w-4" />
                        <ChevronDownIcon v-else class="ml-0.5 h-4 w-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="mt-3 md:mt-6">
                    <!-- <span class="text-md md:text-2xl font-medium leading-8">Rp</span> -->
                    <Currency
                      :total-in-string="activeData.sales_total.total.toString()"
                      :with-prefix="false"
                      class="text-lg md:text-2xl font-medium leading-8"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="intro-y col-span-6 xl:col-span-3">
              <div class="report-box zoom-in">
                <div
                  v-if="activeData.gross_profit !== undefined"
                  class="box py-2 px-3 md:p-5"
                >
                  <div class="flex">
                    <div class="text-sm md:text-base text-slate-500">
                      Modal
                    </div>
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator cursor-pointer bg-success"
                        :class="
                          activeData.gross_profit.is_up == true
                            ? 'bg-success'
                            : 'bg-danger'
                        "
                        :content="`${activeData.gross_profit.percent} ${
                          activeData.gross_profit.is_up == true
                            ? 'Higher'
                            : 'Lower'
                        } than last month`"
                      >
                        {{ activeData.gross_profit.percent}}
                        <ChevronUpIcon v-if="activeData.gross_profit.is_up == true" class="ml-0.5 h-4 w-4" />
                        <ChevronDownIcon v-else class="ml-0.5 h-4 w-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="mt-3 md:mt-6  text-lg md:text-2xl font-medium leading-8">
                    <Currency
                      :total-in-string="activeData.gross_profit.total.toString()"
                      :with-prefix="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="intro-y col-span-6 xl:col-span-3">
              <div class="report-box zoom-in">
                <div
                  v-if="activeData.net_profit !== undefined"
                  class="box py-2 px-3 md:p-5"
                >
                  <div class="flex">
                    <div class="text-sm md:text-base text-slate-500">
                      Profit
                    </div>
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator cursor-pointer bg-success"
                        :class="
                          activeData.net_profit.is_up == true
                            ? 'bg-success'
                            : 'bg-danger'
                        "
                        :content="`${activeData.net_profit.percent} ${
                          activeData.net_profit.is_up == true
                            ? 'Higher'
                            : 'Lower'
                        } than last month`"
                      >
                        {{ activeData.net_profit.percent}}
                        <ChevronUpIcon v-if="activeData.net_profit.is_up == true" class="ml-0.5 h-4 w-4" />
                        <ChevronDownIcon v-else class="ml-0.5 h-4 w-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="mt-3 md:mt-6  text-lg md:text-2xl font-medium leading-8">
                    <Currency
                      :total-in-string="activeData.net_profit.total.toString()"
                      :with-prefix="false"
                    />
                  </div>
                </div>
          
              </div>
            </div>
            <div class="intro-y col-span-6 xl:col-span-3">
              <div class="report-box zoom-in">
                <div
                  v-if="activeData.transaction_total !== undefined"
                  class="box py-2 px-3 md:p-5"
                >
                  <div class="flex">
                    <div class="text-sm md:text-base text-slate-500">
                      Transaksi
                    </div>
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator cursor-pointer bg-success"
                        :class="
                          activeData.transaction_total.is_up == true
                            ? 'bg-success'
                            : 'bg-danger'
                        "
                        :content="`${activeData.transaction_total.percent} ${
                          activeData.transaction_total.is_up == true
                            ? 'Higher'
                            : 'Lower'
                        } than last month`"
                      >
                        {{ activeData.transaction_total.percent}}
                        <ChevronUpIcon v-if="activeData.transaction_total.is_up == true" class="ml-0.5 h-4 w-4" />
                        <ChevronDownIcon v-else class="ml-0.5 h-4 w-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="mt-3 md:mt-6  text-lg md:text-2xl font-medium leading-8">
                    <Currency
                      :total-in-string="activeData.transaction_total.total.toString()"
                      :with-prefix="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 mt-8 lg:col-span-12">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Laporan Penjualan</h2>
            <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="salesReportFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control box pl-10 sm:w-56"
              />
            </div>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div class="flex flex-col md:flex-row md:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl"
                  >
                    Rp 1.500.000
                  </div>
                  <div class="mt-0.5 text-slate-500">Bulan Ini</div>
                </div>
                <div
                  class="mx-4 h-12 w-px border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"
                ></div>
                <div>
                  <div class="text-lg font-medium text-slate-500 xl:text-xl">
                    Rp 1.300.000
                  </div>
                  <div class="mt-0.5 text-slate-500">Bulan Kemarin</div>
                </div>
              </div>
              <Dropdown class="mt-5 md:ml-auto md:mt-0">
                <DropdownToggle class="btn btn-outline-secondary font-normal">
                  Filter by Category
                  <ChevronDownIcon class="ml-2 h-4 w-4" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent class="h-32 overflow-y-auto">
                    <DropdownItem>PC & Laptop</DropdownItem>
                    <DropdownItem>Smartphone</DropdownItem>
                    <DropdownItem>Electronic</DropdownItem>
                    <DropdownItem>Photography</DropdownItem>
                    <DropdownItem>Sport</DropdownItem>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div class="report-chart">
              <ReportLineChart :height="275" class="mt-6 -mb-6" />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Seller -->
        <!-- <div class="col-span-12 mt-8 sm:col-span-6 lg:col-span-6">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">Weekly Top Agen Sales</h2>
            <a href="" class="ml-auto truncate text-primary">Show More</a>
          </div>
          <div class="intro-y box mt-5 p-5">
            <div class="mt-3">
              <ReportPieChart :height="213" />
            </div>
            <div class="mx-auto mt-8 w-52 sm:w-auto">
              <div class="flex items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                <span class="truncate">17 - 30 Years old</span>
                <span class="ml-auto font-medium">62%</span>
              </div>
              <div class="mt-4 flex items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                <span class="truncate">31 - 50 Years old</span>
                <span class="ml-auto font-medium">33%</span>
              </div>
              <div class="mt-4 flex items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                <span class="truncate">>= 50 Years old</span>
                <span class="ml-auto font-medium">10%</span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- END: Weekly Top Seller -->
        <!-- BEGIN: Sales Report -->
        <!-- <div class="col-span-12 mt-8 sm:col-span-6 lg:col-span-6">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">Sales Report</h2>
            <a href="" class="ml-auto truncate text-primary">Show More</a>
          </div>
          <div class="intro-y box mt-5 p-5">
            <div class="mt-3">
              <ReportDonutChart :height="213" />
            </div>
            <div class="mx-auto mt-8 w-52 sm:w-auto">
              <div class="flex items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                <span class="truncate">17 - 30 Years old</span>
                <span class="ml-auto font-medium">62%</span>
              </div>
              <div class="mt-4 flex items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                <span class="truncate">31 - 50 Years old</span>
                <span class="ml-auto font-medium">33%</span>
              </div>
              <div class="mt-4 flex items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                <span class="truncate">>= 50 Years old</span>
                <span class="ml-auto font-medium">10%</span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- END: Sales Report -->
        <!-- BEGIN: Official Store -->
        <!-- <div class="col-span-12 mt-6 xl:col-span-8">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Official Store</h2>
            <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
              <MapPinIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <input
                type="text"
                class="form-control box pl-10 sm:w-56"
                placeholder="Filter by city"
              />
            </div>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div>
              250 Official stores in 21 countries, click the marker to see
              location details.
            </div>
            <ReportMap class="report-maps mt-5 rounded-md bg-slate-200" />
          </div>
        </div> -->
        <!-- END: Official Store -->
        <!-- BEGIN: Weekly Best Sellers -->
        <div class="col-span-12 mt-6">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">
              Agen Sales Terbaik Minggu Ini
            </h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              class="intro-y"
            >
              <div class="box zoom-in mb-3 flex items-center px-4 py-4">
                <div
                  class="image-fit h-10 w-10 flex-none overflow-hidden rounded-md"
                >
                  <img alt="Gromura - SCM" :src="faker.users[0].image" />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    {{ faker.users[0].name }}
                  </div>
                  <div class="mt-0.5 text-xs text-slate-500">
                    {{ faker.dates[0] }}
                  </div>
                </div>
                <div
                  class="cursor-pointer rounded-full bg-success py-1 px-2 text-xs font-medium text-white"
                >
                  137 Sales
                </div>
              </div>
            </div>
            <!-- <a
              href=""
              class="intro-y block w-full rounded-md border border-dotted border-slate-400 py-4 text-center text-slate-500 dark:border-darkmode-300"
              >View More</a
            > -->
          </div>
        </div>
        <!-- END: Weekly Best Sellers -->
        <!-- BEGIN: General Report -->
        <!-- <div class="col-span-12 mt-8 grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-6 2xl:col-span-3">
            <div class="box zoom-in p-5">
              <div class="flex items-center">
                <div class="w-2/4 flex-none">
                  <div class="truncate text-lg font-medium">Target Sales</div>
                  <div class="mt-1 text-slate-500">300 Sales</div>
                </div>
                <div class="relative ml-auto flex-none">
                  <ReportDonutChart1 :width="90" :height="90" />
                  <div
                    class="absolute top-0 left-0 flex h-full w-full items-center justify-center font-medium"
                  >
                    20%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6 2xl:col-span-3">
            <div class="box zoom-in p-5">
              <div class="flex">
                <div class="mr-3 truncate text-lg font-medium">
                  Social Media
                </div>
                <div
                  class="ml-auto flex cursor-pointer items-center truncate rounded-full bg-slate-100 py-1 px-2 text-xs text-slate-500 dark:bg-darkmode-400"
                >
                  320 Followers
                </div>
              </div>
              <div class="mt-1">
                <SimpleLineChart1 :height="58" class="-ml-1" />
              </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6 2xl:col-span-3">
            <div class="box zoom-in p-5">
              <div class="flex items-center">
                <div class="w-2/4 flex-none">
                  <div class="truncate text-lg font-medium">New Products</div>
                  <div class="mt-1 text-slate-500">1450 Products</div>
                </div>
                <div class="relative ml-auto flex-none">
                  <ReportDonutChart1 :width="90" :height="90" />
                  <div
                    class="absolute top-0 left-0 flex h-full w-full items-center justify-center font-medium"
                  >
                    45%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6 2xl:col-span-3">
            <div class="box zoom-in p-5">
              <div class="flex">
                <div class="mr-3 truncate text-lg font-medium">Posted Ads</div>
                <div
                  class="ml-auto flex cursor-pointer items-center truncate rounded-full bg-slate-100 py-1 px-2 text-xs text-slate-500 dark:bg-darkmode-400"
                >
                  180 Campaign
                </div>
              </div>
              <div class="mt-1">
                <SimpleLineChart1 :height="58" class="-ml-1" />
              </div>
            </div>
          </div>
        </div> -->
        <!-- END: General Report -->
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">
              Produk Terbaik Minggu Ini
            </h2>
            <div class="mt-3 flex items-center sm:ml-auto sm:mt-0">
              <button
                class="btn box flex items-center text-slate-600 dark:text-slate-300"
              >
                <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
                Export to Excel
              </button>
              <button
                class="btn box ml-3 flex items-center text-slate-600 dark:text-slate-300"
              >
                <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
                Export to PDF
              </button>
            </div>
          </div>
          <div class="intro-y mt-8 overflow-auto sm:mt-0 lg:overflow-visible">
            <table class="table-report table sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">IMAGES</th>
                  <th class="whitespace-nowrap">PRODUCT NAME</th>
                  <th class="whitespace-nowrap text-center">STOCK</th>
                  <th class="whitespace-nowrap text-center">STATUS</th>
                  <th class="whitespace-nowrap text-center">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(faker, fakerKey) in $_.take($f(), 4)"
                  :key="fakerKey"
                  class="intro-x"
                >
                  <td class="w-40">
                    <div class="flex">
                      <div class="image-fit zoom-in h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[0]"
                          :content="`Uploaded at ${faker.dates[0]}`"
                        />
                      </div>
                      <!-- <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[1]"
                          :content="`Uploaded at ${faker.dates[1]}`"
                        />
                      </div> -->
                      <!-- <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.images[2]"
                          :content="`Uploaded at ${faker.dates[2]}`"
                        />
                      </div> -->
                    </div>
                  </td>
                  <td>
                    <a href="" class="whitespace-nowrap font-medium">{{
                      faker.products[0].name
                    }}</a>
                    <div
                      class="mt-0.5 whitespace-nowrap text-xs text-slate-500"
                    >
                      {{ faker.products[0].category }}
                    </div>
                  </td>
                  <td class="text-center">{{ faker.stocks[0] }}</td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center"
                      :class="{
                        'text-success': faker.trueFalse[0],
                        'text-danger': !faker.trueFalse[0],
                      }"
                    >
                      <CheckSquareIcon class="mr-2 h-4 w-4" />
                      {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex items-center justify-center">
                      <a class="mr-3 flex items-center" href="">
                        <CheckSquareIcon class="mr-1 h-4 w-4" />
                        Edit
                      </a>
                      <a class="flex items-center text-danger" href="">
                        <Trash2Icon class="mr-1 h-4 w-4" /> Delete
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="intro-y mt-3 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
          >
            <nav class="w-full sm:mr-auto sm:w-auto">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronsLeftIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronLeftIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">2</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">3</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronRightIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronsRightIcon class="h-4 w-4" />
                  </a>
                </li>
              </ul>
            </nav>
            <select class="box form-select mt-3 w-20 sm:mt-0">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div>
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-3">
      <div class="-mb-10 pb-10 2xl:border-l">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 2xl:gap-x-0 2xl:pl-6">
          <!-- BEGIN: Transactions -->
          <!-- <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12 2xl:mt-8"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">Transaksi</h2>
            </div>
            <div class="mt-5">
              <div
                v-for="(faker, fakerKey) in $_.take($f(), 5)"
                :key="fakerKey"
                class="intro-x"
              >
                <div class="box zoom-in mb-3 flex items-center px-5 py-3">
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="faker.users[0].image" />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ faker.users[0].name }}
                    </div>
                    <div class="mt-0.5 text-xs text-slate-500">
                      {{ faker.dates[0] }}
                    </div>
                  </div>
                  <div
                    :class="{
                      'text-success': faker.trueFalse[0],
                      'text-danger': !faker.trueFalse[0],
                    }"
                  >
                    {{ faker.trueFalse[0] ? '+' : '-' }}Rp{{ faker.totals[0] }}
                  </div>
                </div>
              </div>
              <a
                href=""
                class="intro-x block w-full rounded-md border border-dotted border-slate-400 py-3 text-center text-slate-500 dark:border-darkmode-300"
                >View More</a
              >
            </div>
          </div> -->
          <!-- END: Transactions -->
          <!-- BEGIN: Recent Activities -->
          <!-- <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">
                Recent Activities
              </h2>
              <a href="" class="ml-auto truncate text-primary">Show More</a>
            </div>
            <div
              class="relative mt-5 before:absolute before:ml-5 before:mt-5 before:block before:h-[85%] before:w-px before:bg-slate-200 before:dark:bg-darkmode-400"
            >
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[9].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[9].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">Has joined the team</div>
                </div>
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[8].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[8].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="text-slate-500">
                    <div class="mt-1">Added 3 new photos</div>
                    <div class="mt-2 flex">
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[0].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[0]"
                        />
                      </Tippy>
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[1].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[1]"
                        />
                      </Tippy>
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[2].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[2]"
                        />
                      </Tippy>
                    </div>
                  </div>
                </div>
              </div>
              <div class="intro-x my-4 text-center text-xs text-slate-500">
                12 November
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[7].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[7].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">
                    Has changed
                    <a class="text-primary" href="">{{
                      $f()[7].products[0].name
                    }}</a>
                    price and description
                  </div>
                </div>
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[6].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[6].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">
                    Has changed
                    <a class="text-primary" href="">{{
                      $f()[6].products[0].name
                    }}</a>
                    description
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- END: Recent Activities -->
          <!-- BEGIN: Important Notes -->
          <!-- <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-auto truncate text-lg font-medium">
                Important Notes
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn mr-2 border-slate-300 px-2 text-slate-600 dark:text-slate-300"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn mr-2 border-slate-300 px-2 text-slate-600 dark:text-slate-300"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="h-4 w-4" />
              </button>
            </div>
            <div class="intro-x mt-5">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
          </div> -->
          <!-- END: Important Notes -->
          <!-- BEGIN: Schedules -->
          <!-- <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 xl:col-start-1 xl:row-start-2 2xl:col-span-12 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">Schedules</h2>
              <a
                href=""
                class="ml-auto flex items-center truncate text-primary"
              >
                <PlusIcon class="mr-1 h-4 w-4" /> Add New Schedules
              </a>
            </div>
            <div class="mt-5">
              <div class="intro-x box">
                <div class="p-5">
                  <div class="flex">
                    <ChevronLeftIcon class="h-5 w-5 text-slate-500" />
                    <div class="mx-auto text-base font-medium">April</div>
                    <ChevronRightIcon class="h-5 w-5 text-slate-500" />
                  </div>
                  <div class="mt-5 grid grid-cols-7 gap-4 text-center">
                    <div class="font-medium">Su</div>
                    <div class="font-medium">Mo</div>
                    <div class="font-medium">Tu</div>
                    <div class="font-medium">We</div>
                    <div class="font-medium">Th</div>
                    <div class="font-medium">Fr</div>
                    <div class="font-medium">Sa</div>
                    <div class="relative rounded py-0.5 text-slate-500">29</div>
                    <div class="relative rounded py-0.5 text-slate-500">30</div>
                    <div class="relative rounded py-0.5 text-slate-500">31</div>
                    <div class="relative rounded py-0.5">1</div>
                    <div class="relative rounded py-0.5">2</div>
                    <div class="relative rounded py-0.5">3</div>
                    <div class="relative rounded py-0.5">4</div>
                    <div class="relative rounded py-0.5">5</div>
                    <div
                      class="relative rounded bg-success/20 py-0.5 dark:bg-success/30"
                    >
                      6
                    </div>
                    <div class="relative rounded py-0.5">7</div>
                    <div class="relative rounded bg-primary py-0.5 text-white">
                      8
                    </div>
                    <div class="relative rounded py-0.5">9</div>
                    <div class="relative rounded py-0.5">10</div>
                    <div class="relative rounded py-0.5">11</div>
                    <div class="relative rounded py-0.5">12</div>
                    <div class="relative rounded py-0.5">13</div>
                    <div class="relative rounded py-0.5">14</div>
                    <div class="relative rounded py-0.5">15</div>
                    <div class="relative rounded py-0.5">16</div>
                    <div class="relative rounded py-0.5">17</div>
                    <div class="relative rounded py-0.5">18</div>
                    <div class="relative rounded py-0.5">19</div>
                    <div class="relative rounded py-0.5">20</div>
                    <div class="relative rounded py-0.5">21</div>
                    <div class="relative rounded py-0.5">22</div>
                    <div
                      class="relative rounded bg-pending/20 py-0.5 dark:bg-pending/30"
                    >
                      23
                    </div>
                    <div class="relative rounded py-0.5">24</div>
                    <div class="relative rounded py-0.5">25</div>
                    <div class="relative rounded py-0.5">26</div>
                    <div
                      class="relative rounded bg-primary/10 py-0.5 dark:bg-primary/50"
                    >
                      27
                    </div>
                    <div class="relative rounded py-0.5">28</div>
                    <div class="relative rounded py-0.5">29</div>
                    <div class="relative rounded py-0.5">30</div>
                    <div class="relative rounded py-0.5 text-slate-500">1</div>
                    <div class="relative rounded py-0.5 text-slate-500">2</div>
                    <div class="relative rounded py-0.5 text-slate-500">3</div>
                    <div class="relative rounded py-0.5 text-slate-500">4</div>
                    <div class="relative rounded py-0.5 text-slate-500">5</div>
                    <div class="relative rounded py-0.5 text-slate-500">6</div>
                    <div class="relative rounded py-0.5 text-slate-500">7</div>
                    <div class="relative rounded py-0.5 text-slate-500">8</div>
                    <div class="relative rounded py-0.5 text-slate-500">9</div>
                  </div>
                </div>
                <div class="border-t border-slate-200/60 p-5">
                  <div class="flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                    <span class="truncate">Sales Workshop</span>
                    <span class="font-medium xl:ml-auto">23th</span>
                  </div>
                  <div class="mt-4 flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                    <span class="truncate">Launching Toko Baru</span>
                    <span class="font-medium xl:ml-auto">10th</span>
                  </div>
                  <div class="mt-4 flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                    <span class="truncate">Perayaan 1 Tahun Gromura</span>
                    <span class="font-medium xl:ml-auto">31th</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- END: Schedules -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs, provide } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Status from '@/components/status/Main.vue'

export default {
  components: {
    ReportLineChart,
    ImageLoader,
    TableComponent,
    Status,
    Currency,
  },
  mixins: [globalMixin],
  setup(props, { emit}) {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const activeData = reactive({})
    const isLoading = ref(false)
    const timer = ref(undefined)

    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', (el) => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }

    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role.code

      emitter.on("update-data", store => {
        console.log(`---updating page => ${route.name} for store Id ${store.name} `)
        getSummaryData()
      });

      getSummaryData()
    })

    const getSummaryData = async () => {
      isLoading.value = true
      emit('showGlobalLoadingIndicator', true)
      await store
        .dispatch('transaction/summary')
        .then((response) => {
          if (response.data) {
            // activeData.value = response.data
            Object.assign(activeData, response.data)
          }

          if (response.meta) {
            // metaData = response.meta
            Object.assign(metaData, response.meta)
          }

          isLoading.value = false
          emit('showGlobalLoadingIndicator', false)
        })
        .catch((e) => {
          isLoading.value = false
          emit('showGlobalLoadingIndicator', false)
          console.log(e)
        })
    }

    return {
      t,
      n,
      store,
      route,
      router,

      roleAccess,

      activeData,
      isLoading,
      timer,

      getSummaryData,

      salesReportFilter,
      importantNotesRef,
      prevImportantNotes,
      nextImportantNotes,
    }
  },
}
</script>
