<template>
  <div class="pt-2 md:py-2">
    <!-- <DarkModeSwitcher /> -->
    <!-- <MainColorSwitc    her /> -->
    <MobileMenu />
    <div class="mt-[2.5rem] flex md:mt-0">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <router-link
          :to="{ name: 'dashboard' }"
          tag="a"
          class="intro-x flex items-center px-2 pt-4"
        >
          <!-- <img
            alt="Gromura - SCM"
            class="aspect-square object-cover xl:hidden"
            src="@/assets/images/icon-white-transparent.png"
          /> -->
          <img
            alt="Gromura - SCM"
            class="xl:block"
            src="@/assets/images/logo-white.png"
          />
          <!-- <GromuraIcon class="w-8 text-white" /> -->
          <!-- <span class="ml-3 hidden text-lg text-white xl:block">
            {{ appName }}
          </span> -->
        </router-link>
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="$t(menu.title)"
                :href="
                  menu.submenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown,
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <template v-if="menu.svg === true">
                    <HomeMenuIcon class="w-6" v-if="menu.icon === 'HomeIcon'" />
                    <ReportMenuIcon
                      class="w-6"
                      v-else-if="menu.icon === 'ReportIcon'"
                    />
                    <AnalyticMenuIcon
                      class="w-6"
                      v-if="menu.icon === 'AnalyticIcon'"
                    />
                    <ProfileMenuIcon
                      class="w-5"
                      v-if="menu.icon === 'ProfileIcon'"
                    />
                    <StoreMenuIcon
                      class="w-6"
                      v-if="menu.icon === 'StoreIcon'"
                    />
                    <WarehouseMenuIcon
                      class="w-6"
                      v-if="menu.icon === 'WarehouseIcon'"
                    />
                  </template>
                  <template v-else>
                    <component :is="menu.icon" />
                  </template>
                </div>
                <div class="side-menu__title">
                  {{ $t(menu.title) }}
                  <div
                    v-if="menu.submenu"
                    class="side-menu__sub-icon"
                    :class="{ 'rotate-180 transform': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.submenu && menu.activeDropdown">
                  <li
                    v-for="(submenu, subMenuKey) in menu.submenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="$t(submenu.title)"
                      :href="
                        submenu.submenu
                          ? 'javascript:;'
                          : router.resolve({ name: submenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': submenu.active }"
                      @click="linkTo(submenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <!-- <ActivityIcon /> -->
                        <CircleIcon class="w-2" />
                      </div>
                      <div class="side-menu__title">
                        {{ $t(submenu.title) }}
                        <div
                          v-if="submenu.submenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'rotate-180 transform': submenu.activeDropdown,
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="submenu.submenu && submenu.activeDropdown">
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in submenu.submenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="$t(lastSubMenu.title)"
                            :href="
                              lastSubMenu.submenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <!-- <ZapIcon /> -->
                              <CircleIcon class="w-2" />
                            </div>
                            <div class="side-menu__title">
                              {{ $t(lastSubMenu.title) }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <div class="hidden md:block">
          <TopBar />
        </div>
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { computed, onMounted, provide, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import {
  useSideMenuStore,
  // useSideMenuStaffStore,
  useSideMenuHeadOperationStore,
  useSideMenuHeadStoreStore,
  useSideMenuCashierStore,
} from '@/stores/side-menu'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import MainColorSwitcher from '@/components/main-color-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import dom from '@left4code/tw-starter/dist/js/dom'
import globalMixin from '@/mixins/global.js'

import GromuraIcon from '@/assets/svg/gromura-icon-dynamic.svg'

import HomeMenuIcon from '@/assets/svg/menu/home.svg'
import ReportMenuIcon from '@/assets/svg/menu/report.svg'
import AnalyticMenuIcon from '@/assets/svg/menu/analytic.svg'
import ProfileMenuIcon from '@/assets/svg/menu/user.svg'
import StoreMenuIcon from '@/assets/svg/menu/store.svg'
import WarehouseMenuIcon from '@/assets/svg/menu/warehouse.svg'


export default {
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    MainColorSwitcher,
    SideMenuTooltip,
    nestedMenu,

    GromuraIcon,
    HomeMenuIcon,
    ReportMenuIcon,
    AnalyticMenuIcon,
    ProfileMenuIcon,
    StoreMenuIcon,
    WarehouseMenuIcon
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const activeUser = ref({})
    const roleUser = ref({})
    const formattedMenu = ref([])

    const sideMenuStore = useSideMenuStore()
    const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route))

    // const sideMenuStaffStore = useSideMenuStaffStore()
    // const sideMenuStaff = computed(() =>
    //   nestedMenu(sideMenuStaffStore.menu, route)
    // )

    const sideMenuHeadOperationStore = useSideMenuHeadOperationStore()
    const sideMenuHeadOperation = computed(() =>
      nestedMenu(sideMenuHeadOperationStore.menu, route)
    )
    const sideMenuHeadStoreStore = useSideMenuHeadStoreStore()
    const sideMenuHeadStore = computed(() =>
      nestedMenu(sideMenuHeadStoreStore.menu, route)
    )

    const sideMenuCashierStore = useSideMenuCashierStore()
    const sideMenuCashier = computed(() =>
      nestedMenu(sideMenuCashierStore.menu, route)
    )

    provide('forceActiveMenu', (pageName) => {
      route.forceActiveMenu = pageName
      // formattedMenu.value = $h.toRaw(sideMenu.value)

      setActiveMenu()
    })
    watch(
      computed(() => route.path),
      () => {
        delete route.forceActiveMenu

        setActiveMenu()
      }
    )
    onMounted(() => {
      dom('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')

      setActiveMenu()
    })

    const setActiveMenu = () => {
      activeUser.value = store.getters['auth/activeUser']
      if (activeUser.value !== null) {
        if (activeUser.value.role !== null) {
          roleUser.value = activeUser.value.role
        }
      }

      console.log('----roleUser.value.code ', roleUser.value.code)

      if (roleUser.value.code === 'HEAD_OPERATION') {
        formattedMenu.value = $h.toRaw(sideMenuHeadOperation.value)
      } else if (roleUser.value.code === 'HEAD_STORE') {
        formattedMenu.value = $h.toRaw(sideMenuHeadStore.value)
      } else if (roleUser.value.code === 'CASHIER') {
        // if (route.name === 'dashboard') {
        //   router.push({
        //     name: 'point-of-sale',
        //     params: { slug: route.params.slug },
        //     query: { lang: route.query.lang },
        //   })
        // }
        formattedMenu.value = $h.toRaw(sideMenuCashier.value)
      } else {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
      // formattedMenu.value = $h.toRaw(sideMenu.value)


      // activeUser.value = store.getters['auth/activeUser']

      // if (activeUser.value !== null) {
      //   if (activeUser.value.role !== null) {
      //     roleUser.value = activeUser.value.role
      //   }
      // }

      // if (roleUser.value.code === 'SUPERADMIN') {
      //   formattedMenu.value = $h.toRaw(sideMenu.value)
      // } else {
      //   formattedMenu.value = $h.toRaw(sideMenuStaff.value)
      // }
    }

    return {
      t,
      route,
      router,
      formattedMenu,
      sideMenuStore,
      sideMenu,
      // sideMenuStaffStore,
      // sideMenuStaff,
      sideMenuHeadOperationStore,
      sideMenuHeadOperation,
      sideMenuHeadStoreStore,
      sideMenuHeadStore,
      sideMenuCashierStore,
      sideMenuCashier,
      setActiveMenu,
      linkTo,
      enter,
      leave,
    }
  },
}
</script>
