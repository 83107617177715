<template>
  <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <h2 class="mr-auto text-lg font-medium">Buat Pembelian</h2>
  </div>
  <div class="intro-y mt-5 grid grid-cols-12 gap-5 border-t-2 pt-6">
    <div class="col-span-12">
      <div class="flex flex-col md:flex-row">
        <!-- <div class="input-form mb-4 w-full md:w-1/2 md:pr-4">
          <label
            for="form-product-store"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.store') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="shop"
              value="id"
              value-prop="id"
              label="name"
              track-by="name"
              :placeholder="
                isStoreLoading
                  ? 'Memuat data....'
                  : $t('formLabel.select.store')
              "
              :options="storeData"
              :classes="multiSelectClasses"
              :class="isStoreError ? 'border-danger' : ''"
              :searchable="true"
              :loading="isStoreLoading"
              @select="isStoreError = false"
            />

            <template v-if="isStoreError">
              <div class="mt-2 text-xs text-danger">Toko harus diisi</div>
            </template>
          </div>
        </div> -->

        <div class="input-form mb-4 w-full">
          <label
            for="form-product-supplier"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.supplier') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="supplier"
              value="id"
              value-prop="id"
              label="name"
              track-by="name"
              :placeholder="
                isSupplierLoading
                  ? 'Memuat data....'
                  : $t('formLabel.select.supplier')
              "
              :options="supplierData"
              :classes="multiSelectClasses"
              :class="isSupplierError ? 'border-danger' : ''"
              :searchable="true"
              :loading="isSupplierLoading"
              @select="isSupplierError = false"
            />

            <template v-if="isSupplierError">
              <div class="mt-2 text-xs text-danger">Supplier harus diisi</div>
            </template>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row">
        <div class="input-form mb-4 w-full md:w-1/2 md:pr-4">
          <label
            for="form-product-paymentTerm"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.paymentTerm') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="paymentTerm"
              value="id"
              value-prop="id"
              label="name"
              track-by="name"
              :placeholder="
                isPaymentTermLoading
                  ? 'Memuat data....'
                  : $t('formLabel.select.paymentTerm')
              "
              :options="paymentTermData"
              :classes="multiSelectClasses"
              :class="isPaymentTermError ? 'border-danger' : ''"
              :searchable="true"
              :loading="isPaymentTermLoading"
              @select="setPaymentDateline"
            />

            <template v-if="isPaymentTermError">
              <div class="mt-2 text-xs text-danger">
                Term Pembayaran harus diisi
              </div>
            </template>
          </div>
        </div>
        <div class="relative mx-auto mb-10 h-12 w-full md:mb-5 md:w-1/2">
          <div class="mb-2"><p>Payment Dateline</p></div>
          <div
            class="absolute flex h-full w-10 items-center justify-center rounded-l border bg-white bg-slate-100 text-slate-500 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
          >
            <CalendarIcon class="h-4 w-4" />
          </div>

          <input
            v-model="paymentDateline"
            type="text"
            class="form-control h-full pl-12 read-only:bg-white"
            readonly
          />
        </div>
      </div>
    </div>
    <!-- <div class="col-span-12 border-t-2 pt-6">
      <div class="mb-4 flex w-full">
        <span class="text-lg">Rekomendasi Produk</span>
      </div>
      <div class="mt-5 grid grid-cols-12 gap-5 pb-8">
        <div
          v-for="item in productData"
          :key="item.key"
          class="box col-span-12 flex h-24 cursor-pointer md:col-span-6 lg:col-span-4 2xl:col-span-3"
          @click="addItem(item)"
        >
          <div class="flex aspect-square">
            <ImageLoader
              :image="item.image !== undefined ? item.images[0].image : null"
              :alt="item.name"
              rounded-class="rounded-tl-md rounded-bl-md object-cover"
            />
          </div>
          <div class="flex w-full flex-col truncate p-2">
            <div class="flex w-full truncate font-medium">
              {{ item.name }}
            </div>
            <div>
              <template v-if="item.sub_category !== undefined">
                <span class="mt-3 text-xs text-green-600">
                  {{ item.sub_category.name }}</span
                >
              </template>
              <template v-else>
                <span class="mt-3 text-xs text-green-600"> - </span></template
              >
            </div>

            <div class="mt-2 text-slate-600 dark:text-slate-500">
              <div class="flex items-center font-bold">
                <Currency :total-in-string="item.price.toString()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-span-12 border-t-2 pt-6">
      <div class="mb-4 flex w-full">
        <span class="text-lg">Cari Produk</span>
      </div>
      <div class="w-full lg:flex">
        <div class="flex w-full md:w-auto">
          <div class="relative sm:w-full">
            <input
              v-model="filterData.search"
              type="text"
              class="form-control box w-full py-3 px-4 pr-10 lg:w-80"
              placeholder="Search produk..."
              @input="searchFilter()"
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4 text-slate-500"
            />
          </div>

          <!-- <select
            v-model="filterData.type"
            placeholder="Semua"
            class="box form-select ml-4 w-28"
            @change="getProduct(true)"
          >
            <option
              v-for="method in productTypeOptions"
              :key="method.id"
              :value="method.id"
            >
              {{ method.name }}
            </option>
          </select> -->
        </div>
        <!-- <select
            class="box form-select mt-3 ml-auto w-full py-3 px-4 lg:mt-0 lg:w-auto"
          >
            <option>Sort By</option>
            <option>A to Z</option>
            <option>Z to A</option>
            <option>Harga Terendah</option>
            <option>Harga Tertinggi</option>
            <option>Stok Terendah</option>
            <option>Stok Tertinggi</option>
            <option>Minimum Stok</option>
            <option>Maksimum Stok</option>
          </select> -->
      </div>
      <div
        v-if="productData.length === 0"
        class="mt-5 grid grid-cols-12 gap-5 border-b pb-8"
      >
        <div
          class="relative col-span-12 flex h-24 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center sm:col-span-4 2xl:col-span-3"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Belum ada produk yang dicari
          </span>
        </div>
      </div>
      <div v-else class="mt-5 flex flex-col border-b pb-8">
        <div class="flex w-full">
          <span
            v-if="filterData.search === ''"
            class="text-sm italic text-gray-600"
            >Rekomendasi Produk</span
          >
          <span v-else class="text-sm italic text-gray-600"
            >Hasil Pencarian</span
          >
        </div>
        <div class="mt-4 grid grid-cols-12 gap-5">
          <div
            v-for="item in productData"
            :key="item.key"
            class="box col-span-12 flex h-24 md:col-span-6 lg:col-span-4 2xl:col-span-3"
          >
            <div class="flex aspect-square">
              <ImageLoader
                :image="
                  item.images !== undefined && item.images.length > 0
                    ? item.images[0].image
                    : null
                "
                :alt="item.name"
                rounded-class="rounded-tl-md rounded-bl-md object-cover"
              />
            </div>
            <div class="flex w-full flex-col p-2">
              <div class="flex w-full text-base font-medium">
                {{ item.name }}
              </div>
              <div class="mt-2 flex w-full">
                <a
                  class="flex items-center text-xs text-secondary"
                  href="javascript:;"
                  @click="productPreview(item)"
                >
                  <EyeIcon class="mr-1 h-4 w-4" /> Detail Produk
                </a>
              </div>
              <!-- <div class="mt-2 text-slate-600 dark:text-slate-500">
                <div class="flex items-center font-bold">
                  <Currency :total-in-string="item.price.toString()" />
                </div>
              </div>
              <div class="mt-2 mb-1 flex">
                <div class="tems-center">
                  <div class="rounded-md bg-green-100 px-2">
                    <span class="self-center text-xs text-green-600">{{
                      item.brand.name
                    }}</span>
                  </div>
                </div>

                <div
                  v-if="item.sub_category !== undefined"
                  class="ml-2 items-center"
                >
                  <div class="rounded-md bg-red-100 px-2">
                    <span class="self-center text-xs text-red-600">{{
                      item.sub_category.name
                    }}</span>
                  </div>
                </div>
                <div v-else class="ml-2 items-center">
                  <div class="rounded-md bg-red-100 px-2">
                    <span class="self-center text-xs text-red-600"> - </span>
                  </div>
                </div>
                <div
                  v-if="item.is_accessories === true"
                  class="ml-2 items-center"
                >
                  <div class="rounded-md bg-gray-100 px-2">
                    <span class="text-xs text-gray-600">AKSESORIS</span>
                  </div>
                </div>
                <div v-else class="ml-2 items-center">
                  <div class="rounded-md bg-gray-100 px-2">
                    <span class="text-xs text-gray-600">UNIT</span>
                  </div>
                </div>
              </div> -->
            </div>
            <div
              class="flex items-center cursor-pointer rounded-tr-md rounded-br-md bg-success px-2"
              @click="addItem(item)"
            >
              <PlusCircleIcon class="h-7 w-7 text-white" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 flex w-full">
        <span class="text-lg">Produk Terpilih</span>
      </div>
      <div
        v-if="productDataSelected.length === 0"
        class="mt-5 grid grid-cols-12 gap-5 border-b pb-8"
      >
        <div
          class="relative col-span-12 flex h-72 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center md:col-span-4 lg:col-span-3 2xl:col-span-2"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Belum ada produk yang dipilih
          </span>
        </div>
      </div>
      <div v-else class="mt-5 grid grid-cols-12 gap-5 pb-8">
        <div
          v-for="item in productDataSelected"
          :key="item.key"
          class="relative col-span-12 md:col-span-6 lg:col-span-4 2xl:col-span-3"
        >
          <div class="box flex flex-col">
            <div class="flex w-full">
              <div class="flex w-32 pl-4 pt-4 pb-2">
                <div class="w-full">
                <ImageLoader
                  :image="
                    item.images !== undefined && item.images.length > 0
                      ? item.images[0].image
                      : null
                  "
                  :alt="item.name"
                  rounded-class="rounded-md w-24 cursor-pointer"
                  @click="imagePreview(item)"
                />
                </div>
              </div>
              <div
                class="flex w-full cursor-pointer flex-col px-4 pt-4 pb-2"
                @click="productPreview(item)"
              >
                <div class="flex w-full text-lg font-medium">
                  {{ item.name }}
                </div>
                <div class="mt-2 flex w-full">
                  <a
                    class="flex items-center text-xs text-secondary"
                    href="javascript:;"
                    @click="productPreview(item)"
                  >
                    <EyeIcon class="mr-1 h-4 w-4" /> Detail Produk
                  </a>
                </div>
                <!-- <div class="mt-3 flex">
                  <div class="tems-center">
                    <div class="rounded-md bg-green-100 px-2">
                      <span class="self-center text-xs text-green-600">{{
                        item.brand.name
                      }}</span>
                    </div>
                  </div>

                  <div
                    v-if="item.sub_category !== undefined"
                    class="ml-2 items-center"
                  >
                    <div class="rounded-md bg-red-100 px-2">
                      <span class="self-center text-xs text-red-600">{{
                        item.sub_category.name
                      }}</span>
                    </div>
                  </div>
                  <div v-else class="ml-2 items-center">
                    <div class="rounded-md bg-red-100 px-2">
                      <span class="self-center text-xs text-red-600"> - </span>
                    </div>
                  </div>
                </div> -->
                <!-- <div v-if="item.sub_category !== undefined">
                  <span class="mt-3 text-xs text-green-600">{{
                    item.sub_category.name
                  }}</span>
                </div> -->
                <!-- <div class="mt-2 flex w-full">
                  <template v-if="item.product_converters !== undefined">
                    <template v-if="item.is_accessories == true">
                      <div
                        v-for="unit in item.product_converters"
                        :key="unit.id"
                      >
                        <div
                          v-if="unit.base === false"
                          class="rounded-md bg-sky-100 px-2"
                        >
                          <span class="text-xs text-sky-600">{{
                            unit.product_unit.name
                          }}</span>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        v-for="unit in item.product_converters"
                        :key="unit.id"
                      >
                        <div class="rounded-md bg-sky-100 px-2">
                          <span class="text-xs text-sky-600">{{
                            unit.product_unit.name
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div
                    v-if="item.is_accessories === true"
                    class="ml-2 items-center"
                    :class="
                      item.product_converters !== undefined ? 'ml-2' : null
                    "
                  >
                    <div class="rounded-md bg-gray-100 px-2">
                      <span class="text-xs text-gray-600">AKSESORIS</span>
                    </div>
                  </div>
                  <div v-else class="ml-2 items-center">
                    <div class="rounded-md bg-gray-100 px-2">
                      <span class="text-xs text-gray-600">UNIT</span>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Quantity
                </div>
                <div class="flex items-end pr-2">
                  <button
                    type="button"
                    class="btn mr-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, false, false)"
                  >
                    -
                  </button>
                  <input
                    id="pos-form-4"
                    v-model="item.quantity"
                    type="text"
                    class="form-control w-16 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                  <button
                    type="button"
                    class="btn ml-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, false, true)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="flex w-full pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Harga Beli
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.pay_price"
                    type="text"
                    class="form-control w-36 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Quantity Bonus
                </div>
                <div class="flex items-end pr-2">
                  <button
                    type="button"
                    class="btn mr-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, true, false)"
                  >
                    -
                  </button>
                  <input
                    id="pos-form-4"
                    v-model="item.quantity_bonus"
                    type="text"
                    class="form-control w-16 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                  <button
                    type="button"
                    class="btn ml-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, true, true)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="mb-2 flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Discount Harga
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.price_discount"
                    type="text"
                    class="form-control w-36 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col border-t-2 p-2 py-3 pl-4">
              <div class="flex w-full justify-between text-xs">
                <div class="flex items-center">Total Harga</div>
                <div class="flex items-end pr-2">
                  <Currency :total-in-string="item.total_price.toString()" />
                </div>
              </div>
            </div>
          </div>
          <Tippy
            content="Hapus item ini?"
            class="tooltip absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-danger text-white"
            @click="removeItem(item)"
          >
            <XIcon class="h-4 w-4" />
          </Tippy>
        </div>
      </div>
    </div>

    <div class="col-span-12 border-t-2 pt-4">
      <!-- <div class="input-form mb-4 w-full">
        <label
          for="form-product-store"
          class="form-label flex w-full flex-col sm:flex-row"
        >
          {{ $t('formLabel.store') }}
        </label>
        <div class="mt-2">
          <Multiselect
            v-model="shop"
            value="id"
            value-prop="id"
            label="name"
            track-by="name"
            :placeholder="
              isStoreLoading ? 'Memuat data....' : $t('formLabel.select.store')
            "
            :options="storeData"
            :classes="multiSelectClasses"
            :class="isStoreError ? 'border-danger' : ''"
            :searchable="true"
            :loading="isStoreLoading"
            @select="isStoreError = false"
          />

          <template v-if="isStoreError">
            <div class="mt-2 text-xs text-danger">Toko harus diisi</div>
          </template>
        </div>
      </div>

      <div class="input-form mb-4 w-full">
        <label
          for="form-product-supplier"
          class="form-label flex w-full flex-col sm:flex-row"
        >
          {{ $t('formLabel.supplier') }}
        </label>
        <div class="mt-2">
          <Multiselect
            v-model="supplier"
            value="id"
            value-prop="id"
            label="name"
            track-by="name"
            :placeholder="
              isSupplierLoading
                ? 'Memuat data....'
                : $t('formLabel.select.supplier')
            "
            :options="supplierData"
            :classes="multiSelectClasses"
            :class="isSupplierError ? 'border-danger' : ''"
            :searchable="true"
            :loading="isSupplierLoading"
            @select="isSupplierError = false"
          />

          <template v-if="isSupplierError">
            <div class="mt-2 text-xs text-danger">Supplier harus diisi</div>
          </template>
        </div>
      </div>

      <div class="input-form mb-4 w-full">
        <label
          for="form-product-paymentTerm"
          class="form-label flex w-full flex-col sm:flex-row"
        >
          {{ $t('formLabel.paymentTerm') }}
        </label>
        <div class="mt-2">
          <Multiselect
            v-model="paymentTerm"
            value="id"
            value-prop="id"
            label="name"
            track-by="name"
            :placeholder="
              isPaymentTermLoading
                ? 'Memuat data....'
                : $t('formLabel.select.paymentTerm')
            "
            :options="paymentTermData"
            :classes="multiSelectClasses"
            :class="isPaymentTermError ? 'border-danger' : ''"
            :searchable="true"
            :loading="isPaymentTermLoading"
            @select="setPaymentDateline"
          />

          <template v-if="isPaymentTermError">
            <div class="mt-2 text-xs text-danger">
              Term Pembayaran harus diisi
            </div>
          </template>
        </div>
      </div>
      <div class="relative mx-auto mb-5 h-12 w-full">
        <div class="mb-2"><p>Payment Dateline</p></div>
        <div
          class="absolute flex h-full w-10 items-center justify-center rounded-l border bg-white bg-slate-100 text-slate-500 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
        >
          <CalendarIcon class="h-4 w-4" />
        </div>

        <input
          v-model="paymentDateline"
          type="text"
          class="form-control h-full pl-12 read-only:bg-white"
          readonly
        />
      </div> -->
      <div class="mb-2"><p>Include Tax</p></div>
      <select
        v-model="tax"
        class="box form-select mb-4 h-12 w-full"
        @change="calculateTotalPrice"
      >
        <option value="0">0%</option>
        <option value="11">11%</option>
      </select>

      <div class="box mt-4 p-5">
        <div class="flex">
          <div class="mr-auto">Subtotal</div>
          <div class="font-medium">
            <Currency :total-in-string="subTotal.toString()" />
          </div>
        </div>

        <div class="mt-4 flex">
          <div class="mr-auto">Tax</div>
          <div class="font-medium">{{ tax }}%</div>
        </div>
        <div
          class="mt-4 flex border-t border-slate-200/60 pt-4 dark:border-darkmode-400"
        >
          <div class="mr-auto text-base font-medium">Total Harga</div>
          <div class="text-base font-medium">
            <Currency :total-in-string="total.toString()" />
          </div>
        </div>
      </div>
      <div class="mt-5 flex">
        <button
          class="btn w-32 border-slate-300 text-slate-500 dark:border-darkmode-400 md:w-64"
          @click="resetForm"
        >
          Clear Items
        </button>
        <button
          class="btn btn-primary ml-auto w-32 shadow-md md:w-64"
          @click="addData"
        >
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Buat
        </button>
      </div>
    </div>
    <!-- END: Ticket -->
  </div>
  
  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct :active-product="activeProduct" />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="activeProduct !== undefined"
        :images="activeProduct.images ?? []"
        :name="activeProduct.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ responseStatus }}</div>
      <div class="mt-1 text-slate-500">
        {{ responseMessage }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ responseStatus }}</div>
      <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import Toastify from 'toastify-js'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import BarcodeGenerator from '@/components/barcode/Generator.vue'
import ShimmerCard from '@/components/shimmer/ShimmerCard.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    BarcodeGenerator,
    ShimmerCard,
    DetailImage,
    DetailProduct,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const rolesCanManage = ref(['SUPERADMIN', 'HEAD_OPERATION'])

    const activeProduct = reactive({})

    const supplierImagePreview = ref('')
    const imageFile = ref(null)

    const shop = ref('')
    const supplier = ref('')
    const paymentTerm = ref('')
    const paymentDateline = ref('')

    const tax = ref(0)
    const subTotal = ref(0)
    const total = ref(0)

    const storePage = ref(1)
    const supplierPage = ref(1)
    const paymentTermPage = ref(1)

    const storeData = ref([])
    const supplierData = ref([])
    const paymentTermData = ref([])

    const isFormLoading = ref(false)
    const isStoreLoading = ref(false)
    const isSupplierLoading = ref(false)
    const isPaymentTermLoading = ref(false)
    const isLoading = ref(false)
    const timer = ref(undefined)

    const isStoreError = ref(false)
    const isSupplierError = ref(false)
    const isPaymentTermError = ref(false)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)

    const responseStatus = ref('')
    const responseMessage = ref('')

    let productData = ref([])
    // let productRecommendedData = ref([])
    let productDataSelected = ref([])

    let metaData = reactive({
      page: 1,
      take: 6,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 4,
      search: '',
      order: 'ASC',
      type: null,
    })

    const productTypeOptions = ref([
      {
        id: null,
        name: 'Semua',
      },
      {
        id: 'UNITS',
        name: 'Unit',
      },
      {
        id: 'ACCESSORIES',
        name: 'Aksesoris',
      },
    ])

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role.code

      emitter.on("update-data", store => {
        console.log(`---updating page => ${route.name} for store Id ${store.name} `)
        // getProduct(true)
      });

      // const storeId = store.getters['store/storeId']

      // if (storeId === null) {
      //   setTimeout(() => {
      //     getProduct()
      //   }, 2000)
      // } else {
      //   getProduct()
      // }

      getProduct()


      // await getStore()
      await getSupplier()
      await getPaymentTerm()
    })

    const getStore = async () => {
      const queryParameters = {
        order: 'ASC',
        page: storePage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('store/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const storeTemporary = []
            response.data.data.forEach((item) => {
              storeTemporary.push({
                value: item.code,
                ...item,
              })
            })
            storeData.value = [...storeData.value, ...storeTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('store')
            }
          }
        })
        .catch((error) => {})
    }

    const getSupplier = async () => {
      const queryParameters = {
        order: 'ASC',
        page: supplierPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('supplier/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const supplierTemporary = []
            response.data.data.forEach((item) => {
              supplierTemporary.push({
                value: item.code,
                ...item,
              })
            })
            supplierData.value = [...supplierData.value, ...supplierTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('supplier')
            }
          }
        })
        .catch((error) => {})
    }

    const getPaymentTerm = async () => {
      const queryParameters = {
        order: 'ASC',
        page: paymentTermPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('master/paymentTerm', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const paymentTermTemporary = []
            response.data.data.forEach((item) => {
              paymentTermTemporary.push({
                value: item.code,
                ...item,
              })
            })
            paymentTermData.value = [
              ...paymentTermData.value,
              ...paymentTermTemporary,
            ]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('paymentTerm')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'supplier') {
        supplierPage.value += 1
        getSupplier()
      } else if (type === 'paymentTerm') {
        paymentTermPage.value += 1
        getPaymentTerm()
      } else if (type === 'store') {
        storePage.value += 1
        getStore()
      }
    }

    const getProduct = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('product/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
          // is_accessories:
          //   filterData.type === 'ACCESSORIES'
          //     ? true
          //     : filterData.type === 'UNITS'
          //     ? false
          //     : null,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            productData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getProduct(true)
      }, 500)
    }

    const productPreview = (data) => {
      Object.assign(activeProduct, data)
      productPreviewModal.value = true
    }

    const imagePreview = (data) => {
      Object.assign(activeProduct, data)
      imagePreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const resetForm = () => {
      shop.value = ''
      supplier.value = ''
      paymentTerm.value = ''
      paymentDateline.value = ''
      tax.value = 0
      subTotal.value = 0
      total.value = 0

      isStoreError.value = false
      isSupplierError.value = false
      isPaymentTermError.value = false

      productData.value = []
      productDataSelected.value = []
    }

    const addItem = (product) => {
      if (productDataSelected.value.length === 0) {
        product.quantity = null
        product.pay_price = null
        product.quantity_bonus = null
        product.price_discount = null
        product.total_price = 0
        productDataSelected.value = [product]
      } else {
        let isDuplicate = false
        productDataSelected.value.forEach((item) => {
          if (item.id === product.id) {
            isDuplicate = true
          }
        })
        if (isDuplicate === false) {
          product.quantity = null
          product.pay_price = null
          product.quantity_bonus = null
          product.price_discount = null
          product.total_price = 0
          productDataSelected.value = [...productDataSelected.value, product]
        }
      }
    }

    const removeItem = (product) => {
      productDataSelected.value = productDataSelected.value.filter(
        (item) => item.id != product.id
      )
    }

    const changeQuantity = (product, bonus, added) => {
      if (bonus === true) {
        if (added === true) {
          if (product.quantity_bonus === null) {
            product.quantity_bonus = 1
          } else {
            product.quantity_bonus = product.quantity_bonus + 1
          }
        } else {
          if (product.quantity_bonus !== 0) {
            product.quantity_bonus = product.quantity_bonus - 1
          }
        }
      } else {
        if (added === true) {
          if (product.quantity === null) {
            product.quantity = 1
          } else {
            product.quantity = product.quantity + 1
          }
        } else {
          if (product.quantity !== 0) {
            product.quantity = product.quantity - 1
          }
        }
      }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (product) => {
      product.total_price =
        product.quantity * product.pay_price - product.price_discount

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      productDataSelected.value.forEach((item) => {
        totalPrice += item.total_price
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round(totalPrice / tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
    }

    const setPaymentDateline = () => {
      isPaymentTermError.value = false

      let paymentTermCode = ''
      paymentTermData.value.forEach((item) => {
        if (item.id === paymentTerm.value) {
          paymentTermCode = item.code
        }
      })

      const formattedDate = 'DD-MM-YYYY'

      if (paymentTermCode === 'CASH') {
        paymentDateline.value = dayjs().format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_3') {
        paymentDateline.value = dayjs().add(2, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_5') {
        paymentDateline.value = dayjs().add(4, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_7') {
        paymentDateline.value = dayjs().add(6, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_14') {
        paymentDateline.value = dayjs().add(13, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_30') {
        paymentDateline.value = dayjs().add(29, 'day').format(formattedDate)
      }
    }

    const addData = async () => {
      const storeId = store.getters['store/storeId']

      if (productDataSelected.value.length === 0) {
        responseStatus.value = 'Gagal'
        responseMessage.value = 'Mohon untuk menambahkan item terlebih dahulu'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      let dataNotValid = false
      productDataSelected.value.forEach((item) => {
        if (
          item.quantity === 0 ||
          item.quantity === null ||
          item.pay_price === 0 ||
          item.pay_price === null
        ) {
          responseStatus.value = 'Gagal'
          responseMessage.value = 'Quantity dan Harga beli tidak boleh kosong'
          setTimeout(() => {
            showToast()
          }, 200)
          dataNotValid = true
        } else if (item.pay_price * item.quantity <= item.price_discount) {
          responseStatus.value = 'Gagal'
          responseMessage.value = 'Harga Diskon tidak boleh melebihi harga beli'
          setTimeout(() => {
            showToast()
          }, 200)
          dataNotValid = true
        }
      })

      if (dataNotValid === true) {
        return
      }

      // if (shop.value === '') {
      //   isStoreError.value = true
      // } else 
      if (supplier.value === '') {
        isSupplierError.value = true
      } else if (paymentTerm.value === '') {
        isPaymentTermError.value = true
      }

      if (
        productDataSelected.value.length === 0 ||
        // shop.value === '' ||
        supplier.value === '' ||
        paymentTerm.value === ''
      ) {
        return
      }

      isFormLoading.value = true

      const products = []

      productDataSelected.value.forEach((item) => {
        const product = {
          product_id: item.id,
          quantity: item.quantity,
          price: item.pay_price,
          quantity_bonus: item.quantity_bonus,
          price_discount: item.price_discount,
        }

        products.push(product)
      })

      // const storeId = store.getters['store/storeId']

      let payload = {
        store_id: storeId,
        supplier_id: supplier.value,
        payment_term_id: paymentTerm.value,
        tax_id: Number(tax.value),
        products,
      }

      await store
        .dispatch('purchase/create', payload)
        .then((response) => {
          if (response.statusCode === 200) {
            responseStatus.value = response.status
            responseMessage.value = response.message

            addEditModal.value = false

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            // getProduct()
            setTimeout(() => {
              router.push({
                name: 'purchase',
                params: {
                  slug: route.params.slug,
                },
                query: {
                  lang: route.query.lang,
                },
              })
            }, 500)
          } else {
            responseStatus.value = response.message.status
            responseMessage.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          isFormLoading.value = false
          responseStatus.value = responseData.message.status
          responseMessage.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeProduct,

      roleAccess,
      rolesCanManage,

      supplierImagePreview,

      productPreviewModal,
      imagePreviewModal,
      productPreview,
      imagePreview,

      shop,
      supplier,
      paymentTerm,
      paymentDateline,
      tax,
      subTotal,
      total,

      setPaymentDateline,

      storeData,
      supplierData,
      paymentTermData,

      isFormLoading,
      isStoreLoading,
      isSupplierLoading,
      isPaymentTermLoading,
      isLoading,
      timer,

      isStoreError,
      isSupplierError,
      isPaymentTermError,

      responseStatus,
      responseMessage,

      metaData,
      filterData,
      productTypeOptions,

      productData,
      productDataSelected,

      addItem,
      removeItem,
      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      addData,
      resetForm,

      getStore,
      getSupplier,
      getPaymentTerm,
      getProduct,

      searchFilter,
      showToast,
    }
  },
}
</script>
