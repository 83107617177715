<template>
  <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <div class="mr-auto font-medium">
      <span class="text-lg">{{ purchaseData.code }} </span>&nbsp
      <!-- <template> -->
      <Status :status="purchaseData.status" />
    </div>
  </div>
  <div class="intro-y mt-5 grid grid-cols-12 gap-5 border-t pt-4">
    <!-- BEGIN: Item List -->
    <div class="col-span-12">
      <!-- <template v-if="rolesCanManage.includes(roleAccess)"> -->
      <div class="flex flex-col md:flex-row">
        <div class="input-form mb-4 w-full md:pr-4 lg:w-1/2">
          <label
            for="form-product-store"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.store') }}
          </label>
          <div class="mt-2">
            <input
              v-if="purchaseData.store !== undefined"
              v-model="purchaseData.store.name"
              type="text"
              class="form-control h-12 read-only:bg-white"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control h-12 read-only:bg-white"
              readonly
            />
          </div>
        </div>
        <div class="input-form mb-4 w-full lg:w-1/2">
          <!-- :class="rolesCanManage.includes(roleAccess) ? 'lg:w-1/2' : ''" -->
          <label
            for="form-product-supplier"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.supplier') }}
          </label>
          <div class="mt-2">
            <input
              v-if="purchaseData.supplier !== undefined"
              v-model="purchaseData.supplier.name"
              type="text"
              class="form-control h-12 read-only:bg-white"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control h-12 read-only:bg-white"
              readonly
            />
          </div>
        </div>
      </div>
      <!-- <template v-else>
        <div class="flex flex-col md:flex-row">
          <div class="input-form mb-4 w-full md:pr-4 lg:w-1/2">
            <label
              for="form-product-supplier"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.supplier') }}
            </label>
            <div class="mt-2">
              <input
                v-if="purchaseData.supplier !== undefined"
                v-model="purchaseData.supplier.name"
                type="text"
                class="form-control h-12 read-only:bg-white"
                readonly
              />
              <input
                v-else
                type="text"
                class="form-control h-12 read-only:bg-white"
                readonly
              />
            </div>
          </div>
          <div class="input-form mb-4 w-full lg:w-1/2">
            <label
              for="form-product-paymentTerm"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.paymentTerm') }}
            </label>
            <div class="mt-2">
              <input
                v-if="purchaseData.payment_term !== undefined"
                v-model="purchaseData.payment_term.name"
                type="text"
                class="form-control h-12 read-only:bg-white"
                readonly
              />
              <input
                v-else
                type="text"
                class="form-control h-12 read-only:bg-white"
                readonly
              />
            </div>
          </div>
        </div>
      </template> -->

      <!-- <template v-if="rolesCanManage.includes(roleAccess)"> -->
      <div class="flex flex-col md:flex-row">
        <div class="input-form mb-4 w-full md:pr-4 lg:w-1/2">
          <label
            for="form-product-paymentTerm"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.paymentTerm') }}
          </label>
          <div class="mt-2">
            <input
              v-if="
                purchaseData.payment !== undefined &&
                purchaseData.payment.payment_term !== undefined
              "
              v-model="purchaseData.payment.payment_term.name"
              type="text"
              class="form-control h-12 read-only:bg-white"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control h-12 read-only:bg-white"
              readonly
            />
          </div>
        </div>
        <div class="relative mx-auto mb-5 h-12 w-full lg:w-1/2">
          <div class="mb-2"><p>Payment Dateline</p></div>
          <div
            class="absolute flex h-12 w-10 items-center justify-center rounded-l border bg-white bg-slate-100 text-slate-500 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
          >
            <CalendarIcon class="h-3 w-3" />
          </div>

          <input
            :value="
              formattedDate(
                purchaseData.payment !== undefined
                  ? purchaseData.payment.payment_due
                  : new Date()
              )
            "
            type="text"
            class="form-control h-12 pl-12 read-only:bg-white"
            readonly
          />
        </div>
      </div>
      <!-- <template v-else>
        <div class="flex flex-col md:flex-row">
          <div
            class="relative mx-auto mb-10 h-12 w-full md:pr-4 lg:mb-5 lg:w-1/2"
          >
            <div class="mb-2"><p>Payment Dateline</p></div>
            <div
              class="absolute flex h-12 w-10 items-center justify-center rounded-l border bg-white bg-slate-100 text-slate-500 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
            >
              <CalendarIcon class="h-3 w-3" />
            </div>

            <input
              :value="formattedDate(purchaseData.payment.payment_due)"
              type="text"
              class="form-control h-12 pl-12 read-only:bg-white"
              readonly
            />
          </div>
          <div class="input-form mb-4 w-full lg:w-1/2">
            <label
              for="form-product-paymentTerm"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              Include Tax
            </label>
            <div class="mt-2">
              <input
                type="text"
                class="form-control h-12 read-only:bg-white"
                :value="`${purchaseData.tax}%`"
                readonly
              />
            </div>
          </div>
        </div>
      </template> -->

      <!-- <template v-if="rolesCanManage.includes(roleAccess)">
        <div class="input-form mb-4 w-full">
          <label
            for="form-product-paymentTerm"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            Include Tax
          </label>
          <div class="mt-2">
            <input
              type="text"
              class="form-control h-12 read-only:bg-white"
              :value="`${purchaseData.tax}%`"
              readonly
            />
          </div>
        </div>
      </template> -->
    </div>

    <div class="intro-y col-span-12 border-t-2 pt-4">
      <div class="mr-auto font-medium">
        <span class="text-lg">Produk yang dibeli</span>
      </div>
      <div class="grid grid-cols-12 gap-5 pt-6 pb-2">
        <div
          v-for="item in purchaseData.items"
          :key="item.key"
          class="relative col-span-12 md:col-span-6 lg:col-span-4 2xl:col-span-3"
        >
          <div class="box flex flex-col">
            <div class="flex w-full">
              <div class="flex w-32 pl-4 pt-4 pb-2">
                <div class="w-full">
                  <ImageLoader
                    :image="
                      item.product.images !== undefined &&
                      item.product.images.length > 0
                        ? item.product.images[0].image
                        : null
                    "
                    :alt="item.product.name"
                    rounded-class="rounded-md w-24 cursor-pointer"
                    @click="imagePreview(item.product)"
                  />
                </div>
              </div>
              <div
                class="flex w-full cursor-pointer flex-col px-4 pt-4 pb-2"
                @click="productPreview(item.product)"
              >
                <div class="flex w-full text-lg font-medium">
                  {{ item.product.name }}
                </div>
                <div class="mt-2 flex w-full">
                  <a
                    class="flex items-center text-xs text-secondary"
                    href="javascript:;"
                    @click="productPreview(item.product)"
                  >
                    <EyeIcon class="mr-1 h-3 w-3" /> Detail Produk
                  </a>
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Quantity
                </div>
                <div class="flex items-end pr-2">
                  <input
                    v-if="item.quantity_received !== null"
                    id="pos-form-4"
                    v-model="item.quantity_received"
                    type="text"
                    class="form-control w-28 text-center text-xs read-only:bg-white"
                    placeholder="0"
                    :readonly="true"
                  />
                  <input
                    v-else
                    id="pos-form-4"
                    v-model="item.quantity"
                    type="text"
                    class="form-control w-28 text-center text-xs read-only:bg-white"
                    placeholder="0"
                    :readonly="true"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Harga Beli
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.price"
                    type="number"
                    class="form-control w-28 text-center text-xs read-only:bg-white"
                    placeholder="0"
                    :readonly="true"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Quantity Bonus
                </div>
                <div class="flex items-end pr-2">
                  <input
                    v-if="item.quantity_bonus_received !== null"
                    id="pos-form-4"
                    v-model="item.quantity_bonus_received"
                    type="text"
                    class="form-control w-28 text-center text-xs read-only:bg-white"
                    placeholder="0"
                    :readonly="true"
                  />
                  <input
                    v-else
                    id="pos-form-4"
                    v-model="item.quantity_bonus"
                    type="text"
                    class="form-control w-28 text-center text-xs read-only:bg-white"
                    placeholder="0"
                    :readonly="true"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Diskon Harga
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.price_discount"
                    type="text"
                    class="form-control w-28 text-center text-xs read-only:bg-white"
                    placeholder="0"
                    :readonly="true"
                  />
                </div>
              </div>
            </div>
            <div class="mt-2 flex w-full flex-col border-t-2 p-2 py-3 pl-4">
              <div class="flex w-full justify-between text-xs">
                <div class="flex items-center">Total Harga</div>
                <div class="flex items-end pr-2">
                  <Currency :total-in-string="item.total_price.toString()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Item List -->
    <!-- BEGIN: Ticket -->

    <div class="col-span-12">
      <div class="box mt-4 mb-8 p-5">
        <div class="flex">
          <div class="mr-auto">Subtotal</div>
          <div class="font-medium">
            <Currency :total-in-string="subTotal.toString()" />
          </div>
        </div>
        <div class="mt-4 flex">
          <div class="mr-auto">Tax</div>
          <div class="font-medium">{{ purchaseData.tax }}%</div>
        </div>
        <div v-if="purchaseData.total_discount !== undefined" class="mt-4 flex">
          <div class="mr-auto">Discount</div>
          <div class="font-medium">
            <Currency
              :total-in-string="purchaseData.total_discount.toString()"
            />
          </div>
        </div>
        <div
          class="mt-4 flex border-t border-slate-200/60 pt-4 dark:border-darkmode-400"
        >
          <div class="mr-auto text-base font-medium">Total Harga</div>
          <div class="text-base font-medium">
            <Currency :total-in-string="total.toString()" />
          </div>
        </div>
      </div>

      <div class="intro-y col-span-12 border-t-2 pt-4">
        <div class="mr-auto font-medium">
          <span class="text-lg">Detail Purchase Order</span>
        </div>
      </div>

      <div class="box col-span-12 mt-4 mb-8 p-5">
        <ol class="pl-2">
          <li
            class="border-l-2"
            :class="
              purchaseData.status === 'COMPLETED'
                ? 'border-green-500'
                : purchaseData.status === 'CANCELLED'
                ? 'border-red-500'
                : 'border-sky-500'
            "
          >
            <div class="flex-start md:flex">
              <div
                class="-ml-3.5 flex h-6 w-6 items-center justify-center rounded-full"
                :class="
                  purchaseData.status === 'COMPLETED'
                    ? 'bg-green-600'
                    : purchaseData.status === 'CANCELLED'
                    ? 'bg-red-600'
                    : 'bg-sky-600'
                "
              >
                <CalendarIcon class="h-3 w-3 text-white" />
              </div>
              <div
                class="ml-6 mb-10 block w-full rounded-lg bg-gray-50 p-5 px-8"
              >
                <div class="flex justify-between">
                  <a
                    href="#!"
                    class="text-sm font-bold transition duration-300 ease-in-out"
                    :class="
                      purchaseData.status === 'COMPLETED'
                        ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                        : purchaseData.status === 'CANCELLED'
                        ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                        : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                    "
                    >{{ formattedDate(purchaseData.created_at) }}</a
                  >
                  <a
                    href="#!"
                    class="text-sm font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                    >Pesanan Dibuat</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="
              purchaseData.status !== 'CANCELLED' &&
              purchaseData.payment !== undefined &&
              purchaseData.payment.payment_at !== null
            "
            class="border-l-2"
            :class="
              purchaseData.status === 'COMPLETED'
                ? 'border-green-500'
                : purchaseData.status === 'CANCELLED'
                ? 'border-red-500'
                : 'border-sky-500'
            "
          >
            <div class="flex-start md:flex">
              <div
                class="-ml-3.5 flex h-6 w-6 items-center justify-center rounded-full"
                :class="
                  purchaseData.status === 'COMPLETED'
                    ? 'bg-green-600'
                    : purchaseData.status === 'CANCELLED'
                    ? 'bg-red-600'
                    : 'bg-sky-600'
                "
              >
                <CalendarIcon class="h-3 w-3 text-white" />
              </div>
              <div
                class="ml-6 mb-10 block w-full rounded-lg bg-gray-50 p-5 px-8"
              >
                <div class="flex justify-between">
                  <a
                    href="#!"
                    class="text-sm font-bold transition duration-300 ease-in-out"
                    :class="
                      purchaseData.status === 'COMPLETED'
                        ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                        : purchaseData.status === 'CANCELLED'
                        ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                        : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                    "
                    >{{ formattedDate(purchaseData.payment.payment_at) }}</a
                  >
                  <a
                    href="#!"
                    class="text-sm font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                    >Pesanan Dibayar</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="
              purchaseData.status !== 'CANCELLED' &&
              purchaseData.received_at !== null
            "
            class="border-l-2"
            :class="
              purchaseData.status === 'COMPLETED'
                ? 'border-green-500'
                : purchaseData.status === 'CANCELLED'
                ? 'border-red-500'
                : 'border-sky-500'
            "
          >
            <div class="flex-start md:flex">
              <div
                class="-ml-3.5 flex h-6 w-6 items-center justify-center rounded-full"
                :class="
                  purchaseData.status === 'COMPLETED'
                    ? 'bg-green-600'
                    : purchaseData.status === 'CANCELLED'
                    ? 'bg-red-600'
                    : 'bg-sky-600'
                "
              >
                <CalendarIcon class="h-3 w-3 text-white" />
              </div>
              <div
                class="ml-6 mb-10 block w-full rounded-lg bg-gray-50 p-5 px-8"
              >
                <div class="flex justify-between">
                  <a
                    href="#!"
                    class="text-sm font-bold transition duration-300 ease-in-out"
                    :class="
                      purchaseData.status === 'COMPLETED'
                        ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                        : purchaseData.status === 'CANCELLED'
                        ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                        : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                    "
                    >{{ formattedDate(purchaseData.received_at) }}</a
                  >
                  <a
                    href="#!"
                    class="text-sm font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                    >Pesanan Diterima</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="['COMPLETED', 'CANCELLED'].includes(purchaseData.status)"
            class="border-green-500"
          >
            <div class="flex-start md:flex">
              <div
                class="-ml-3.5 flex h-6 w-6 items-center justify-center rounded-full"
                :class="
                  purchaseData.status === 'COMPLETED'
                    ? 'bg-green-600'
                    : purchaseData.status === 'CANCELLED'
                    ? 'bg-red-600'
                    : 'bg-sky-600'
                "
              >
                <CalendarIcon class="h-3 w-3 text-white" />
              </div>
              <div class="ml-6 block w-full rounded-lg bg-gray-50 p-5 px-8">
                <div class="flex justify-between">
                  <a
                    href="#!"
                    class="text-sm font-bold transition duration-300 ease-in-out"
                    :class="
                      purchaseData.status === 'COMPLETED'
                        ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                        : purchaseData.status === 'CANCELLED'
                        ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                        : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                    "
                    >{{ formattedDate(purchaseData.updated_at) }}</a
                  >
                  <div>
                    <a
                      v-if="purchaseData.status === 'COMPLETED'"
                      href="#!"
                      class="text-sm font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                      >Pesanan Selesai</a
                    >
                    <a
                      v-else
                      href="#!"
                      class="text-sm font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                      >Pesanan Dibatalkan</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>

      <div
        v-if="
          purchaseData.documents !== undefined &&
          purchaseData.documents.length > 0
        "
        class="intro-y col-span-12 border-t-2 pt-4"
      >
        <div class="mr-auto font-medium">
          <span class="text-lg">Dokumen</span>
        </div>
      </div>
      <div class="mb-8 mt-4 grid gap-4 md:grid-cols-2">
        <div
          v-for="item in purchaseData.documents"
          :key="item.id"
          class="box zoom-in px-2 py-2"
        >
          <Document :document="item.document" />
        </div>
        <!-- </div> -->
      </div>
      <!-- <template
        v-if="roleAccess === 'HEAD_STORE' && purchaseData.received_at === null && purchaseData.payment.payment_at === null"
      > -->
      <!-- <template v-if="purchaseData.received_at === null && purchaseData.payment.payment_at === null">
        <div class="mb-2"><p>Upload Dokumen</p></div>
        <div class="mb-2" @click="selectFile">
          <input
            id="hidden-input"
            type="file"
            accept="application/pdf,image/*"
            class="hidden"
          />
          <label
            for="file"
            class="relative flex cursor-pointer items-center justify-center rounded-md border border-dashed border-gray-300 bg-white py-5 text-center"
          >
            <div v-if="previewDocumentName === null">
              <span class="mb-2 block text-xs font-semibold text-gray-500">
                Jatuhkan file di sini atau klik untuk mengunggah
              </span>
              <span class="mb-2 block text-xs font-medium text-gray-400">
                Hanya mendukung .pdf dan gambar
              </span>
            </div>
            <div v-else>
              <span class="mb-2 block text-xs font-semibold text-gray-400">
                Dokumen yang terpilih
              </span>
              <span class="mb-2 block text-xs font-medium text-green-600">
                {{ previewDocumentName }}
              </span>
            </div>
          </label>
        </div>
      </template>
      <template
        v-if="
          purchaseData.is_received === true &&
          purchaseData.file !== null &&
          purchaseData.payment_file !== null
        "
      >
        <div class="w-full flex-col">
          <div class="mb-2 flex"><p>Dokumen</p></div>
          <div
            class="flex w-full md:w-1/2 lg:w-1/3"
            @click="openURL(purchaseData.file)"
          >
            <label
              class="flex w-full cursor-pointer flex-col items-center justify-center rounded-md border border-dashed border-gray-300 bg-white py-5 text-center"
            >
              <span class="mb-2 block text-xs font-semibold text-gray-400">
                Receive PO
              </span>
              <span class="mb-2 block text-xs font-medium text-green-600">
                Buka dokumen
              </span>
            </label>
          </div>
        </div>
      </template>
      <div class="mt-5 flex">
        <template v-if="purchaseData.received_at === null && purchaseData.payment.payment_at === null">
          <button
            class="btn btn-primary w-full shadow-md md:w-64"
            @click="receivePO"
          >
            <template v-if="isFormLoading === true">
              <LoadingIcon
                icon="spinning-circles"
                color="white"
                class=""
              />&nbsp
            </template>
            Receive PO
          </button>
        </template>
      </div> -->
    </div>
    <!-- END: Ticket -->
  </div>

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct :active-product="activeProduct" />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="activeProduct !== undefined"
        :images="activeProduct.images ?? []"
        :name="activeProduct.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ responseStatus }}</div>
      <div class="mt-1 text-slate-500">
        {{ responseMessage }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ responseStatus }}</div>
      <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
    </div>
  </div>
</template>

<script>
import { provide, onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import Toastify from 'toastify-js'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'
import Status from '@/components/status/Main.vue'
import Document from '@/components/document/Main.vue'

export default {
  components: {
    ImageLoader,
    Currency,
    DetailImage,
    DetailProduct,
    Status,
    Document,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const purchaseData = reactive({})

    const activeProduct = reactive({})

    const subTotal = ref(0)
    const total = ref(0)

    // const dropzoneValidationRef = ref()

    const documentFile = ref(null)
    const previewDocumentName = ref(null)

    const isFormLoading = ref(false)
    const isLoading = ref(false)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)

    const responseStatus = ref('')
    const responseMessage = ref('')

    // provide('bind[dropzoneValidationRef]', (el) => {
    //   dropzoneValidationRef.value = el
    // })

    onMounted(async () => {
      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role.code

      getDetail()

      // const elDropzoneValidationRef = dropzoneValidationRef.value
      // elDropzoneValidationRef.dropzone.on('success', () => {
      //   alert('File ditambahkan')
      // })
      // elDropzoneValidationRef.dropzone.on('error', () => {
      //   alert('Mohon masukan file')
      // })
    })

    const getDetail = async () => {
      isLoading.value = true
      await store
        .dispatch('purchase/getSingle', route.params.id)
        .then((response) => {
          response.data.items.forEach((item) => {
            item.total_price = item.quantity * item.price - item.price_discount
            item.quantity_fixed = item.quantity
            item.quantity_bonus_fixed = item.quantity_bonus
          })

          Object.assign(purchaseData, response.data)
          calculateTotalPrice()

          isLoading.value = false
        })
        .catch((e) => {
          isLoading.value = false
          console.log(e)
        })
    }

    const receivePO = async () => {
      if (documentFile.value === null) {
        responseStatus.value = 'Gagal'
        responseMessage.value = 'Mohon untuk mengupload dokumen terlebih dahulu'
        setTimeout(() => {
          showToast()
        }, 200)

        return
      }

      isFormLoading.value = true

      const products = []

      purchaseData.items.forEach((item) => {
        const product = {
          product_id: item.product.id,
          quantity: item.quantity,
          quantity_bonus: item.quantity_bonus,
        }

        products.push(product)
      })

      const payload = {
        purchase_id: route.params.id,
        document: documentFile.value,
        products,
      }

      await store
        .dispatch('purchase/receive', payload)
        .then((response) => {
          if (response.statusCode === 200) {
            responseStatus.value = response.status
            responseMessage.value = response.message

            isFormLoading.value = false

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
              getDetail()
            }, 200)
          }
        })
        .catch((e) => {
          isFormLoading.value = false
          console.log(e)
        })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        const file = e.target.files[0]
        documentFile.value = file
        previewDocumentName.value = file.name
        // previewImage(e.target.files[0])
      }
    }

    const productPreview = (data) => {
      Object.assign(activeProduct, data)
      productPreviewModal.value = true
    }

    const imagePreview = (data) => {
      Object.assign(activeProduct, data)
      imagePreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const changeQuantity = (product, bonus, added) => {
      if (bonus === true) {
        if (added === true) {
          if (product.quantity_bonus === null) {
            product.quantity_bonus = 1
          } else {
            product.quantity_bonus = product.quantity_bonus + 1
          }
        } else {
          if (product.quantity_bonus !== 0) {
            product.quantity_bonus = product.quantity_bonus - 1
          }
        }
      } else {
        if (added === true) {
          if (product.quantity === null) {
            product.quantity = 1
          } else {
            product.quantity = product.quantity + 1
          }
        } else {
          if (product.quantity !== 0) {
            product.quantity = product.quantity - 1
          }
        }
      }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (product) => {
      if (product.quantity > product.quantity_fixed) {
        product.quantity = product.quantity_fixed
      }
      // if (product.quantity_bonus > product.quantity_bonus_fixed) {
      //   product.quantity_bonus = product.quantity_bonus_fixed
      // }

      product.total_price =
        product.quantity * product.price - product.price_discount

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      purchaseData.items.forEach((item) => {
        totalPrice += item.total_price
      })
      if (purchaseData.tax.toString() !== '0') {
        subTotal.value = totalPrice - Math.round(totalPrice / purchaseData.tax)
      } else {
        subTotal.value = totalPrice
      }
      total.value = totalPrice
    }

    const formattedDate = (date) => {
      return dayjs(date).format('DD MMMM, YYYY')
    }

    const openURL = (url) => {
      return window.open(url, '_blank')
    }

    const formatPrice = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    return {
      t,
      n,
      store,
      route,
      router,

      roleAccess,

      productPreviewModal,
      imagePreviewModal,
      productPreview,
      imagePreview,

      subTotal,
      total,

      // dropzoneValidationRef,
      documentFile,
      previewDocumentName,
      isFormLoading,
      isLoading,

      responseStatus,
      responseMessage,

      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      getDetail,
      receivePO,

      purchaseData,
      activeProduct,

      selectFile,

      showToast,
      formattedDate,

      openURL,
      formatPrice,
    }
  },
}
</script>
