<template>
  <h2 class="intro-y mt-6 text-lg font-medium">List Retur Stock</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <button
        class="btn btn-primary mr-2 shadow-md"
        @click="
          $router.push({
            path: 'add-retur-stock',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        Buat Baru
      </button>
      <Dropdown>
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <PrinterIcon class="mr-2 h-4 w-4" /> Print
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to Excel
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <div class="mx-auto hidden text-slate-500 md:block">
        Showing 1 to 10 of 150 entries
      </div>
      <div class="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <input
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Search..."
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
      <table class="table-report -mt-2 table">
        <thead>
          <tr>
            <th class="whitespace-nowrap">
              <input class="form-check-input" type="checkbox" />
            </th>
            <th class="whitespace-nowrap">ID</th>
            <th class="whitespace-nowrap">PRODUK</th>
            <th class="whitespace-nowrap text-center">TOTAL PRODUK</th>
            <th class="whitespace-nowrap text-center">TOTAL STOCK</th>
            <th class="whitespace-nowrap text-center">TOTAL HARGA</th>
            <th class="whitespace-nowrap text-center">SUPPLIER</th>
            <th class="whitespace-nowrap">TANGGAL RETUR</th>
            <th class="whitespace-nowrap text-center">AKSI</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(faker, fakerKey) in $_.take($f(), 9)"
            :key="fakerKey"
            class="intro-x"
          >
            <td class="w-10">
              <input class="form-check-input" type="checkbox" />
            </td>
            <td class="w-40 !py-4">
              <a
                href="#"
                @click="
                  $router.push({
                    path: 'transaction-detail',
                    params: {
                      slug: $route.params.slug,
                    },
                    query: {
                      lang: $route.query.lang,
                    },
                  })
                "
                class="whitespace-nowrap underline decoration-dotted"
                >{{ '#RTR-' + faker.totals[0] + '290' }}</a
              >
            </td>
            <td class="w-40">
              <div class="flex">
                <div class="image-fit zoom-in h-10 w-10">
                  <Tippy
                    tag="img"
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="faker.products[0].images[0]"
                    :content="`Uploaded at ${faker.dates[0]}`"
                  />
                </div>
                <div class="image-fit zoom-in -ml-5 h-10 w-10">
                  <Tippy
                    tag="img"
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="faker.products[0].images[1]"
                    :content="`Uploaded at ${faker.dates[1]}`"
                  />
                </div>
                <!-- <div class="image-fit zoom-in -ml-5 h-10 w-10">
                    <Tippy
                      tag="img"
                      alt="Gromura - SCM"
                      class="rounded-full"
                      :src="faker.images[2]"
                      :content="`Uploaded at ${faker.dates[2]}`"
                    />
                  </div> -->
              </div>
            </td>
            <td class="text-center">
              {{ ['2', '6', '8', 4][$_.random(0, 1)] }}
            </td>
            <td class="text-center">{{ faker.stocks[0] }}</td>
            <td class="text-center">Rp{{ faker.totals[0] + ',000.00' }}</td>
            <td class="text-center">
              <div
                class="flex items-center justify-center whitespace-nowrap"
                :class="{
                  'text-warning': faker.trueFalse[0],
                  'text-info': !faker.trueFalse[0],
                }"
              >
                {{
                  faker.trueFalse[0] ? 'CV Agung Barokah' : 'CV Sentosa Jaya'
                }}
              </div>
            </td>
            <td>
              <template v-if="faker.trueFalse[0]">
                <div class="whitespace-nowrap">22 November, 12:55</div>
              </template>
              <template v-else>
                <div class="whitespace-nowrap">22 November, 11:00</div>
              </template>
            </td>

            <td class="table-report__action">
              <div class="flex items-center justify-center">
                <a
                  class="mr-5 flex items-center whitespace-nowrap text-primary"
                  href="javascript:;"
                >
                  <CheckSquareIcon class="mr-1 h-4 w-4" /> Lihat Detail
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div>
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref } from 'vue'

const deleteConfirmationModal = ref(false)
</script>
