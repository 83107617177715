<template>
  <h2 class="intro-y mt-6 text-lg font-medium">List Receipt</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
    <button
        class="btn btn-primary mr-2 shadow-md"
        @click="
          $router.push({
            path: '/purchase/order',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        Buat PO
      </button>
      <Dropdown>
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <PrinterIcon class="mr-2 h-4 w-4" /> Print
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
            </DropdownItem>
            <!-- <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem> -->
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <div class="mx-auto hidden text-slate-500 md:block">
        Menampilkan {{ metaData.take * (metaData.page - 1) + 1 }} sampai
        {{
          metaData.take > metaData.itemCount
            ? metaData.itemCount
            : metaData.take * metaData.page
        }}
        dari {{ metaData.itemCount }} data
      </div>
      <div class="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Cari nomor PO ..."
            @input="searchFilter()"
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="purchase in tableData" :key="purchase.id" class="intro-x">
        <td class="!py-3.5">
          {{ purchase.code }}
        </td>
        <td>
          {{ purchase.store.name }}
        </td>
        <td>
          <div class="flex justify-center text-center capitalize">
            <Status :status="purchase.status" />
          </div>
        </td>
        <td>
          <div class="flex items-center">
            <div
              v-for="item in purchase.items.slice(0, 3)"
              :key="item.id"
              class="image-fit zoom-in -ml-5 h-10 w-10"
            >
              <ImageLoader
                :image="
                  item.product.images !== undefined &&
                  item.product.images.length > 0
                    ? item.product.images[0].image
                    : null
                "
                :alt="item.product.name"
                rounded-class="rounded-md"
              />
            </div>
            <!-- <span class="text-xs">
              {{ getProductNames(item.items.slice(0, 3)) }},
            </span> -->
          </div>
        </td>

        <td class="text-center">
          {{ purchase.items.length }}
        </td>
        <td class="text-success">
          <Currency
            :total-in-string="(purchase.total - purchase.total_discount).toString()"
          />
        </td>
        <td class="text-danger">
          <Currency :total-in-string="purchase.total_discount.toString()" />
        </td>
        <!-- <td class="text-center">
          {{ item.quantity }}
        </td> -->
        <!-- <td v-if="purchase.received_at !== null" class="text-center">
          {{ formattedDate(purchase.received_at) }}
        </td>
        <td v-else class="text-center">-</td> -->
        <td class="table-report__action">
          <div class="flex items-center justify-center">
            <template
              v-if="
                ['COMPLETED', 'CANCELLED'].includes(purchase.status) ===
                false
              "
            >
              <a
                class="mx-2 flex items-center text-green-600"
                href="javascript:;"
                @click="changeStatusForm(purchase)"
              >
                <EditIcon class="mr-1 h-4 w-4" /> Ubah Status
              </a>
              |
            </template>
            <!-- <template v-if="purchase.is_payed !== true">
              <a
                class="mx-2 flex items-center text-green-600"
                href="javascript:;"
                @click="detail(item)"
              >
                <DollarSignIcon class="mr-1 h-4 w-4" /> Bayar
              </a>
              |
            </template> -->
            <a
              class="mx-2 flex items-center text-secondary"
              href="javascript:;"
              @click="detail(purchase)"
            >
              <EyeIcon class="mr-1 h-4 w-4" /> Detail
            </a>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->

    <!-- ADD & EDIT MODAL -->
    <Modal
      size="modal-lg"
      :show="changeStatusModal"
      @hidden="changeStatusModal = false"
    >
      <ModalHeader>
        <div class="flex w-full justify-between">
          <div class="flex">
            <h2 class="text-base font-medium">Ubah Status</h2>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="flex w-full flex-col">
          <div class="w-full flex-col">
            <label class="form-label">Status</label>
            <select
              v-model="validate.status.$model"
              class="box form-select mt-1 w-full"
              placeholder="Pilih Status"
            >
              <option value="" disabled selected>
                {{ placeholderStatus }}
              </option>
              <option
                v-for="item in statusOption"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <template v-if="validate.status.$error">
              <div
                v-for="(error, index) in validate.status.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
            <!-- <div v-if="statusError === true" class="mt-2 text-xs text-danger">
              Status harus diisi
            </div> -->
          </div>
          <div
            v-if="['PAID', 'RECEIVED'].includes(formData.status)"
            class="mt-4 w-full"
          >
            <label class="form-label">Upload Dokumen</label>
            <div
              class="rounded-md border-2 border-dashed dark:border-darkmode-400"
              :class="files.length === 0 ? 'py-6' : 'pt-4'"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <div class="flex flex-wrap px-4">
                <div
                  v-for="(file, i) in files"
                  :key="i"
                  class="image-fit zoom-in relative mb-5 mr-5 h-24 w-24 cursor-pointer"
                >
                  <img
                    v-if="file.image !== undefined"
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="file.image"
                  />
                  <div
                    v-else-if="file.type === 'application/pdf'"
                    class="rounded-md px-2 w-full h-full flex flex-col text-center justify-center bg-orange-100 text-orange-600"
                  >
                    <span class="text-xs">{{ file.name }}</span>
                    <!-- <span class="text-sm">{{ file.name }}</span> -->
                  </div>
                  <img
                    v-else
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="generateThumbnail(file)"
                  />
                  <Tippy
                    tag="div"
                    content="Hapus gambar ini?"
                    class="absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                    @click="remove(file)"
                  >
                    <xIcon class="h-4 w-4" />
                  </Tippy>
                </div>
              </div>
              <div
                class="relative flex cursor-pointer flex-col px-4"
                :class="files.length === 0 ? 'py-6' : 'pb-4'"
              >
                <div class="flex items-center">
                  <ImageIcon class="mr-2 h-4 w-4" />
                  <div v-if="isDragging">Lepaskan file disini.</div>
                  <div v-else>
                    <span class="mr-1 text-primary">Unggah file</span>
                    <span class="text-gray-500">atau jatuhkan file disini</span>
                  </div>
                </div>
                <span
                  class="mt-2 block text-xs font-medium italic text-gray-400"
                >
                  Hanya mendukung pdf & gambar
                </span>
                <input
                  id="fileInput"
                  ref="file"
                  multiple
                  type="file"
                  name="file"
                  class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                  accept="application/pdf,image/*"
                  @change="onChange"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-primary px-8"
          @click="changeStatusData"
        >
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Simpan
        </button>
      </ModalFooter>
    </Modal>
  </div>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helpers, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Status from '@/components/status/Main.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Status,
    Currency
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const rolesCanManage = ref(['SUPERADMIN', 'HEAD_OPERATION'])

    let activePO = reactive({})

    const isDragging = ref(false)
    let files = ref([])

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const timer = ref(undefined)

    const changeStatusModal = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'KODE PO',
        customClass: '',
      },
      {
        item: 'GUDANG',
        customClass: '',
      },
      {
        item: 'STATUS',
        customClass: 'text-center',
      },
      {
        item: 'PRODUK',
        customClass: '',
      },
      {
        item: 'TOTAL PRODUK',
        customClass: 'text-center',
      },
      // {
      //   item: 'TOTAL QUANTITY',
      //   customClass: '',
      // },
      {
        item: 'TOTAL HARGA',
        customClass: '',
      },
      {
        item: 'TOTAL DISCOUNT',
        customClass: '',
      },
      // {
      //   item: 'TANGGAL DITERIMA',
      //   customClass: 'text-center',
      // },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    const poStatus = ref([
      // {
      //   id: 'PENDING',
      //   name: 'Tertunda',
      // },
      {
        id: 'PROCESS',
        name: 'Diproses',
      },
      {
        id: 'DELIVERY',
        name: 'Dikirim',
      },
      {
        id: 'PAID',
        name: 'Dibayar',
      },
      {
        id: 'RECEIVED',
        name: 'Diterima',
      },
      {
        id: 'COMPLETED',
        name: 'Selesai',
      },
      {
        id: 'CANCELLED',
        name: 'Dibatalkan',
      },
    ])

    let placeholderStatus = ref('')
    let statusOption = ref([])

    const formData = reactive({
      status: '',
    })

    const statusRequired = helpers.withMessage('Status harus dipilih', required)

    let rules = reactive({
      status: {
        statusRequired,
      },
    })

    const validate = useVuelidate(rules, toRefs(formData))

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    })

    onMounted(async () => {
      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role.code

      getAllData()
    })

    const getAllData = async (success) => {
      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('purchase/getByStore', {
          store_id: activeUser.store.id,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      filterData = event.filterData
      getAllData(false)
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData()
      }, 500)
    }

    const detail = async (purchase) => {
      router.push({
        name: 'purchase-detail',
        params: { id: purchase.id },
        query: { lang: route.query.lang },
      })
    }

    const formattedDate = (date) => {
      return dayjs(date).format('DD-MM-YYYY')
    }

    const getProductNames = (purchase) => {
      const productNames = []
      purchase.map((item) => {
        productNames.push(item.product.name)
      })
      return productNames.toString()
    }

    const onChange = (e) => {
      files.value = [...files.value, ...e.target.files]
    }
    const generateThumbnail = (file) => {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }
    const makeName = (name) => {
      return (
        name.split('.')[0].substring(0, 3) +
        '...' +
        name.split('.')[name.split('.').length - 1]
      )
    }
    const remove = (file) => {
      const index = files.value.indexOf(file)
      files.value.splice(index, 1)
    }
    const dragover = (e) => {
      e.preventDefault()
      isDragging.value = true
    }
    const dragleave = () => {
      isDragging.value = false
    }
    const drop = (e) => {
      e.preventDefault()

      files.value = [...files.value, ...e.dataTransfer.files]

      isDragging.value = false
    }

    const changeStatusForm = async (data) => {
      resetForm()

      if (data.status === 'PENDING') {
        placeholderStatus.value = 'Tertunda'
      } else if (data.status === 'PROCESS') {
        placeholderStatus.value = 'Diproses'
      } else if (data.status === 'DELIVERY') {
        placeholderStatus.value = 'Dikirim'
      } else if (data.status === 'PAID') {
        placeholderStatus.value = 'Dibayar'
      } else if (data.status === 'RECEIVED') {
        placeholderStatus.value = 'Diterima'
      } else if (data.status === 'COMPLETED') {
        placeholderStatus.value = 'Selesai'
      } else if (data.status === 'CANCELLED') {
        placeholderStatus.value = 'Dibatalkan'
      }

      if (data.status === 'PENDING') {
        statusOption.value = [
          {
            id: 'PROCESS',
            name: 'Diproses',
          },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
      } else if (data.status === 'PROCESS') {
        statusOption.value = [
          {
            id: 'DELIVERY',
            name: 'Dikirim',
          },
          {
            id: 'PAID',
            name: 'Dibayar',
          },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
      } else if (
        data.status === 'DELIVERY' &&
        data.payment.payment_at === null
      ) {
        statusOption.value = [
          {
            id: 'PAID',
            name: 'Dibayar',
          },
          {
            id: 'RECEIVED',
            name: 'Diterima',
          },
        ]
      } else if (data.status === 'DELIVERY') {
        statusOption.value = [
          {
            id: 'RECEIVED',
            name: 'Diterima',
          },
        ]
      } else if (data.status === 'PAID' && data.received_at === null) {
        statusOption.value = [
          {
            id: 'RECEIVED',
            name: 'Diterima',
          },
        ]
      } else if (data.status === 'PAID' || data.status === 'RECEIVED') {
        statusOption.value = [
          {
            id: 'COMPLETED',
            name: 'Selesai',
          },
        ]
      }

      activePO.value = data

      changeStatusModal.value = true
    }

    const resetForm = () => {
      validate.value.$reset()

      activePO.value = {}

      formData.status = ''

      files.value = []
    }

    const changeStatusData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true
        const payload = {
          purchase_id: activePO.value.id,
          status: formData.status,
        }

        await store
          .dispatch('purchase/changeStatus', payload)
          .then(async (response) => {
            if (response.statusCode === 200) {
              if (['PAID', 'RECEIVED'].includes(formData.status)) {
                files.value.map(async (file) => {
                  const payloadDoc = {
                    purchase_id: activePO.value.id,
                    type:
                      formData.status === 'RECEIVED' ? 'INVOICE' : 'PAYMENT', // INVOICE, PAYMENT, DOCUMENT, DRAFT
                    document: file,
                  }
                  await store
                    .dispatch('purchase/upload', payloadDoc)
                    .then((response) => {})
                    .catch((error) => {
                      const responseData = error.response.data
                      isFormLoading.value = false
                      status.value = responseData.message.status
                      message.value = responseData.message.detail
                      // show toast
                      setTimeout(() => {
                        showToast(false)
                      }, 200)
                    })
                })
              }

              if (formData.status === 'RECEIVED') {
                const products = []

                activePO.value.items.forEach((item) => {
                  const product = {
                    product_id: item.product.id,
                    quantity: item.quantity,
                    quantity_bonus: item.quantity_bonus,
                    price_discount: item.price_discount,
                  }

                  products.push(product)
                })

                const payload = {
                  purchase_id: activePO.value.id,
                  products,
                }

                await store
                  .dispatch('purchase/receive', payload)
                  .then((response) => {
                    if (response.statusCode === 200) {
                      status.value = response.status
                      message.value = response.message

                      isFormLoading.value = false
                      changeStatusModal.value = false

                      // show toast
                      setTimeout(() => {
                        showToast({
                          success: true,
                        })
                      }, 200)
                    }
                  })
                  .catch((e) => {
                    isFormLoading.value = false
                    console.log(e)
                  })
              } else if (formData.status === 'PAID') {
                const payload = {
                  purchase_id: activePO.value.id,
                }

                await store
                  .dispatch('purchase/paid', payload)
                  .then((response) => {
                    if (response.statusCode === 200) {
                      status.value = response.status
                      message.value = response.message

                      isFormLoading.value = false
                      changeStatusModal.value = false

                      // show toast
                      setTimeout(() => {
                        showToast({
                          success: true,
                        })
                      }, 200)
                    }
                  })
                  .catch((e) => {
                    isFormLoading.value = false
                    console.log(e)
                  })
              } else {
                status.value = response.status
                message.value = response.message

                isFormLoading.value = false
                changeStatusModal.value = false

                // show toast
                setTimeout(() => {
                  showToast({
                    success: true,
                  })
                }, 200)
              }

              validate.value.$reset()

              setTimeout(() => {
                getAllData()
              }, 1500)
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
          })
          .catch((error) => {
            console.log('-error ', error)
            console.log('-error.response ', error.response)
            const responseData = error.response.data
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    return {
      t,
      n,
      store,
      route,
      router,

      roleAccess,
      rolesCanManage,

      detail,

      activePO,
      changeStatusForm,
      changeStatusModal,
      changeStatusData,

      isFormLoading,
      isLoading,
      timer,

      resetForm,
      formData,
      validate,
      poStatus,
      placeholderStatus,
      statusOption,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,
      searchFilter,
      changeFilter,

      getAllData,
      formattedDate,

      getProductNames,

      isDragging,
      files,

      onChange,
      generateThumbnail,
      makeName,
      remove,
      dragover,
      dragleave,
      drop,

      showToast,
    }
  },
}
</script>
