<template>
  <!-- <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <h2 class="mr-auto text-lg font-medium">Sales Order</h2>
  </div> -->
  <div class="intro-y grid grid-cols-12 gap-5 pt-4">
    <div class="col-span-12 pt-4 lg:col-span-8">
      <div class="mb-4 flex w-full">
        <span class="text-lg">Buat Sales Order</span>
      </div>
      <div class="w-full lg:flex">
        <div class="flex w-full md:w-auto">
          <div class="relative sm:w-full">
            <input
              v-model="filterData.search"
              type="text"
              class="form-control box w-full py-3 px-4 pr-10 lg:w-80"
              placeholder="Cari produk..."
              @input="searchFilter()"
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4 text-slate-500"
            />
          </div>

          <!-- <select
            v-model="filterData.type"
            placeholder="Semua"
            class="box form-select ml-4 w-28"
            @change="getProduct(true)"
          >
            <option
              v-for="method in productTypeOptions"
              :key="method.id"
              :value="method.id"
            >
              {{ method.name }}
            </option>
          </select> -->
        </div>
        <!-- <select
            class="box form-select mt-3 ml-auto w-full py-3 px-4 lg:mt-0 lg:w-auto"
          >
            <option>Sort By</option>
            <option>A to Z</option>
            <option>Z to A</option>
            <option>Harga Terendah</option>
            <option>Harga Tertinggi</option>
            <option>Stok Terendah</option>
            <option>Stok Tertinggi</option>
            <option>Minimum Stok</option>
            <option>Maksimum Stok</option>
          </select> -->
      </div>
      <div
        v-if="productData.length === 0"
        class="mt-5 grid grid-cols-12 gap-5 border-b pb-8"
      >
        <div
          class="relative col-span-12 flex h-32 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center md:col-span-6"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Belum ada produk yang dicari
          </span>
        </div>
      </div>
      <div v-else class="mt-5 flex flex-col border-b pb-8">
        <div class="flex w-full">
          <span
            v-if="filterData.search === ''"
            class="text-sm italic text-gray-600"
            >Rekomendasi Produk</span
          >
          <span v-else class="text-sm italic text-gray-600"
            >Hasil Pencarian</span
          >
        </div>
        <div class="mt-4 grid grid-cols-12 gap-5">
          <div
            v-for="item in productData"
            :key="item.product.key"
            class="box col-span-12 flex h-24 md:col-span-6"
          >
            <div class="flex aspect-square">
              <ImageLoader
                :image="
                  item.product.images !== undefined &&
                  item.product.images.length > 0
                    ? item.product.images[0].image
                    : null
                "
                :alt="item.product.name"
                rounded-class="rounded-tl-md rounded-bl-md object-cover"
              />
            </div>
            <div class="flex w-full flex-col p-2">
              <div class="text-md flex w-full font-medium">
                {{ item.product.name }}
              </div>
              <div class="flex w-full text-sm font-medium text-red-500">
                <Currency :total-in-string="item.product.price.toString()" />
              </div>
              <div class="mt-2 flex w-full">
                <a
                  class="flex items-center text-xs text-sky-600"
                  href="javascript:;"
                  @click="productPreview(item)"
                >
                  <EyeIcon class="mr-1 h-4 w-4" /> Detail Produk
                </a>
              </div>
            </div>
            <div
              class="flex cursor-pointer items-center rounded-tr-md rounded-br-md bg-gradient-to-r from-cyan-500 to-teal-400 px-2"
              @click="addItem(item)"
            >
              <PlusCircleIcon class="h-7 w-7 text-white" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 flex w-full">
        <span class="text-lg">Produk Terpilih</span>
      </div>
      <div
        v-if="productDataSelected.length === 0"
        class="mt-5 grid grid-cols-12"
      >
        <div
          class="relative col-span-12 flex h-32 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center md:col-span-6"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Belum ada produk yang dipilih
          </span>
        </div>
      </div>
      <div v-else class="mt-5 grid grid-cols-12 gap-5 pb-8">
        <div
          v-for="item in productDataSelected"
          :key="item.id"
          class="relative col-span-12 md:col-span-6"
        >
          <div class="box flex flex-col">
            <div class="flex w-full">
              <div class="flex w-32 pl-4 pt-4 pb-2">
                <div class="w-full">
                  <ImageLoader
                    :image="
                      item.product.images !== undefined &&
                      item.product.images.length > 0
                        ? item.product.images[0].image
                        : null
                    "
                    :alt="item.product.name"
                    rounded-class="rounded-md w-24 cursor-pointer"
                    @click="imagePreview(item)"
                  />
                </div>
              </div>
              <div
                class="flex w-full cursor-pointer flex-col px-4 pt-4 pb-2"
                @click="productPreview(item)"
              >
                <div class="text-md flex w-full font-medium">
                  {{ item.product.name }}
                </div>
                <div class="flex w-full text-sm font-medium text-red-500">
                  <Currency :total-in-string="item.product.price.toString()" />
                </div>
                <div class="mt-2 flex items-center text-green-600">
                  <LayersIcon class="mr-2 h-4 w-4" /> Stok:
                  {{ item.stock }}
                </div>
              </div>
              <div class="flex w-14 pt-4 pr-4">
                <a
                  class="flex text-xs text-secondary"
                  href="javascript:;"
                  @click="productPreview(item)"
                >
                  <InfoIcon class="mr-1 h-5 w-5" />
                </a>
              </div>
            </div>
            <div class="flex w-full flex-col pt-2 pb-4 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Quantity
                </div>
                <div class="flex items-end pr-2">
                  <button
                    type="button"
                    class="btn mr-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, false, false)"
                  >
                    -
                  </button>
                  <input
                    id="pos-form-4"
                    v-model="item.quantity"
                    type="text"
                    class="form-control w-16 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                  <button
                    type="button"
                    class="btn ml-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, false, true)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="flex w-full pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Harga Beli
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.price"
                    type="text"
                    class="form-control w-36 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                </div>
              </div>
            </div> -->
            <!-- <div class="flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Quantity Bonus
                </div>
                <div class="flex items-end pr-2">
                  <button
                    type="button"
                    class="btn mr-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, true, false)"
                  >
                    -
                  </button>
                  <input
                    id="pos-form-4"
                    v-model="item.quantity_bonus"
                    type="text"
                    class="form-control w-16 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                  <button
                    type="button"
                    class="btn ml-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, true, true)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="mb-2 flex w-full flex-col pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Discount Harga
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.price_discount"
                    type="text"
                    class="form-control w-36 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                </div>
              </div>
            </div> -->
            <div class="flex w-full flex-col border-t-2 p-2 py-3 pl-4">
              <div class="flex w-full justify-between text-xs">
                <div class="flex items-center">Total Harga</div>
                <div class="flex items-end pr-2">
                  <Currency :total-in-string="item.total_price.toString()" />
                </div>
              </div>
            </div>
          </div>
          <Tippy
            content="Hapus item ini?"
            class="tooltip absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-danger text-white"
            @click="removeItem(item)"
          >
            <XIcon class="h-4 w-4" />
          </Tippy>
        </div>
      </div>
    </div>

    <div class="col-span-12 lg:col-span-4">
      <div class="col-span-12">
        <div class="mt-8 flex flex-col md:flex-row">
          <!-- <div class="input-form mb-4 w-full md:w-1/2 md:pr-4">
          <label
            for="form-product-store"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.store') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="shop"
              value="id"
              value-prop="id"
              label="name"
              track-by="name"
              :placeholder="
                isStoreLoading
                  ? 'Memuat data....'
                  : $t('formLabel.select.store')
              "
              :options="storeData"
              :classes="multiSelectClasses"
              :class="isStoreError ? 'border-danger' : ''"
              :searchable="true"
              :loading="isStoreLoading"
              @select="isStoreError = false"
              readonly
            />

            <template v-if="isStoreError">
              <div class="mt-2 text-xs text-danger">Toko harus diisi</div>
            </template>
          </div>
        </div> -->

          <div class="input-form mb-4 w-full">
            <label
              for="form-product-agent"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              <!-- {{ $t('formLabel.customer') }} -->
              Warung
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="customer"
                value="id"
                value-prop="id"
                label="full_name"
                track-by="full_name"
                :placeholder="
                  isCustomerLoading ? 'Memuat data....' : 'Pilih Warung'
                "
                :options="customerData"
                :classes="multiSelectClasses"
                :class="isCustomerError ? 'border-danger' : ''"
                :searchable="true"
                :loading="isCustomerLoading"
                @select="isCustomerError = false"
              />

              <template v-if="isCustomerError">
                <div class="mt-2 text-xs text-danger">Warung harus diisi</div>
              </template>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="input-form mb-4 w-full">
            <label
              for="form-product-paymentMethod"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.paymentMethod') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="paymentMethod"
                value="id"
                value-prop="id"
                label="name"
                track-by="name"
                :placeholder="
                  isPaymentMethodLoading
                    ? 'Memuat data....'
                    : $t('formLabel.select.paymentMethod')
                "
                :options="paymentMethodData"
                :classes="multiSelectClasses"
                :class="isPaymentMethodError ? 'border-danger' : ''"
                :searchable="true"
                :loading="isPaymentMethodLoading"
              />

              <template v-if="isPaymentMethodError">
                <div class="mt-2 text-xs text-danger">
                  Metode Pembayaran harus diisi
                </div>
              </template>
            </div>
          </div>
          <!-- <div class="input-form mb-4 w-full">
            <label
              for="form-product-agent"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.paymentType') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="paymentType"
                value="id"
                value-prop="id"
                label="name"
                track-by="name"
                :placeholder="
                  isPaymentTypeLoading
                    ? 'Memuat data....'
                    : $t('formLabel.select.paymentType')
                "
                :options="paymentTypeData"
                :classes="multiSelectClasses"
                :class="isPaymentTypeError ? 'border-danger' : ''"
                :searchable="true"
                :loading="isPaymentTypeLoading"
                @select="isPaymentTypeError = false"
              />

              <template v-if="isPaymentTypeError">
                <div class="mt-2 text-xs text-danger">
                  Payment Type harus diisi
                </div>
              </template>
            </div>
          </div> -->
        </div>

        <!-- <div
          class="mt-2 flex w-full cursor-pointer items-center"
          @click="changeToAgent"
        >
          <input
            id="is-accessories"
            v-model="isSaleByAgent"
            type="checkbox"
            class="form-check-input mr-2 border"
          />
          <label class="cursor-pointer select-none">
            Dijual oleh Agen Sales ?</label
          >
        </div>
        <div v-if="isSaleByAgent === true" class="input-form w-full">
          <div class="mt-2">
            <Multiselect
              v-model="agent"
              value="id"
              value-prop="id"
              label="first_name"
              track-by="first_name"
              :placeholder="
                isAgentLoading
                  ? 'Memuat data....'
                  : $t('formLabel.select.salesAgent')
              "
              :options="agentData"
              :classes="multiSelectClasses"
              :class="isAgentError ? 'border-danger' : ''"
              :searchable="true"
              :loading="isAgentLoading"
              @select="isAgentError = false"
            />

            <template v-if="isAgentError">
              <div class="mt-2 text-xs text-danger">
                Sales agent harus diisi
              </div>
            </template>
          </div>
        </div> -->
      </div>

      <div class="col-span-12 border-t-2 pt-4 mt-2">
        <div class="mb-2"><p>Include Tax</p></div>
        <select
          v-model="tax"
          class="box form-select mb-4 h-12 w-full"
          @change="calculateTotalPrice"
        >
          <option value="0">0%</option>
          <option value="11">11%</option>
        </select>

        <div class="box mt-4 p-5">
          <div class="flex">
            <div class="mr-auto">Subtotal</div>
            <div class="font-medium">
              <Currency :total-in-string="subTotal.toString()" />
            </div>
          </div>

          <div class="mt-4 flex">
            <div class="mr-auto">Tax</div>
            <div class="font-medium">{{ tax }}%</div>
          </div>
          <div
            class="mt-4 flex border-t border-slate-200/60 pt-4 dark:border-darkmode-400"
          >
            <div class="mr-auto text-base font-medium">Total Harga</div>
            <div class="text-base font-medium">
              <Currency :total-in-string="total.toString()" />
            </div>
          </div>
        </div>
        <div class="mt-5 flex">
          <button
            class="btn w-32 border-slate-300 text-slate-500 dark:border-darkmode-400"
            @click="resetForm"
          >
            Clear Items
          </button>
          <button
            class="btn btn-primary ml-auto w-32 shadow-md"
            @click="addData"
          >
            <template v-if="isFormLoading === true">
              <LoadingIcon
                icon="spinning-circles"
                color="white"
                class=""
              />&nbsp
            </template>
            Buat Pesanan
          </button>
        </div>
      </div>
    </div>
    <!-- END: Ticket -->
  </div>

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct
        v-if="activeProductStock.product !== undefined"
        :active-product="activeProductStock.product"
        :base-price="activeProductStock.price"
        :sale-price="activeProductStock.sale_price"
        :stock="activeProductStock.stock"
      />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="
          activeProductStock !== undefined &&
          activeProductStock.product !== undefined
        "
        :images="activeProductStock.product.images ?? []"
        :name="activeProductStock.product.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import Toastify from 'toastify-js'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import BarcodeGenerator from '@/components/barcode/Generator.vue'
import ShimmerCard from '@/components/shimmer/ShimmerCard.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    BarcodeGenerator,
    ShimmerCard,
    DetailImage,
    DetailProduct,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const rolesCanManage = ref(['SUPERADMIN', 'HEAD_OPERATION'])

    const activeProductStock = reactive({})

    const isSaleByAgent = ref(false)

    const shop = ref('')
    const customer = ref('')
    const agent = ref('')
    const paymentMethod = ref('')
    const paymentType = ref('')

    const tax = ref(0)
    const subTotal = ref(0)
    const total = ref(0)

    const storePage = ref(1)
    const customerPage = ref(1)
    const salesAgentPage = ref(1)
    const paymentMethodPage = ref(1)

    const storeData = ref([])
    const customerData = ref([])
    const agentData = ref([])
    const paymentMethodData = ref([])
    const paymentTypeData = ref([])

    const isFormLoading = ref(false)
    const isStoreLoading = ref(false)
    const isCustomerLoading = ref(false)
    const isAgentLoading = ref(false)
    const isPaymentMethodLoading = ref(false)
    const isPaymentTypeLoading = ref(false)
    const isLoading = ref(false)
    const timer = ref(undefined)

    const isStoreError = ref(false)
    const isCustomerError = ref(false)
    const isAgentError = ref(false)
    const isPaymentMethodError = ref(false)
    const isPaymentTypeError = ref(false)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)

    const status = ref('')
    const message = ref('')

    let productData = ref([])
    // let productRecommendedData = ref([])
    let productDataSelected = ref([])

    let metaData = reactive({
      page: 1,
      take: 6,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 4,
      search: '',
      order: 'ASC',
      // type: null,
    })

    // const productTypeOptions = ref([
    //   {
    //     id: null,
    //     name: 'Semua',
    //   },
    //   {
    //     id: 'UNITS',
    //     name: 'Unit',
    //   },
    //   {
    //     id: 'ACCESSORIES',
    //     name: 'Aksesoris',
    //   },
    // ])

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role.code

      emitter.on('update-data', (store) => {
        console.log(
          `---updating page => ${route.name} for store Id ${store.name} `
        )
        // getAllData(true)
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          // getAllData()
          getProduct()
        }, 2000)
      } else {
        getProduct()
        // getAllData()
      }

      // await getStore()
      await getCustomer()
      await getPaymentMethod()
      await getPaymentType()
    })

    const getStore = async () => {
      const queryParameters = {
        order: 'ASC',
        page: storePage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('store/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const storeTemporary = []
            response.data.data.forEach((item) => {
              storeTemporary.push({
                value: item.code,
                ...item,
              })
            })
            storeData.value = [...storeData.value, ...storeTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('store')
            }
          }
        })
        .catch((error) => {})
    }

    const getCustomer = async () => {
      const queryParameters = {
        order: 'ASC',
        page: customerPage.value,
        take: 50,
        customer_only: true,
        q: '',
      }
      await store
        .dispatch('user/all', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const customerTemporary = []
            response.data.data.forEach((item) => {
              customerTemporary.push({
                value: item.code,
                ...item,
              })
            })
            customerData.value = [...customerData.value, ...customerTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('user')
            }
          }
        })
        .catch((error) => {})
    }

    const getAgent = async (reset) => {
      if (reset === true) {
        salesAgentPage.value = 1
        agentData.value = []
      }

      const queryParameters = {
        order: 'ASC',
        page: salesAgentPage.value,
        take: 50,
        agent_only: true,
        q: '',
      }
      await store
        .dispatch('user/all', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const agentTemporary = []
            response.data.data.forEach((item) => {
              agentTemporary.push({
                value: item.code,
                ...item,
              })
            })
            agentData.value = [...agentData.value, ...agentTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('user')
            }
          }
        })
        .catch((error) => {})
    }

    const getPaymentMethod = async () => {
      const queryParameters = {
        order: 'ASC',
        page: paymentMethodPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('master/paymentMethod', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const paymentMethodTemporary = []
            response.data.data.forEach((item) => {
              paymentMethodTemporary.push({
                value: item.code,
                ...item,
              })
            })
            paymentMethodData.value = [
              ...paymentMethodData.value,
              ...paymentMethodTemporary,
            ]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('paymentMethod')
            }
          }
        })
        .catch((error) => {})
    }

    const getPaymentType = async () => {
      const paymentTypeOptions = [
        {
          id: 'FULL',
          name: 'Lunas',
        },
        {
          id: 'INSTALLMENT',
          name: 'Cicilan',
        },
      ]
      paymentTypeData.value = paymentTypeOptions
    }

    const loadMores = async (type) => {
      if (type === 'user') {
        salesAgentPage.value += 1
        getAgent()
      } else if (type === 'customer') {
        customerPage.value += 1
        getCustomer()
      } else if (type === 'paymentMethod') {
        paymentMethodPage.value += 1
        getPaymentMethod()
      } else if (type === 'store') {
        storePage.value += 1
        getStore()
      }
    }

    const getProduct = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']

      await store
        .dispatch('productStock/getByStore', {
          store_id: storeId,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
          // is_accessories:
          //   filterData.type === 'ACCESSORIES'
          //     ? true
          //     : filterData.type === 'UNITS'
          //     ? false
          //     : null,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            productData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getProduct(true)
      }, 500)
    }

    const productPreview = (data) => {
      Object.assign(activeProductStock, data)
      productPreviewModal.value = true
    }

    const imagePreview = (data) => {
      Object.assign(activeProductStock, data)
      imagePreviewModal.value = true
    }

    const customLabel = (data) => {
      console.log('---data ', data)
      return `${data.first_name} – ${data.last_name}`
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const resetForm = () => {
      shop.value = ''
      customer.value = ''
      agent.value = ''
      paymentMethod.value = ''
      paymentType.value = ''
      tax.value = 0
      subTotal.value = 0
      total.value = 0

      isStoreError.value = false
      isCustomerError.value = false
      isAgentError.value = false
      isPaymentMethodError.value = false
      isPaymentTypeError.value = false

      productData.value = []
      productDataSelected.value = []
    }

    const addItem = (product) => {
      if (productDataSelected.value.length === 0) {
        product.quantity = null
        product.price = null
        // product.quantity_bonus = null
        product.price_discount = null
        product.total_price = 0
        productDataSelected.value = [product]
      } else {
        let isDuplicate = false
        productDataSelected.value.forEach((item) => {
          if (item.id === product.id) {
            isDuplicate = true
          }
        })
        if (isDuplicate === false) {
          product.quantity = null
          product.price = null
          // product.quantity_bonus = null
          product.price_discount = null
          product.total_price = 0
          productDataSelected.value = [...productDataSelected.value, product]
        }
      }
    }

    const removeItem = (product) => {
      productDataSelected.value = productDataSelected.value.filter(
        (item) => item.id != product.id
      )
    }

    const changeQuantity = (product, bonus, added) => {
      // if (bonus === true) {
      //   if (added === true) {
      //     if (product.quantity_bonus === null) {
      //       product.quantity_bonus = 1
      //     } else {
      //       product.quantity_bonus = product.quantity_bonus + 1
      //     }
      //   } else {
      //     if (product.quantity_bonus !== 0) {
      //       product.quantity_bonus = product.quantity_bonus - 1
      //     }
      //   }
      // } else {
      if (added === true) {
        if (product.quantity === null) {
          product.quantity = 1
        } else {
          product.quantity = product.quantity + 1
        }
      } else {
        if (product.quantity !== 0) {
          product.quantity = product.quantity - 1
        }
      }
      // }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (productStock) => {
      productStock.total_price =
        productStock.quantity * productStock.product.price -
        productStock.price_discount

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      productDataSelected.value.forEach((item) => {
        totalPrice += item.total_price
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round(totalPrice / tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
    }

    const changeToAgent = () => {
      isSaleByAgent.value = !isSaleByAgent.value

      if (isSaleByAgent.value === true) {
        getAgent(true)
      }
    }

    const addData = async () => {
      if (productDataSelected.value.length === 0) {
        status.value = 'Gagal'
        message.value = 'Mohon untuk menambahkan item terlebih dahulu'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      let dataNotValid = false
      productDataSelected.value.forEach((item) => {
        if (item.quantity === 0 || item.quantity === null) {
          status.value = 'Gagal'
          message.value = 'Quantity tidak boleh kosong'
          setTimeout(() => {
            showToast()
          }, 200)
          dataNotValid = true
        }
        //  else if (item.price * item.quantity <= item.price_discount) {
        //   status.value = 'Gagal'
        //   message.value = 'Harga Diskon tidak boleh melebihi harga beli'
        //   setTimeout(() => {
        //     showToast()
        //   }, 200)
        //   dataNotValid = true
        // }
      })

      if (dataNotValid === true) {
        return
      }

      // if (shop.value === '') {
      //   isStoreError.value = true
      // } else
      if (customer.value === '') {
        isCustomerError.value = true
      } else if (isSaleByAgent.value === true && agent.value === '') {
        isAgentError.value = true
      } else if (paymentMethod.value === '') {
        isPaymentMethodError.value = true
      }

      if (
        productDataSelected.value.length === 0 ||
        // shop.value === '' ||
        customer.value === '' ||
        paymentMethod.value === '' ||
        (isSaleByAgent.value === true && agent.value === '')
      ) {
        return
      }

      isFormLoading.value = true

      const products = []

      productDataSelected.value.forEach((item) => {
        const product = {
          product_stock_id: item.id,
          quantity: item.quantity,
          // price: item.price,
          // quantity_bonus: item.quantity_bonus,
          // price_discount: item.price_discount,
        }

        products.push(product)
      })

      const storeId = store.getters['store/storeId']

      let payload = {
        store_id: storeId,
        customer_id: customer.value,
        user_id: isSaleByAgent.value === true ? agent.value : activeUser.id,
        // payment_type: paymentType.value,
        payment_method_id: paymentMethod.value,
        channel: "agent-web",
        tax_id: Number(tax.value),
        products,
      }

      await store
        .dispatch('transaction/create', payload)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            // getProduct()
            setTimeout(() => {
              router.push({
                name: 'transaction-detail',
                params: {
                  id: response.data.id,
                  slug: route.params.slug,
                },
                query: {
                  lang: route.query.lang,
                },
              })
            }, 500)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
        })
        .catch((error) => {
          console.log('error ', error)
          console.log('error.response ', error.response)
          const responseData = error.response.data
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeProductStock,

      roleAccess,
      rolesCanManage,

      productPreviewModal,
      imagePreviewModal,
      productPreview,
      imagePreview,

      isSaleByAgent,
      changeToAgent,

      shop,
      customer,
      agent,
      paymentMethod,
      paymentType,
      tax,
      subTotal,
      total,

      storeData,
      customerData,
      agentData,
      paymentMethodData,
      paymentTypeData,

      isFormLoading,
      isStoreLoading,
      isCustomerLoading,
      isAgentLoading,
      isPaymentMethodLoading,
      isPaymentTypeLoading,
      isLoading,
      timer,

      isStoreError,
      isCustomerError,
      isAgentError,
      isPaymentMethodError,
      isPaymentTypeError,

      status,
      message,

      metaData,
      filterData,
      // productTypeOptions,

      productData,
      productDataSelected,

      addItem,
      removeItem,
      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      addData,
      resetForm,

      getStore,
      getCustomer,
      getAgent,
      getPaymentMethod,
      getPaymentType,
      getProduct,

      searchFilter,
      showToast,
      customLabel,
    }
  },
}
</script>
