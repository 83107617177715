<template>
  <h2 class="intro-y mt-4 md:mt-10 text-base md:text-lg font-medium">Laporan Penjualan Harian</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <!-- <button
        class="btn btn-primary mr-2 shadow-md"
        @click="
          $router.push({
            path: 'add-product',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        Central Produk
      </button> -->
      <!-- <Dropdown>
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <PrinterIcon class="mr-2 h-4 w-4" /> Print
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to Excel
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown> -->
      <div class="flex">
        <button class="btn btn-primary mr-2 shadow-md">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block">
        Showing 1 to 10 of 150 entries
      </div>
      <div class="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <!-- <input
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Search..."
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" /> -->
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
      <table class="table-report -mt-2 table">
        <thead>
          <tr>
            <th class="whitespace-nowrap">TANGGAL</th>
            <th class="whitespace-nowrap text-center">JUMLAH TRANSAKSI</th>
            <th class="whitespace-nowrap text-center">STOK TERJUAL</th>
            <th class="whitespace-nowrap text-center">TOTAL PENJUALAN</th>
            <th class="whitespace-nowrap text-center">PERSENTASE</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(faker, fakerKey) in $_.take($f(), 9)"
            :key="fakerKey"
            class="intro-x"
          >
            <td class="text-center">{{ fakerKey + 1 }} Oktober 2022</td>
            <td class="text-center">
              {{ ['50', '100', '200', '250'][$_.random(0, 1)] }}
            </td>
            <td class="text-center">
              {{ ['2500', '1599', '690', '780'][$_.random(0, 1)] }}
            </td>
            <td class="text-center">Rp{{ faker.totals[0] }},000.00</td>
            <td class="w-40">
              <div
                class="flex items-center justify-center"
                :class="{
                  'text-success': faker.trueFalse[0],
                  'text-danger': !faker.trueFalse[0],
                }"
              >
                {{
                  faker.trueFalse[0]
                    ? ['50%', '100%', '78%', '25%'][$_.random(0, 1)]
                    : ['-20%', '-10%', '-28%', '-15%'][$_.random(0, 1)]
                }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div>
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref } from 'vue'

const deleteConfirmationModal = ref(false)
</script>
