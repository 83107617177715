<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <nav aria-label="breadcrumb" class="-intro-x mr-auto hidden md:flex">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">{{ breadcumbName }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ 'title' in $route.meta ? $t($route.meta.title) : '' }}
        </li>
      </ol>
    </nav>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-slate-500" />
      </div>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-slate-500" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href="" class="flex items-center">
              <div
                class="flex h-8 w-8 items-center justify-center rounded-full bg-success/20 text-success dark:bg-success/10"
              >
                <InboxIcon class="h-4 w-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href="" class="mt-2 flex items-center">
              <div
                class="flex h-8 w-8 items-center justify-center rounded-full bg-pending/10 text-pending"
              >
                <UsersIcon class="h-4 w-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href="" class="mt-2 flex items-center">
              <div
                class="flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 text-primary/80 dark:bg-primary/20"
              >
                <CreditCardIcon class="h-4 w-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="mt-2 flex items-center"
            >
              <div class="image-fit h-8 w-8">
                <img
                  alt="Gromura - SCM"
                  class="rounded-full"
                  :src="faker.users[0].image"
                />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div
                class="ml-auto w-48 truncate text-right text-xs text-slate-500"
              >
                {{ faker.users[0].email }}
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            href
            class="mt-2 flex items-center"
          >
            <div class="image-fit h-8 w-8">
              <img
                alt="Gromura - SCM"
                class="rounded-full"
                :src="faker.products[0].images[0]"
              />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div
              class="ml-auto w-48 truncate text-right text-xs text-slate-500"
            >
              {{ faker.products[0].category }}
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div
      v-if="
        storeOptions.length > 0 && generalPages.includes($route.name) === false
      "
      class="md:intro-x mr-2 hidden md:mr-6 md:flex"
    >
      <div class="box flex rounded-full">
        <div
          class="-mr-1 flex w-12 items-center justify-center rounded-l rounded-tl-full rounded-bl-full border bg-slate-50 pl-1 text-slate-600 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
        >
          <WarehouseIcon class="w-4 dark:text-white" />
        </div>
        <select
          v-model="storeId"
          placeholder="Pilih Outlet"
          class="form-select w-full cursor-pointer rounded-tr-full rounded-br-full"
          @change="changeActiveStore()"
        >
          <option
            v-for="store in storeOptions"
            :key="store.id"
            :value="store.id"
          >
            {{ store.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="md:intro-x mr-2 md:mr-4">
      <SunIcon
        v-if="darkMode"
        class="cursor-pointer text-white dark:text-slate-500 md:text-gray-500"
        @click="switchMode"
      />
      <MoonIcon
        v-else
        class="cursor-pointer text-white dark:text-slate-500 md:text-gray-500"
        @click="switchMode"
      />
    </div>
    <Dropdown class="md:intro-x md:mr-6">
      <DropdownToggle
        tag="div"
        role="button"
        class="notification notification--bullet cursor-pointer"
      >
        <BellIcon
          class="notification__icon text-white dark:text-slate-500 md:text-gray-500"
        />
      </DropdownToggle>
      <DropdownMenu class="notification-content pt-2">
        <DropdownContent tag="div" class="notification-content__box">
          <div class="notification-content__title">Notifications</div>
          <div
            v-for="(faker, fakerKey) in $_.take($f(), 5)"
            :key="fakerKey"
            class="relative flex cursor-pointer items-center"
            :class="{ 'mt-5': fakerKey }"
          >
            <div class="image-fit mr-1 h-12 w-12 flex-none">
              <img
                alt="Gromura - SCM"
                class="rounded-full"
                :src="faker.users[0].image"
              />
              <div
                class="absolute right-0 bottom-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
              ></div>
            </div>
            <div class="ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="mr-5 truncate font-medium">{{
                  faker.users[0].name
                }}</a>
                <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                  {{ faker.times[0] }}
                </div>
              </div>
              <div class="mt-0.5 w-full truncate text-slate-500">
                {{ faker.news[0].shortContent }}
              </div>
            </div>
          </div>
        </DropdownContent>
      </DropdownMenu>
    </Dropdown>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <Dropdown v-if="showUserInfo" class="md:intro-x hidden h-8 w-8 md:block">
      <DropdownToggle
        tag="div"
        role="button"
        class="image-fit zoom-in h-8 w-8 overflow-hidden rounded-full shadow-lg"
      >
        <ImageLoader
          :image="activeUser.avatar"
          :alt="`${activeUser.first_name} ${activeUser.last_name}`"
          rounded-class="rounded-full"
        />
      </DropdownToggle>
      <DropdownMenu class="w-56">
        <DropdownContent class="bg-primary text-white">
          <DropdownHeader tag="div" class="!font-normal">
            <div class="font-medium">
              {{ `${activeUser.first_name} ${activeUser.last_name}` }}
            </div>
            <div class="mt-0.5 text-xs text-white/70 dark:text-slate-500">
              {{ activeUser.role.name || null }}
            </div>
          </DropdownHeader>
          <DropdownDivider class="border-white/[0.08]" />
          <DropdownItem
            class="hover:bg-white/5"
            @click="openURL('profile-user')"
          >
            <UserIcon class="mr-2 h-4 w-4" /> {{ $t('action.profile') }}
          </DropdownItem>
          <DropdownItem class="hover:bg-white/5">
            <SettingsIcon class="mr-2 h-4 w-4" /> {{ $t('action.setting') }}
          </DropdownItem>
          <DropdownDivider class="border-white/[0.08]" />
          <DropdownItem class="hover:bg-white/5" @click="showLogoutModal">
            <LogOutIcon class="mr-2 h-4 w-4" /> {{ $t('action.logout') }}
          </DropdownItem>
        </DropdownContent>
      </DropdownMenu>
    </Dropdown>
    <!-- END: Account Menu -->
  </div>
  <Modal :show="logoutModal" @hidden="logoutModal = false">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="my-6 text-xl">{{ $t('confirmation.logout') }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-primary mr-2 w-24"
          @click="logoutModal = false"
        >
          {{ $t('button.cancel') }}
        </button>
        <button type="button" class="btn btn-danger w-24" @click="logout">
          {{ $t('button.logout') }}
        </button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Top Bar -->
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import { useDarkModeStore } from '@/stores/dark-mode'
import dom from '@left4code/tw-starter/dist/js/dom'
import WarehouseIcon from '@/assets/svg/menu/warehouse.svg'

export default {
  components: {
    ImageLoader,
    WarehouseIcon,
  },
  mixins: [globalMixin],
  setup() {
    const searchDropdown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }
    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    const darkModeStore = useDarkModeStore()
    const darkMode = computed(() => darkModeStore.darkMode)

    const setDarkModeClass = () => {
      darkMode.value
        ? dom('html').addClass('dark')
        : dom('html').removeClass('dark')
    }

    const switchMode = () => {
      darkModeStore.setDarkMode(!darkMode.value)
      setDarkModeClass()
    }

    onMounted(async () => {
      setDarkModeClass()
    })

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      switchMode,
      darkMode,
    }
  },
  data() {
    return {
      logoutModal: false,
      showUserInfo: false,
      breadcumbName: '',
      isLoading: false,
      storeOptions: [],
      metaData: {
        page: 1,
        take: 10,
        itemCount: 10,
        pageCount: 1,
        hasPreviousPage: false,
        hasNextPage: true,
      },
      filterData: {
        page: 1,
        take: 10,
        search: '',
        order: 'ASC',
      },
      generalPages: [
        'product',
        'product-principle',
        'product-category',
        'product-sub-category',
        'product-uom',
        'address-province',
        'address-city',
        'address-district',
        'address-village',
        'supplier',
        'store-list',
        'user-manager',
        'payment-term',
        'payment-method',
      ],
    }
  },
  mounted() {
    if (this.isLoggedIn === false) {
      this.$router.push({
        name: 'login',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    } else {
      this.activeUser = this.$store.getters['auth/activeUser']

      if (this.activeUser.store !== undefined) {
        this.breadcumbName = this.activeUser.store.name
        // } else if (this.activeUser.company !== undefined) {
        //   this.breadcumbName = this.activeUser.company.name
      } else {
        this.breadcumbName = this.appName
      }
      if (
        this.activeUser.first_name !== null &&
        this.activeUser.first_name !== ''
      ) {
        this.showUserInfo = true
      }

      // if (this.generalPages.includes(this.$route.name) === false) {
      if (
        this.activeUser.store === undefined &&
        this.storeOptions.length === 0
      ) {
        this.getStore()
      } else {
        this.storeId = this.activeUser.store.id
      }
      // }
    }
  },
  methods: {
    showLogoutModal() {
      this.logoutModal = true
    },
    logout() {
      this.$store.dispatch('auth/logout').then((response) => {
        this.logoutModal = false
        this.isLoggedIn = false

        this.$router.push({
          name: 'login',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      })
    },
    openURL(pageName) {
      this.$router.push({
        name: pageName,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    getStore() {
      // storeOptions
      this.$store
        .dispatch('store/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            this.storeId = responseData.data[0].id
            this.storeName = responseData.data[0].name
            this.storeOptions = responseData.data
          }
        })
    },
    changeActiveStore() {
      this.emitter.emit('update-data', {
        id: this.storeId,
        name: this.storeName,
      })
    },
  },
}
</script>
