import axios, { routePathAPI } from '@/plugins/axios.js'
import router from '@/router'

// initial state
const state = () => ({
  isUserLoggedIn: false,
})

// getters
const getters = {
  isUserLoggedIn(state) {
    if (state.isUserLoggedIn === false) {
      return (
        localStorage.getItem('gromura.accessToken') !== null &&
        localStorage.getItem('gromura.refreshToken') !== null
      )
    }
    return state.isUserLoggedIn
  },
  accessToken(state) {
    return localStorage.getItem('gromura.accessToken') || null
    // return state.isUserLoggedIn
  },
  refreshToken(state) {
    return localStorage.getItem('gromura.refreshToken') || null
    // return state.isUserLoggedIn
  },
  activeUser(state) {
    return JSON.parse(localStorage.getItem('gromura.activeUser')) || null
  },
}

// actions
const actions = {
  updateLoginState({ commit, state }, loggedIn) {
    return commit('SET_IS_USER_LOGGED_IN', loggedIn)
  },
  login({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/login`,
          {
            email: payload.email,
            password: payload.password,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            const data = response.data.data
            const token = data.token
            // SET TOKEN
            const accessToken = token.access_token
            const refreshToken = token.refresh_token

            const tokenType = 'Bearer'
            axios.defaults.headers.common[
              'Authorization'
            ] = `${tokenType} ${accessToken}`

            await commit('SET_ACCESS_TOKEN', accessToken)
            await commit('SET_REFRESH_TOKEN', refreshToken)

            // SET ACTIVE USER
            const user = data.user
            commit('SET_ACTIVE_USER', user)

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit }, payload) {
    commit('SET_IS_USER_LOGGED_IN', false)
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
    commit('REMOVE_ACTIVE_USER')
    commit('REMOVE_ACTIVE_STORE')
    return true
  },
}

// mutations
const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    localStorage.setItem('gromura.accessToken', accessToken)
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    localStorage.setItem('gromura.refreshToken', refreshToken)
  },
  SET_ACTIVE_USER(state, user) {
    localStorage.setItem('gromura.activeUser', JSON.stringify(user))
  },
  SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
    state.isUserLoggedIn = isUserLoggedIn
  },
  REMOVE_ACCESS_TOKEN(state) {
    localStorage.removeItem('gromura.accessToken')
  },
  REMOVE_REFRESH_TOKEN(state) {
    localStorage.removeItem('gromura.refreshToken')
  },
  REMOVE_ACTIVE_USER(state) {
    localStorage.removeItem('gromura.activeUser')
  },
  REMOVE_ACTIVE_STORE(state) {
    localStorage.removeItem('gromura.storeId')
    localStorage.removeItem('gromura.storeName')
  },
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
