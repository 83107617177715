<template>
  <!-- <DarkModeSwitcher /> -->
  <div class="container h-full">
    <div class="flex grid-cols-2 gap-4 lg:block xl:grid">
      <!-- BEGIN: Login Info -->
      <div class="hidden min-h-screen flex-col xl:flex">
        <a href="" class="-intro-x flex items-center pt-5">
          <!-- <img
            alt="Gromura - SCM"
            class="w-6"
            src="@/assets/images/icon.png"
          />
          <span class="ml-3 text-lg text-white"> {{ appName }} </span> -->

          <!-- <img
            alt="Gromura - SCM"
            class="h-12"
            src="@/assets/images/logo-white.png"
          /> -->
        </a>

        <div class="my-auto">
          <Illustration class="-intro-x -mt-16 w-1/2" />
          <div
            class="-intro-x mt-10 pr-20 text-4xl font-medium leading-tight text-white lg:mr-20"
          >
            {{ $t('info.loginGreeting') }}
          </div>
          <div
            class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400"
          >
            {{ $t('info.loginGreetingDetail') }}
          </div>
        </div>
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div
        class="flex h-full w-full md:h-screen md:py-5 xl:my-0 xl:h-auto xl:py-0"
      >
        <div
          class="my-0 h-full w-full bg-white px-5 py-8 dark:bg-darkmode-600 sm:px-8 md:my-auto md:mx-auto md:h-auto md:rounded-md md:shadow-md lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none"
        >
          <a href="#" class="-intro-x mb-10 flex w-full justify-start xl:mb-12">
            <!-- <GromuraIcon class="w-12 text-white" /> -->
            <!-- <span class="ml-3 text-lg text-gray-800 dark:text-white">
              Gromura
            </span> -->
            <img
              alt="Gromura - SCM"
              class="h-10 md:h-12"
              src="@/assets/images/logo.png"
            />
          </a>
          <h2
            class="intro-x mt-8 text-left text-xl font-bold md:text-2xl xl:text-left xl:text-3xl"
          >
            {{ $t('general.greeting') }}
          </h2>
          <p class="intro-x mt-4 text-left text-sm xl:text-left">
            {{ $t('formInfo.loginDesc') }}
          </p>
          <div class="intro-x mt-2 text-left text-xs text-slate-400 xl:hidden">
            {{ $t('info.loginGreeting') }},
            {{ $t('info.loginGreetingDetail') }}
          </div>
          <form class="validate-form" @submit.prevent="login">
            <div class="intro-x mt-8">
              <div class="input-form mt-3">
                <label
                  for="form-email"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.email') }}
                </label>
                <input
                  id="form-email"
                  v-model.trim="validate.email.$model"
                  type="email"
                  name="email"
                  class="intro-x login__input form-control block py-3 px-4"
                  :class="{ 'border-danger': validate.email.$error }"
                  :placeholder="$t('formLabel.email')"
                />
                <template v-if="validate.email.$error">
                  <div
                    v-for="(error, index) in validate.email.$errors"
                    :key="index"
                    class="mt-2 text-danger"
                  >
                    {{ $t(error.$message) }}
                  </div>
                </template>
              </div>
              <div class="input-form mt-3">
                <label
                  for="form-password"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.password') }}
                </label>
                <input
                  id="form-password"
                  v-model.trim="validate.password.$model"
                  type="password"
                  name="password"
                  class="intro-x login__input form-control mt-4 block py-3 px-4"
                  :class="{ 'border-danger': validate.password.$error }"
                  :placeholder="$t('formLabel.password')"
                />
                <template v-if="validate.password.$error">
                  <div
                    v-for="(error, index) in validate.password.$errors"
                    :key="index"
                    class="mt-2 text-danger"
                  >
                    {{ $t(error.$message) }}
                  </div>
                </template>
              </div>
            </div>
            <div
              class="intro-x mt-4 flex text-xs text-slate-600 dark:text-slate-500 sm:text-sm"
            >
              <!-- <div class="mr-auto flex items-center">
              <input
                id="remember-me"
                type="checkbox"
                class="form-check-input mr-2 border"
              />
              <label class="cursor-pointer select-none" for="remember-me"
                >Remember me</label
              >
            </div> -->
              <a href="">{{ $t('button.forgotPassword') }}</a>
            </div>
            <div class="intro-x mt-5 text-center xl:mt-8 xl:text-left">
              <button
                type="submit"
                class="btn btn-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32"
              >
                <LoadingIcon
                  v-if="isLoading"
                  icon="spinning-circles"
                  color="white"
                  class="ml-2 h-4 w-4"
                />
                &nbsp
                {{ $t('button.login') }}
              </button>
              <!-- <button
                class="btn btn-outline-primary mt-3 w-full py-3 px-4 align-top xl:mt-0 xl:w-32"
              >
                {{ $t('button.register') }}
              </button> -->
            </div>
          </form>
          <!-- <div
            class="intro-x mt-10 text-center text-slate-600 dark:text-slate-500 xl:mt-16 xl:text-left"
          >
            {{ $t('formInfo.loginCheck') }}
            <a class="text-primary dark:text-slate-200" href="">{{
              $t('general.tnc')
            }}</a>
            &
            <a class="text-primary dark:text-slate-200" href="">{{
              $t('general.privacyPolicy')
            }}</a>
          </div> -->
          <div
            class="intro-x mt-4 text-center text-xs text-slate-600 dark:text-slate-500 md:text-sm xl:mt-10 xl:text-left"
          >
            Copyright {{ appName }} {{ currentYear }}
          </div>
        </div>
      </div>
      <div
        id="success-notification-content"
        class="toastify-content flex hidden"
      >
        <CheckCircleIcon class="text-success" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ responseStatus }}</div>
          <!-- <div class="font-medium">{{ $t('formInfo.loginSuccess') }}</div> -->
          <div class="mt-1 text-slate-500">
            {{ responseMessage }}
          </div>
        </div>
      </div>
      <!-- END: Success Notification Content -->
      <!-- BEGIN: Failed Notification Content -->
      <div
        id="failed-notification-content"
        class="toastify-content flex hidden"
      >
        <XCircleIcon class="text-danger" />
        <div class="ml-4 mr-4">
          <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
          <div class="font-medium">{{ responseStatus }}</div>
          <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
        </div>
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
</template>

<script>
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import Toastify from 'toastify-js'
import dom from '@left4code/tw-starter/dist/js/dom'
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  url,
  integer,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useStore } from 'vuex'
import globalMixin from '@/mixins/global.js'
import GromuraDynamicIcon from '@/assets/svg/gromura-icon-dynamic.svg'
import GromuraIcon from '@/assets/svg/gromura-icon.svg'
import Illustration from '@/assets/svg/illustration.svg'

export default {
  name: 'LoginPage',
  components: {
    DarkModeSwitcher,
    Toastify,
    Illustration,
    GromuraDynamicIcon,
    GromuraIcon,
  },
  mixins: [globalMixin],
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t, n } = useI18n()

    const isLoading = ref(false)
    const currentYear = ref(new Date().getFullYear())
    const responseStatus = ref('')
    const responseMessage = ref('')

    const formData = reactive({
      email: '',
      password: '',
    })

    const emailRequired = helpers.withMessage(
      'formError.email.required',
      required
    )
    const emailValid = helpers.withMessage('formError.email.validEmail', email)
    const passwordRequired = helpers.withMessage(
      'formError.password.required',
      required
    )
    const passwordMinLength = helpers.withMessage(
      'formError.password.minLength',
      minLength(6)
    )

    const rules = {
      email: {
        emailRequired,
        emailValid,
      },
      password: {
        passwordRequired,
        // minLength: minLength(6),
        minLength: passwordMinLength,
      },
    }

    onMounted(() => {
      dom('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const validate = useVuelidate(rules, toRefs(formData))

    const showToast = (success) => {
      let toastId = '#failed-notification-content'
      if (success) {
        toastId = '#success-notification-content'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }
    const login = async () => {
      validate.value.$touch()
      if (validate.value.$invalid == false) {
        isLoading.value = true
        const payload = {
          email: formData.email,
          password: formData.password,
        }

        await store
          .dispatch('auth/login', payload)
          .then((response) => {
            if (response.statusCode === 200) {
              responseStatus.value = response.status
              responseMessage.value = response.message
              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              const user = response.data.user
              if (
                user !== null &&
                user.role !== null &&
                user.role.code === 'CASHIER'
              ) {
                setTimeout(() => {
                  router.push({
                    name: 'point-of-sale',
                    params: { slug: route.params.slug },
                    query: { lang: route.query.lang },
                  })
                }, 2000)
              } else {
                setTimeout(() => {
                  router.push({
                    name: 'dashboard',
                    params: { slug: route.params.slug },
                    query: { lang: route.query.lang },
                  })
                }, 2000)
              }
            } else {
              responseStatus.value = response.status
              responseMessage.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isLoading.value = false
          })
          .catch((error) => {
            const data = error.response.data
            isLoading.value = false
            responseStatus.value = data.message.status
            responseMessage.value = data.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    return {
      t,
      n,
      isLoading,
      currentYear,
      responseStatus,
      responseMessage,
      validate,
      login,
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({
        name: 'dashboard',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    }
  },
}
</script>
