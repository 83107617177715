<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Produk</h2>
  <template v-if="rolesCanManage.includes(roleAccess)">
    <ButtonAdd :is-loading="isButtonLoading" @click="addForm" />
  </template>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div class="intro-y col-span-12 flex flex-wrap items-center sm:flex-nowrap">
      <!-- <FilterSearch
        :show-option="true"
        :is-loading="isSubCategoryFilterLoading"
        :search="filterData.search"
        :option="filterData.sub_category_ids"
        :search-options="subCategoryFilterData"
        @search-filter="searchFilter()"
        @change="getAllData(true)"
      /> -->
      <div class="flex w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-56 pr-8"
            placeholder="Cari produk..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto -mr-4 h-4 w-4"
          />
        </div>
        <select
          v-model="filterData.sub_category_ids"
          class="box form-select ml-10"
          @change="getAllData(true)"
        >
          <option :value="null">
            {{ isSubCategoryFilterLoading ? 'Memuat data....' : 'Semua' }}
          </option>
          <option
            v-for="item in subCategoryFilterData"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <button class="btn btn-primary mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary text-white">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="item in tableData" :key="item.id" class="intro-x">
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="item.images.length > 0 ? item.images[0].image : null"
                :alt="item.name"
                rounded-class="rounded-md"
                @click="imagePreview(item)"
              />
            </div>
          </div>
        </td>
        <td>
          {{ item.name }}
        </td>
        <td class="w-40 text-green-600">
          <Currency :total-in-string="item.price" />
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          <template v-if="item.sub_category !== undefined">
            <!-- {{ item.sub_category.code }} - -->
            {{ item.sub_category.name }}
            <!-- <template v-if="item.sub_category.category !== undefined"> -->
            <!-- {{ item.sub_category.category.code }} -
              {{ item.sub_category.category.name }} -->
            <!-- </template> -->
            <!-- <template v-else> - </template> -->
          </template>
          <template v-else> - </template>
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          <template v-if="item.brand !== undefined">
            {{ item.brand.name }}
          </template>
        </td>
        <!-- <td v-if="filterData.type === null">
          <div class="flex justify-center text-center capitalize">
            <span v-if="item.is_accessories === true" class="mr-1 rounded-full bg-secondary px-3 py-1 text-white">
              AKSESORIS
            </span>
            <span v-else class="mr-1 rounded-full bg-danger px-3 py-1 text-white">
              UNIT
            </span>
          </div>
        </td> -->
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center">
            <a
              class="flex items-center text-secondary"
              href="javascript:;"
              @click="productPreview(item)"
            >
              <EyeIcon class="mr-1 h-4 w-4" /> Detail
            </a>
            <template v-if="rolesCanManage.includes(roleAccess)">
              <template
                v-if="
                  isSubCategoryLoading === false &&
                  isBrandLoading === false &&
                  isUnitLoading === false
                "
              >
                <a
                  class="ml-3 flex items-center text-green-500"
                  href="javascript:;"
                  @click="editForm(item)"
                >
                  <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
                </a>
              </template>
              <template v-else>
                <a
                  class="ml-3 flex items-center text-green-300"
                  href="javascript:;"
                >
                  <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
                </a>
              </template>
              <a
                class="ml-3 flex items-center text-danger"
                href="javascript:;"
                @click="deleteForm(item)"
              >
                <Trash2Icon class="mr-1 h-4 w-4" /> Delete
              </a>
            </template>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- ADD & EDIT MODAL -->
  <Modal size="modal-lg" :show="addEditModal" @hidden="addEditModal = false">
    <ModalHeader>
      <div class="flex w-full justify-between">
        <div class="flex">
          <h2 class="text-base font-medium">{{ titleModal }}</h2>
        </div>
        <div v-if="isEditForm === false" class="form-check form-switch flex">
          <label class="form-check-label text-sm text-warning"
            >Multiple uom?</label
          >
          <input
            v-model="isAccessories"
            class="form-check-input mr-0 ml-3"
            type="checkbox"
            @input="changeAccessories"
          />
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div class="flex w-full flex-col">
        <div class="w-full">
          <label class="form-label">Gambar Produk</label>
          <div
            class="rounded-md border-2 border-dashed dark:border-darkmode-400"
            :class="files.length === 0 ? 'py-6' : 'pt-4'"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <div class="flex flex-wrap px-4">
              <div
                v-for="(file, i) in files"
                :key="i"
                class="image-fit zoom-in relative mb-5 mr-5 h-24 w-24 cursor-pointer"
              >
                <img
                  v-if="file.image !== undefined"
                  class="rounded-md"
                  alt="Gromura - SCM"
                  :src="file.image"
                />
                <img
                  v-else
                  class="rounded-md"
                  alt="Gromura - SCM"
                  :src="generateThumbnail(file)"
                />
                <Tippy
                  tag="div"
                  content="Hapus gambar ini?"
                  class="absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                  @click="remove(file)"
                >
                  <xIcon class="h-4 w-4" />
                </Tippy>
              </div>
            </div>
            <div
              class="relative flex cursor-pointer flex-col px-4"
              :class="files.length === 0 ? 'py-6' : 'pb-4'"
            >
              <div class="flex items-center">
                <ImageIcon class="mr-2 h-4 w-4" />
                <div v-if="isDragging">Lepaskan file disini.</div>
                <div v-else>
                  <span class="mr-1 text-primary">Unggah file</span>
                  <span class="text-gray-500">atau jatuhkan file disini</span>
                </div>
              </div>
              <span class="mt-2 block text-xs font-medium italic text-gray-400">
                Hanya mendukung gambar
              </span>
              <input
                id="fileInput"
                ref="file"
                multiple
                type="file"
                name="file"
                class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                accept="image/*"
                @change="onChange"
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="input-form mt-5 w-full">
            <label
              for="form-name"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.productName') }}
            </label>
            <input
              id="form-name"
              v-model.trim="validate.name.$model"
              type="text"
              name="name"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.name.$error }"
              :placeholder="$t('formInput.enterProductName')"
            />
            <template v-if="validate.name.$error">
              <div
                v-for="(error, index) in validate.name.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-price"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.salePrice') }}
              <template v-if="isAccessories"> (satuan kecil) </template>
            </label>
            <input
              id="form-price"
              v-model.trim="validate.price.$model"
              type="number"
              name="price"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.price.$error }"
              :placeholder="$t('formInput.enterSalePrice')"
              @keypress="isNumber($event)"
            />
            <template v-if="validate.price.$error">
              <div
                v-for="(error, index) in validate.price.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-product-sub-category"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.productSubCategory') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="subCategory"
                value="id"
                value-prop="id"
                label="name"
                track-by="name"
                :placeholder="
                  isSubCategoryLoading
                    ? 'Memuat data....'
                    : $t('formLabel.select.productSubCategory')
                "
                :options="subCategoryData"
                :classes="multiSelectClasses"
                :class="isSubCategoryError ? 'border-danger' : ''"
                :searchable="true"
                :loading="isSubCategoryLoading"
                @select="isSubCategoryError = false"
              />

              <template v-if="isSubCategoryError">
                <div class="mt-2 text-xs text-danger">
                  Sub Kategori Produk harus diisi
                </div>
              </template>
            </div>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-product-brand"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.productBrand') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="brand"
                value="id"
                value-prop="id"
                label="name"
                track-by="name"
                :placeholder="
                  isBrandLoading
                    ? 'Memuat data....'
                    : $t('formLabel.select.productBrand')
                "
                :options="brandData"
                :classes="multiSelectClasses"
                :class="isBrandError ? 'border-danger' : ''"
                :searchable="true"
                :loading="isBrandLoading"
                @select="isBrandError = false"
              />

              <template v-if="isBrandError">
                <div class="mt-2 text-xs text-danger">
                  Principle Produk harus diisi
                </div>
              </template>
            </div>
          </div>
          <div class="input-form mt-3 w-full">
            <label class="form-label flex w-full flex-col sm:flex-row">
              <template v-if="isAccessories"> Satuan Besar </template>
              <template v-else> Satuan </template>
            </label>
            <div
              class="flex w-full flex-col rounded-lg p-3"
              :class="isAccessories ? 'bg-green-100' : 'bg-gray-100'"
            >
              <div class="flex w-full">
                <div
                  class="flex flex-col"
                  :class="isAccessories ? 'w-2/3' : 'w-full'"
                >
                  <label
                    for="form-product-unit-big"
                    class="form-label flex w-full flex-col text-xs sm:flex-row"
                  >
                    {{ $t('formLabel.productUnit') }}
                  </label>
                  <div>
                    <Multiselect
                      v-model="unitBig"
                      value="id"
                      value-prop="id"
                      label="name"
                      track-by="name"
                      :placeholder="
                        isUnitLoading
                          ? 'Memuat data....'
                          : $t('formLabel.select.productUnit')
                      "
                      :options="unitBigData"
                      :classes="multiSelectClasses"
                      :class="isUnitBigError ? 'border-danger' : ''"
                      :searchable="true"
                      :loading="isUnitLoading"
                      @select="isUnitBigError = false"
                    />

                    <template v-if="isUnitBigError">
                      <div class="mt-2 text-xs text-danger">
                        Unit Produk harus diisi
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="isAccessories" class="flex w-1/3 flex-col pl-3">
                  <label
                    for="form-code"
                    class="form-label flex w-full flex-col text-xs sm:flex-row"
                  >
                    Converter
                  </label>
                  <input
                    id="form-code"
                    type="text"
                    name="code"
                    readonly="true"
                    placeholder="1"
                    class="intro-x login__input form-control block py-3 px-4"
                  />
                </div>
              </div>
              <div class="mt-2 flex w-full flex-col">
                <label
                  for="form-barcode-small"
                  class="form-label flex w-full flex-col text-xs sm:flex-row"
                >
                  Barcode
                </label>
                <input
                  id="form-barcode-small"
                  v-model.trim="validate.barcodeBig.$model"
                  type="text"
                  name="barcode-small"
                  class="intro-x login__input form-control block py-3 px-4"
                  :class="{ 'border-danger': validate.barcodeBig.$error }"
                  placeholder="XXXX-XXX-XXXX"
                />
                <template v-if="validate.barcodeBig.$error">
                  <div
                    v-for="(error, index) in validate.barcodeBig.$errors"
                    :key="index"
                    class="mt-2 text-xs text-danger"
                  >
                    {{ $t(error.$message) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-if="isAccessories" class="input-form mt-3 w-full">
            <label class="form-label flex w-full flex-col sm:flex-row">
              Satuan Kecil
            </label>
            <div class="flex w-full flex-col rounded-lg bg-sky-100 p-3">
              <div class="flex w-full">
                <div class="flex w-2/3 flex-col">
                  <label
                    for="form-product-unit-small"
                    class="form-label flex w-full flex-col text-xs sm:flex-row"
                  >
                    {{ $t('formLabel.productUnit') }}
                  </label>
                  <div>
                    <Multiselect
                      v-model="unitSmall"
                      value="id"
                      value-prop="id"
                      label="name"
                      track-by="name"
                      :placeholder="
                        isUnitLoading
                          ? 'Memuat data....'
                          : $t('formLabel.select.productUnit')
                      "
                      :options="unitSmallData"
                      :classes="multiSelectClasses"
                      :class="isUnitSmallError ? 'border-danger' : ''"
                      :searchable="true"
                      :loading="isUnitLoading"
                      @select="isUnitSmallError = false"
                    />

                    <template v-if="isUnitSmallError">
                      <div class="mt-2 text-xs text-danger">
                        Unit Produk harus diisi
                      </div>
                    </template>
                  </div>
                </div>
                <div class="flex w-1/3 flex-col pl-3">
                  <label
                    for="form-converter"
                    class="form-label flex w-full flex-col text-xs sm:flex-row"
                  >
                    Converter
                  </label>
                  <input
                    id="form-converter"
                    v-model.trim="validate.converter.$model"
                    type="number"
                    name="converter"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.converter.$error }"
                    placeholder="0"
                    @keypress="isNumber($event)"
                  />
                  <template v-if="validate.converter.$error">
                    <div
                      v-for="(error, index) in validate.converter.$errors"
                      :key="index"
                      class="mt-2 text-xs text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="mt-2 flex w-full flex-col">
                <label
                  for="form-barcode-big"
                  class="form-label flex w-full flex-col text-xs sm:flex-row"
                >
                  Barcode
                </label>
                <input
                  id="form-barcode-big"
                  v-model.trim="validate.barcodeSmall.$model"
                  type="text"
                  name="barcode-big"
                  class="intro-x login__input form-control block py-3 px-4"
                  :class="{ 'border-danger': validate.barcodeSmall.$error }"
                  placeholder="XXXX-XXX-XXXX"
                />
                <template v-if="validate.barcodeSmall.$error">
                  <div
                    v-for="(error, index) in validate.barcodeSmall.$errors"
                    :key="index"
                    class="mt-2 text-xs text-danger"
                  >
                    {{ $t(error.$message) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="input-form mt-3 w-full">
            <label class="form-label flex w-full flex-col">
              Description (Opsional)
            </label>

            <ClassicEditor
              v-model="validate.description.$model"
              class="rounded-lg"
            />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" class="btn btn-primary px-8" @click="addData">
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button>
    </ModalFooter>
  </Modal>

  <!-- DELETE MODAL -->
  <Modal :show="deleteModal" @hidden="cancelDelete">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5">Apakah anda yakin ingin menghapus data ini ?</div>
        <div class="mt-2 text-lg text-slate-600">{{ activeData.name }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary mr-4 w-24"
          @click="cancelDelete"
        >
          Batal
        </button>
        <button type="button" class="btn btn-danger w-32" @click="deleteData">
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Hapus
        </button>
      </div>
    </ModalBody>
  </Modal>

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct :active-product="activeProduct" />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="activeProduct !== undefined"
        :images="activeProduct.images"
        :name="activeProduct.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  requiredIf,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { Pagination } from 'swiper/modules'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import TableComponent from '@/components/TableComponent.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'
import ButtonAdd from '@/components/button/ButtonAdd.vue'
import FilterSearch from '@/components/filter/FilterSearch.vue'

export default {
  components: {
    TableComponent,
    ImageLoader,
    Currency,
    Multiselect,
    Pagination,
    DetailImage,
    DetailProduct,
    ButtonAdd,
    FilterSearch
  },
  mixins: [globalMixin],
  // methods: {
  //   isNumber: function (evt) {
  //     evt = evt ? evt : window.event
  //     var charCode = evt.which ? evt.which : evt.keyCode
  //     if (
  //       charCode > 31 &&
  //       (charCode < 48 || charCode > 57) &&
  //       charCode !== 46
  //     ) {
  //       evt.preventDefault()
  //     } else {
  //       return true
  //     }
  //   },
  // },
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const rolesCanManage = ref(['SUPERADMIN', 'HEAD_OPERATION'])

    const activeProduct = reactive({})

    const isAccessories = ref(false)
    const isEditForm = ref(false)

    const isDragging = ref(false)
    let files = ref([])
    let filesDeleted = ref([])

    const productImagePreview = ref('')
    const imageFile = ref(null)

    // const isCategoryError = ref(false)
    const isSubCategoryError = ref(false)
    const isBrandError = ref(false)
    const isUnitBigError = ref(false)
    const isUnitSmallError = ref(false)

    // const pageCategory = ref(1)
    const pageSubCategory = ref(1)
    const pageBrand = ref(1)
    const pageUnit = ref(1)

    // const category = ref('')
    // const categoryData = ref([])

    const subCategory = ref('')
    const subCategoryData = ref([])

    const brand = ref('')
    const brandData = ref([])

    const unitBig = ref('')
    const unitSmall = ref('')

    const unitSmallData = ref([])
    const unitBigData = ref([])

    // const isCategoryLoading = ref(false)
    const isSubCategoryLoading = ref(false)
    const isBrandLoading = ref(false)
    const isUnitLoading = ref(false)

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isButtonLoading = ref(false)
    const isSubCategoryFilterLoading = ref(false)
    
    const subCategoryPage = ref(1)
    let subCategoryFilterData = ref([])

    const timer = ref(undefined)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)
    const deleteModal = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'GAMBAR',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      {
        item: 'HARGA',
        customClass: '',
      },
      {
        item: 'SUB KATEGORI',
        customClass: '',
      },
      {
        item: 'MERK',
        customClass: '',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      order: 'ASC',
      type: null,
      search: '',
      sub_category_ids: null,
    })

    const productTypeOptions = ref([
      {
        id: null,
        name: 'Semua',
      },
      {
        id: 'UNITS',
        name: 'Unit',
      },
      {
        id: 'ACCESSORIES',
        name: 'Aksesoris',
      },
    ])

    const activeData = reactive({
      image: null,
      converter: '',
      name: '',
      description: '',
      price: '',
      barcodeBig: '',
      barcodeSmall: '',
    })

    const formData = reactive({
      image: null,
      converter: '',
      name: '',
      description: '',
      price: '',
      barcodeBig: '',
      barcodeSmall: '',
    })

    const nameRequired = helpers.withMessage(
      'formError.productName.required',
      required
    )
    const priceRequired = helpers.withMessage(
      'formError.salePrice.required',
      required
    )
    const converterRequired = helpers.withMessage(
      'formError.converter.required',
      required
    )
    const barcodeRequired = helpers.withMessage(
      'formError.barcode.required',
      required
    )

    let rules = reactive({
      name: {
        nameRequired,
      },
      description: {},
      price: {
        priceRequired,
      },
      converter: {
        converterRequired: requiredIf(isAccessories.value),
      },
      barcodeBig: {
        barcodeRequired,
      },
      barcodeSmall: {
        barcodeRequired: requiredIf(isAccessories.value),
      },
    })

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      // getSubCategoryData()
      // getBrandData()
      // getUnitData()
      getSubCategoryFilterData()
      getAllData()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role.code
    })

    const onChange = (e) => {
      files.value = [...files.value, ...e.target.files]
    }
    const generateThumbnail = (file) => {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }

    const makeName = (name) => {
      return (
        name.split('.')[0].substring(0, 3) +
        '...' +
        name.split('.')[name.split('.').length - 1]
      )
    }
    const remove = (file) => {
      const index = files.value.indexOf(file)
      files.value.splice(index, 1)

      if (file.id !== undefined && file.image !== undefined) {
        filesDeleted.value.push(file.id)
      }
    }
    const dragover = (e) => {
      e.preventDefault()
      isDragging.value = true
    }
    const dragleave = () => {
      isDragging.value = false
    }
    const drop = (e) => {
      e.preventDefault()

      files.value = [...files.value, ...e.dataTransfer.files]

      isDragging.value = false
    }

    const addData = async () => {
      if (subCategory.value === '') {
        isSubCategoryError.value = true
      }
      if (brand.value === '') {
        isBrandError.value = true
      }
      if (unitBig.value === '') {
        isUnitBigError.value = true
      }
      if (isAccessories.value === true && unitSmall.value === '') {
        isUnitSmallError.value = true
      }

      validate.value.$touch()

      if (
        subCategory.value === '' ||
        brand.value === '' ||
        unitBig.value === '' ||
        (isAccessories.value === true && unitSmall.value === '')
      ) {
        return
      }

      if (validate.value.$invalid == false) {
        isFormLoading.value = true

        const converters = []
        converters.push({
          unit_id: unitBig.value,
          barcode: formData.barcodeBig,
          converter: 1,
        })
        if (isAccessories.value === true) {
          converters.push({
            unit_id: unitSmall.value,
            barcode: formData.barcodeSmall,
            converter: formData.converter,
          })
        }

        let payload = {
          // image: imageFile.value,
          name: formData.name,
          description: formData.description,
          price: formData.price.toString(),
          sub_category_id: subCategory.value,
          brand_id: brand.value,
          converters: JSON.stringify(converters),
          // is_accessories: isAccessories.value,
        }

        let endpoint = 'product/create'

        if (activeDataId.value !== null && activeDataId.value !== '') {
          payload.id = activeDataId.value

          endpoint = 'product/update'
        }

        await store
          .dispatch(endpoint, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              const productId = response.data.id

              files.value.map(async (file) => {
                if (file.id === undefined) {
                  const payloadImage = {
                    id: productId,
                    file: file,
                  }
                  await store
                    .dispatch('product/uploadImage', payloadImage)
                    .then((response) => {})
                    .catch((error) => {
                      const responseData = error.response.data
                      isFormLoading.value = false
                      status.value = responseData.message.status
                      message.value = responseData.message.detail
                      // show toast
                      setTimeout(() => {
                        showToast(false)
                      }, 200)
                    })
                }
              })

              filesDeleted.value.map(async (fileId) => {
                const payloadImage = {
                  id: fileId,
                }
                await store
                  .dispatch('product/deleteImage', payloadImage)
                  .then((response) => {})
                  .catch((error) => {
                    const responseData = error.response.data
                    isFormLoading.value = false
                    status.value = responseData.message.status
                    message.value = responseData.message.detail
                    // show toast
                    setTimeout(() => {
                      showToast(false)
                    }, 200)
                  })
              })

              status.value = response.status
              message.value = response.message

              addEditModal.value = false
              validate.value.$reset()

              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              setTimeout(() => {
                getAllData()
              }, 500)
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isFormLoading.value = false
          })
          .catch((error) => {
            const responseData = error.response.data
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('product/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            validate.value.$reset()

            getAllData(true)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const resetForm = () => {
      isEditForm.value = false
      isAccessories.value = false

      // category.value = ''
      subCategory.value = ''
      brand.value = ''
      unitBig.value = ''
      unitSmall.value = ''

      // isCategoryError.value = false
      isSubCategoryError.value = false
      isBrandError.value = false
      isUnitBigError.value = false
      isUnitSmallError.value = false

      validate.value.$reset()

      productImagePreview.value = ''
      imageFile.value = null

      // formData.image = null
      files.value = []
      filesDeleted.value = []

      formData.price = ''
      formData.name = ''
      formData.description = ''
      formData.barcodeBig = ''
      formData.barcodeSmall = ''
      formData.converter = ''

      activeDataId.value = null
    }

    const addForm = async () => {
      isButtonLoading.value = true
      resetForm()

      await getSubCategoryData(true)
      await getBrandData(true)
      await getUnitData(true)

      titleModal.value = 'Tambah Produk'
      addEditModal.value = true

      isButtonLoading.value = false
    }

    const editForm = async (data) => {
      resetForm()

      // isAccessories.value = data.is_accessories === true ? true : false

      await getSubCategoryData(true)
      await getBrandData(true)
      await getUnitData(true)

      isEditForm.value = true

      activeDataId.value = data.id

      data.images.map((item) => {
        files.value.push({
          id: item.id,
          image: item.image,
          name: data.name,
        })
      })
      // formData.image = data.image
      // files.value = file.src

      formData.name = data.name
      formData.description = data.description ?? ''
      formData.price = data.price

      // category.value = data.category.id
      subCategory.value = data.sub_category.id
      brand.value = data.brand.id

      if (data.product_converters !== null) {
        data.product_converters.map((item, key) => {
          if (item.base === true) {
            unitBig.value = item.product_unit.id
            formData.barcodeBig = item.barcode
          } else {
            unitSmall.value = item.product_unit.id
            formData.barcodeSmall = item.barcode
            formData.converter = item.converter
          }
        })
      }

      if (data.product_converters.length > 1) {
        isAccessories.value = true
      }

      titleModal.value = 'Ubah Produk'
      addEditModal.value = true
    }

    const deleteForm = (data) => {
      activeData.name = data.name
      activeData.price = data.price

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.name = ''
      activeData.price = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        productImagePreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        productImagePreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const loadMores = async (type) => {
      if (type === 'sub-category') {
        subCategoryPage.value += 1
        getSubCategoryFilterData()
      }
    }

    const getSubCategoryFilterData = async () => {
      const queryParameters = {
        order: 'ASC',
        page: subCategoryPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('productSubCategory/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const subCategoryTemporary = []
            response.data.data.forEach((item) => {
              subCategoryTemporary.push({
                value: item.code,
                ...item,
              })
            })
            subCategoryFilterData.value = [
              ...subCategoryFilterData.value,
              ...subCategoryTemporary,
            ]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('sub-category')
            }
          }
        })
        .catch((error) => {})
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('product/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
          sub_category_ids: filterData.sub_category_ids,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    // const getCategoryData = async (success) => {
    //   isCategoryLoading.value = true
    //   await store
    //     .dispatch('productCategory/getAll', {
    //       order: 'ASC',
    //       page: pageCategory.value,
    //       take: 10,
    //     })
    //     .then((response) => {
    //       const responseData = response.data

    //       if (responseData.data) {
    //         Object.assign(categoryData.value, [
    //           ...categoryData.value,
    //           ...responseData.data,
    //         ])
    //       }

    //       if (responseData.data.length > 0) {
    //         pageCategory.value += 1
    //         getCategoryData()
    //       }
    //       isCategoryLoading.value = false
    //     })
    //     .catch((e) => {
    //       // if (filterData.page === 1) {
    //       //   isLoading.value = false
    //       // }
    //       console.log(e)
    //       isCategoryLoading.value = false
    //     })
    // }

    const changeAccessories = async () => {
      isAccessories.value = !isAccessories.value

      subCategory.value = ''
      brand.value = ''
      unitSmall.value = ''
      unitBig.value = ''

      getSubCategoryData(true)
      getUnitData(true)
    }

    const getSubCategoryData = async (reset) => {
      if (reset === true) {
        pageSubCategory.value = 1
        subCategoryData.value = []
      }

      isSubCategoryLoading.value = true
      await store
        .dispatch('productSubCategory/getAll', {
          order: 'ASC',
          page: pageSubCategory.value,
          take: 50,
          // is_accessories: isAccessories.value,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            Object.assign(subCategoryData.value, [
              ...subCategoryData.value,
              ...responseData.data,
            ])
          }

          if (responseData.data.length > 0) {
            pageSubCategory.value += 1
            getSubCategoryData()
          } else {
            isSubCategoryLoading.value = false
          }
        })
        .catch((e) => {
          // if (filterData.page === 1) {
          //   isLoading.value = false
          // }
          console.log(e)
          isSubCategoryLoading.value = false
        })
    }

    const getBrandData = async (reset) => {
      if (reset === true) {
        pageBrand.value = 1
        brandData.value = []
      }

      isBrandLoading.value = true
      await store
        .dispatch('productBrand/getAll', {
          order: 'ASC',
          page: pageBrand.value,
          take: 50,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            Object.assign(brandData.value, [
              ...brandData.value,
              ...responseData.data,
            ])
          }

          if (responseData.data.length > 0) {
            pageBrand.value += 1
            getBrandData()
          } else {
            isBrandLoading.value = false
          }
        })
        .catch((e) => {
          // if (filterData.page === 1) {
          //   isLoading.value = false
          // }
          console.log(e)
          isBrandLoading.value = false
        })
    }

    const getUnitData = async (reset) => {
      if (reset === true) {
        pageUnit.value = 1
        unitBigData.value = []
        unitSmallData.value = []
      }

      isUnitLoading.value = true
      await store
        .dispatch('productUnit/getAll', {
          order: 'ASC',
          page: pageUnit.value,
          take: 50,
          // is_accessories: isAccessories.value,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            Object.assign(unitBigData.value, [
              ...unitBigData.value,
              ...responseData.data,
            ])

            Object.assign(unitSmallData.value, [
              ...unitSmallData.value,
              ...responseData.data,
            ])
          }

          if (responseData.data.length > 0) {
            pageUnit.value += 1
            getUnitData()
          } else {
            isUnitLoading.value = false
          }
        })
        .catch((e) => {
          // if (filterData.page === 1) {
          //   isLoading.value = false
          // }
          console.log(e)
          isUnitLoading.value = false
        })
    }

    const changeFilter = (event) => {
      filterData = event.filterData
      getAllData(true)
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData(true)
      }, 500)
    }

    const productPreview = async (product) => {
      Object.assign(activeProduct, product)
      if (product.product_converters.length > 1) {
        isAccessories.value = true
      } else {
        isAccessories.value = false
      }
      productPreviewModal.value = true
    }

    const imagePreview = async (product) => {
      Object.assign(activeProduct, product)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeProduct,

      isAccessories,
      isEditForm,

      isDragging,
      files,
      filesDeleted,

      onChange,
      generateThumbnail,
      makeName,
      remove,
      dragover,
      dragleave,
      drop,

      roleAccess,
      rolesCanManage,

      productImagePreview,
      imageFile,
      selectFile,
      previewImage,

      addEditModal,
      deleteModal,
      productPreviewModal,
      imagePreviewModal,

      productPreview,
      imagePreview,

      // isCategoryLoading,
      isSubCategoryLoading,
      isBrandLoading,
      isUnitLoading,

      // isCategoryError,
      isSubCategoryError,
      isBrandError,
      isUnitBigError,
      isUnitSmallError,

      isFormLoading,
      isLoading,
      isButtonLoading,
      isSubCategoryFilterLoading,
      subCategoryFilterData,
      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,
      productTypeOptions,

      formData,
      validate,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      cancelDelete,
      activeData,
      deleteData,
      // editData,

      activeDataId,
      getAllData,

      showToast,
      changeFilter,
      searchFilter,

      // pageCategory,
      pageSubCategory,
      pageBrand,
      pageUnit,

      // category,
      subCategory,
      brand,
      unitBig,
      unitSmall,

      // categoryData,
      subCategoryData,
      brandData,
      unitBigData,
      unitSmallData,

      // getCategoryData,
      getSubCategoryData,
      getBrandData,
      getUnitData,
      changeAccessories,
    }
  },
  data: () => ({
    modulesImage: [Pagination],
  }),
}
</script>
