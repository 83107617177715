<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Grid Produk</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <button
        class="btn btn-primary mr-2 shadow-md"
        @click="
          $router.push({
            path: 'add-product',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
            },
          })
        "
      >
        Tambah Produk
      </button>
      <Dropdown>
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <PrinterIcon class="mr-2 h-4 w-4" /> Print
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to Excel
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <div class="mx-auto hidden text-slate-500 md:block">
        Showing 1 to 10 of 150 entries
      </div>
      <div class="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <input
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Search..."
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
      </div>
    </div>
    <!-- BEGIN: Users Layout -->
    <div
      v-for="(faker, fakerKey) in $_.take($f(), 12)"
      :key="fakerKey"
      class="intro-y col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
    >
      <div class="box">
        <div class="p-5">
          <div
            class="image-fit h-40 overflow-hidden rounded-md before:absolute before:top-0 before:left-0 before:z-10 before:block before:h-full before:w-full before:bg-gradient-to-t before:from-black before:to-black/10 2xl:h-56"
          >
            <img
              alt="Gromura - SCM"
              class="rounded-md"
              :src="faker.products[0].images[0]"
            />
            <span
              v-if="faker.trueFalse[0]"
              class="absolute top-0 z-10 m-5 rounded bg-pending/80 px-2 py-1 text-xs text-white"
              >Featured</span
            >
            <div class="absolute bottom-0 z-10 px-5 pb-6 text-white">
              <a href="" class="block text-base font-medium">{{
                faker.products[0].name
              }}</a>
              <span class="mt-3 text-xs text-white/90">{{
                faker.products[0].category
              }}</span>
            </div>
          </div>
          <div class="mt-5 text-slate-600 dark:text-slate-500">
            <div class="flex items-center">
              <LinkIcon class="mr-2 h-4 w-4" /> Harga: Rp {{ faker.totals[0] }}
            </div>
            <div class="mt-2 flex items-center">
              <LayersIcon class="mr-2 h-4 w-4" /> Stok Tersedia:
              {{ faker.stocks[0] }}
            </div>
            <div class="mt-2 flex items-center">
              <CheckSquareIcon class="mr-2 h-4 w-4" /> Status:
              {{ faker.trueFalse[0] ? 'Aktif' : 'Tidak Aktif' }}
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-center border-t border-slate-200/60 p-5 dark:border-darkmode-400 lg:justify-end"
        >
          <a class="mr-auto flex items-center text-primary" href="javascript:;">
            <EyeIcon class="mr-1 h-4 w-4" /> Preview
          </a>
          <a class="mr-3 flex items-center" href="javascript:;">
            <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
          </a>
          <a
            class="flex items-center text-danger"
            href="javascript:;"
            @click="deleteConfirmationModal = true"
          >
            <Trash2Icon class="mr-1 h-4 w-4" /> Delete
          </a>
        </div>
      </div>
    </div>
    <!-- END: Users Layout -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div>
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref } from 'vue'

const deleteConfirmationModal = ref(false)
</script>
