import Toastify from 'toastify-js'
import { colors } from '@/utils/colors'

export default {
  inject: ['dayjs'],
  data: () => ({
    appName: 'Kiraket',
    // appName: 'PT. Dwimitra Pasifik Internasional',

    userAgent: '',
    activeUser: {
      id: null,
      created: null,
      modified: null,
      full_name: null,
      email: null,
      phone_number: null,
      avatar: null,
      is_verified: true,
      institute: null,
      institute_slug: null,
      role: null,
      role_position: null,
    },

    userMenus: {},
    userFeatures: {},

    maintenanceToPage: false,
    isAuthenticated: false,

    showModalMaintenance: false,
    showModalComingSoon: false,
    showModalLogout: false,

    multiSelectClasses: {
      container:
        'relative mx-auto w-full h-11 flex items-center justify-end box-border cursor-pointer border border-gray-200 rounded-md bg-white text-sm leading-snug outline-none',
      containerDisabled: 'cursor-default bg-gray-100',
      containerOpen: 'rounded-b-none',
      containerOpenTop: 'rounded-t-none',
      containerActive: 'ring ring-primary ring-opacity-30',
      search:
        'w-full absolute rounded-md inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-sm bg-white',
      placeholder:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
      clearIcon:
        'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
      option:
        'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
      optionPointed: 'text-gray-800 bg-gray-100',
      optionSelected: 'text-gray-800 bg-white',
      optionDisabled: 'text-gray-300 cursor-not-allowed',
      noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
      noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
    },
    multiSelectFilterClasses: {
      container:
        'relative w-full h-10 flex items-center justify-end box-border cursor-pointer border border-gray-200 rounded-md bg-white text-sm leading-snug outline-none',
      containerDisabled: 'cursor-default bg-gray-100',
      containerOpen: 'rounded-b-none',
      containerOpenTop: 'rounded-t-none',
      containerActive: 'ring ring-primary ring-opacity-30',
      search:
        'w-full absolute rounded-md inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-sm bg-white',
      placeholder:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
      clearIcon:
        'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
      option:
        'flex z-50 items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
      optionPointed: 'text-gray-800 bg-gray-100',
      optionSelected: 'text-gray-800 bg-white',
      optionDisabled: 'text-gray-300 cursor-not-allowed',
      noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
      noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
    },
    // multiSelectError: {
    //   container:
    //     'relative mx-auto w-full h-11 flex items-center justify-end box-border cursor-pointer border border-danger rounded-md bg-white text-sm leading-snug outline-none',
    //   containerActive: 'ring ring-danger ring-opacity-30',
    // },

    showOption: [10, 25, 35, 50],

    masterRoles: [
      'SUPERADMIN',
      'CEO',
      'HEAD_FINANCE_ACCOUNTING',
      'HEAD_SALES_MARKETING',
      'HEAD_OPERATION',
    ],
    storeRoles: [
      'HEAD_STORE',
      'HEAD_SALES',
      'SALES',
      'CASHIER',
      'CHECKER_PACKER',
      'DRIVER',
    ],
  }),
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters['auth/isUserLoggedIn']
      },
      set(userLogggedIn) {
        return this.$store.dispatch('auth/updateLoginState', userLogggedIn)
      },
    },
    isUnderMaintenance: {
      get() {
        // return this.underMaintenance
        return this.$store.getters['home/underMaintenanceGetter']
      },
      set(maintenance) {
        return this.$store.dispatch('home/setUnderMaintenance', maintenance)
      },
    },
    storeId: {
      get() {
        return this.$store.getters['store/storeId']
      },
      set(storeId) {
        return this.$store.dispatch('store/updateStoreIdState', storeId)
      },
    },
    storeName: {
      get() {
        return this.$store.getters['store/storeName']
      },
      set(storeName) {
        return this.$store.dispatch('store/updateStoreNameState', storeName)
      },
    },
  },
  mounted() {
    this.userAgent = navigator.userAgent
  },
  methods: {
    checkIsAuthenticated() {
      const accessToken = localStorage.getItem('accessToken') || null

      if (accessToken !== null) {
        this.$router.push({
          name: 'home',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
    checkMaintenance() {
      if (this.isUnderMaintenance) {
        if (this.maintenanceToPage) this.$router.push({ name: 'maintenance' })
        else this.$emit('showModalMaintenance', this.isUnderMaintenance)
      }
    },
    isPhoneNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    mergeText(text1, text2) {
      return text1 + ' ' + text2
    },
    persentage(price, discount) {
      return Math.floor(((price - discount) / price) * 100)
    },
    imageUrlAlternate(event) {
      crossOriginIsolated.log('called imageUrlAlternate')
      event.target.src = '@/assets/image/icon.png'
    },
    imageUrlAlternateBox(event) {
      event.target.src = '@/assets/image/icon.png'
    },
    formatDateDay(
      date,
      options = {
        month: 'long',
        day: 'numeric',
      }
    ) {
      return new Date(date).toLocaleDateString('id-ID', options)
    },
    formattedDate(value) {
      if (!value) return ''
      const formattedDate = this.dayjs(value).format('DD MMMM YYYY')
      return formattedDate
    },
    hoursMinutesFromTwoDates(date1, date2) {
      const localStartTime = this.dayjs(date1)
      const localEndTime = this.dayjs(date2)

      const hoursDiff = localEndTime.diff(localStartTime, 'h')
      const minutesDiff = localEndTime.diff(localStartTime, 'm')

      let hoursMinutes = ''
      if (hoursDiff > 0) {
        hoursMinutes =
          hoursDiff +
          ' ' +
          this.$t('time_hour') +
          ' ' +
          minutesDiff +
          ' ' +
          this.$t('time_minute')
      } else {
        hoursMinutes = minutesDiff + ' ' + this.$t('time_minute')
      }
      return hoursMinutes
    },
    dateStillActive(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      return endDate > startDate
    },
    dateDiffDays(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      const diffTime = endDate - startDate
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
    diffPrice(price, discountedPrice) {
      return discountedPrice - price
    },
    hoursMinutesFromDate(date) {
      const localDateTime = this.dayjs(date).format('HH:mm')
      return localDateTime
    },
    getDateRange(totalDay = 30) {
      const curr = new Date()
      const dateList = []
      dateList.push(
        new Date(curr.setDate(curr.getDate())).toISOString().slice(0, 10)
      )

      for (let i = 1; i <= totalDay; i++) {
        const first = curr.getDate() + 1
        const day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        dateList.push(day)
      }
      return dateList
    },
    getRangeInteger(start, end, length = end - start) {
      return Array.from({ length }, (_, i) => start + i)
    },
    timeEstimatedFromDate(date, addMinutes = 5) {
      const startTime = this.dayjs(date).format('HH:mm')

      const endTime = this.dayjs(date).add(addMinutes, 'm').format('HH:mm')
      return `${startTime} - ${endTime}`
    },
    mergeTwoArrayByKey(arr1, arr2, key = 'id') {
      return arr1
        .filter((elem) => !arr2.find((subElem) => subElem[key] === elem[key]))
        .concat(arr2)
    },
    toDatetime(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)

      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      )
    },
    arrayToString(array, key) {
      return array.map((item) => item[key]).join(', ')
    },
    onCopy(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_success'),
        type: 'default',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    onError(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_failed'),
        type: 'error',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    showToast(id) {
      Toastify({
        node: dom('#' + id)
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    },
    sumObjectValue(obj) {
      var sum = 0
      for (var el in obj) {
        if (obj.hasOwnProperty(el)) {
          sum += parseFloat(obj[el])
        }
      }
      return sum
    },
    extractFilename(dispotition, defaultName) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(dispotition)
      let filename = defaultName
      if (matches && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
      return filename
    },
    getColor(index) {
      switch (index) {
        case 0:
          return colors.primary()
        case 1:
          return colors.secondary()
        case 2:
          return colors.success()
        case 3:
          return colors.info()
        case 4:
          return colors.warning()
        case 5:
          return colors.pending()
        case 6:
          return colors.danger()
        case 7:
          return colors.light()
        case 8:
          return colors.dark()
        default:
          return colors.primary()
      }
    },
    checkArray(arr, comparison) {
      return arr.every((v) => v === comparison)
    },
    splitWords(text, numWords) {
      const words = text.split(' ')
      let part1 = '',
        part2 = ''
      words.forEach((word, idx) => {
        if (idx < numWords) {
          part1 += ' ' + word
        } else {
          part2 += ' ' + word
        }
      })
      return [part1.trim(), part2.trim()]
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
