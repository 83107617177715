<template>
  <ButtonComponent :title="$t('action.exportCSV')">
    <template #icon>
      <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
    </template>
  </ButtonComponent>
</template>
<script>
import ButtonComponent from './ButtonComponent.vue'
export default {
  name: 'ExportCSVComponent',
  components: {
    ButtonComponent,
  },
}
</script>
