<template>
  <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <h2 class="mr-auto text-lg font-medium">Buat Stock Opname</h2>
    <!-- <div class="mt-4 flex w-full sm:mt-0 sm:w-auto">
        <a
          href="javascript:;"
          @click="newOrderModal = true"
          class="btn btn-primary mr-2 shadow-md"
          >New Order</a
        >
        <Dropdown class="pos-dropdown ml-auto sm:ml-0">
          <DropdownToggle class="btn box px-2">
            <span class="flex h-5 w-5 items-center justify-center">
              <ChevronDownIcon class="h-4 w-4" />
            </span>
          </DropdownToggle>
          <DropdownMenu class="pos-dropdown__dropdown-menu">
            <DropdownContent>
              <DropdownItem>
                <ActivityIcon class="mr-2 h-4 w-4" />
                <span class="truncate"
                  >INV-0206020 - {{ $f()[3].users[0].name }}</span
                >
              </DropdownItem>
              <DropdownItem>
                <ActivityIcon class="mr-2 h-4 w-4" />
                <span class="truncate"
                  >INV-0206022 - {{ $f()[4].users[0].name }}</span
                >
              </DropdownItem>
              <DropdownItem>
                <ActivityIcon class="mr-2 h-4 w-4" />
                <span class="truncate"
                  >INV-0206021 - {{ $f()[5].users[0].name }}</span
                >
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div> -->
  </div>
  <div class="intro-y mt-5 grid grid-cols-12 gap-5">
    <!-- BEGIN: Item List -->
    <div class="intro-y col-span-12">
      <div class="intro-y lg:flex">
        <div class="relative">
          <input
            type="text"
            class="form-control box w-full py-3 px-4 pr-10 lg:w-80"
            placeholder="Search item..."
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4 text-slate-500"
          />
        </div>
        <!-- <select
          class="box form-select mt-3 ml-auto w-full py-3 px-4 lg:mt-0 lg:w-auto"
        >
          <option>Sort By</option>
          <option>A to Z</option>
          <option>Z to A</option>
          <option>Harga Terendah</option>
          <option>Harga Tertinggi</option>
          <option>Stok Terendah</option>
          <option>Stok Tertinggi</option>
          <option>Minimum Stok</option>
          <option>Maksimum Stok</option>
        </select> -->
      </div>
      <!-- <div class="mt-5 grid grid-cols-12 gap-5 border-t pt-5">
        <a
          v-for="(faker, fakerKey) in $_.take($f(), 8)"
          :key="fakerKey"
          href="javascript:;"
          @click="addItemModal = true"
          class="intro-y col-span-12 block sm:col-span-4 2xl:col-span-3"
        >
          <div class="box zoom-in relative rounded-md p-3">
            <div
              class="relative block flex-none before:block before:w-full before:pt-[100%]"
            >
              <div class="image-fit absolute top-0 left-0 h-full w-full">
                <img
                  alt="Gromura - SCM"
                  class="rounded-md"
                  :src="faker.products[0].images[0]"
                />
              </div>
            </div>
            <div class="mt-3 block truncate text-center font-medium">
              {{ faker.products[0].name }}
            </div>
            <span class="mt-3 text-xs text-green-600">{{
              faker.products[0].category
            }}</span>
            <div class="mt-2 text-slate-600 dark:text-slate-500">
              <div class="flex items-center font-bold">
                Rp.
                {{ faker.totals[0] }}
              </div>
              <div class="mt-2 flex items-center">
                <LayersIcon class="mr-2 h-4 w-4" /> Stok:
                {{ faker.stocks[0] }}
              </div>
            </div>
          </div>
        </a>
      </div> -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table-report mt-2 table">
          <thead>
            <tr>
              <th class="whitespace-nowrap">IMAGES</th>
              <th class="whitespace-nowrap">NAMA PRODUK</th>
              <th class="whitespace-nowrap text-center">STOCK</th>
              <th class="whitespace-nowrap text-center">FISIK</th>
              <th class="whitespace-nowrap text-center">SELISIH</th>
              <th class="whitespace-nowrap text-center">PRICE</th>
              <th class="whitespace-nowrap text-center">NILAI</th>
              <th class="whitespace-nowrap text-center">AKSI</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(faker, fakerKey) in $_.take($f(), 6)"
              :key="fakerKey"
              class="intro-x"
            >
              <td class="w-40">
                <div class="flex">
                  <div class="image-fit zoom-in h-10 w-10">
                    <Tippy
                      tag="img"
                      alt="Gromura - SCM"
                      class="rounded-full"
                      :src="faker.products[0].images[0]"
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                  <div class="image-fit zoom-in -ml-5 h-10 w-10">
                    <Tippy
                      tag="img"
                      alt="Gromura - SCM"
                      class="rounded-full"
                      :src="faker.products[0].images[1]"
                      :content="`Uploaded at ${faker.dates[1]}`"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="whitespace-nowrap font-medium">{{
                  faker.products[0].name
                }}</a>
                <div class="mt-0.5 whitespace-nowrap text-xs text-green-600">
                  {{ faker.products[0].category }}
                </div>
              </td>
              <td class="text-center">{{ faker.stocks[0] + 100 }}</td>
              <td class="text-center">
                <input
                  id="pos-form-4"
                  type="text"
                  class="form-control w-18 text-center"
                  placeholder="Item quantity"
                  value="2"
                />
              </td>
              <td class="text-center">{{ faker.stocks[0] - 10 }}</td>
              <td class="text-center">Rp{{ faker.totals[0] }}</td>
              <td class="text-center">Rp{{ 10 * faker.totals[0] }}</td>
              <td class="text-center">
                <TrashIcon class="h-5 w-5 cursor-pointer text-danger" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div class="col-span-12 flex flex-col">
      <div class="mb-2"><p>Kategori</p></div>
      <select class="box form-select mb-5 h-12 w-full" v-model="category">
        <option value="1">Stock Count</option>
        <option value="2">Expired</option>
      </select>
    </div> -->
  </div>
  <div class="mt-5 flex w-full">
    <button
      class="btn w-32 border-slate-300 text-slate-500 dark:border-darkmode-400"
    >
      Clear Items
    </button>
    <button class="btn btn-primary ml-auto w-32 shadow-md">SIMPAN</button>
  </div>
  <!-- END: Item List -->
  <!-- BEGIN: Ticket -->

  <!-- END: Ticket -->
  <!-- BEGIN: Add Item Modal -->
  <Modal :show="addItemModal" @hidden="addItemModal = false">
    <ModalHeader>
      <h2 class="mr-auto text-base font-medium">
        {{ $f()[0].foods[0].name }}
      </h2>
    </ModalHeader>
    <ModalBody class="grid grid-cols-12 gap-4 gap-y-3">
      <div class="col-span-12">
        <label for="pos-form-4" class="form-label">Quantity</label>
        <div class="flex flex-1">
          <button
            type="button"
            class="btn mr-1 w-12 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
          >
            -
          </button>
          <input
            id="pos-form-4"
            type="text"
            class="form-control w-24 text-center"
            placeholder="Item quantity"
            value="2"
          />
          <button
            type="button"
            class="btn ml-1 w-12 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
          >
            +
          </button>
        </div>
      </div>
      <div class="col-span-12">
        <label for="pos-form-5" class="form-label">Notes</label>
        <textarea
          id="pos-form-5"
          class="form-control"
          placeholder="Item notes"
        ></textarea>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="addItemModal = false"
        class="btn btn-outline-secondary mr-1 w-24"
      >
        Cancel
      </button>
      <button type="button" class="btn btn-primary w-24">Add Item</button>
    </ModalFooter>
  </Modal>
  <!-- END: Add Item Modal -->
</template>

<script setup>
import { ref, provide, onMounted } from 'vue'
import Multiselect from '@vueform/multiselect'

const category = ref('1')
</script>
