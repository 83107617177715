<template>
  <div class="flex w-auto">
    <div class="relative w-48 text-slate-500">
      <input
        :value="search"
        type="text"
        class="form-control box w-56 pr-8"
        placeholder="Cari produk..."
        @input="searchFilter()"
      />
      <SearchIcon class="absolute inset-y-0 right-0 my-auto -mr-4 h-4 w-4" />
    </div>
    <select
      v-if="showOption"
      :value="option"
      class="box form-select ml-10"
      @change="onChange()"
    >
      <option :value="null">
        {{ isLoading ? 'Memuat data....' : 'Semua' }}
      </option>
      <option v-for="item in searchOptions" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'FilterSearch',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: String,
      required: false,
      default: '',
    },
    option: {
      type: String,
      required: false,
      default: null,
    },
    searchOptions: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['search-filter', 'change'],
  methods: {
    searchFilter() {
      this.$emit('search-filter')
    },
    onChange() {
      this.$emit('change')
    },
  },
}
</script>
