<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Data Staff</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center xl:flex-nowrap"
    >
      <!-- <button class="btn btn-primary mr-2 shadow-md">Tambah Staff</button> -->
      <div class="flex">
        <button class="btn btn-primary mr-2 shadow-md">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
      <!-- <Dropdown>
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <PrinterIcon class="mr-2 h-4 w-4" /> Print
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to Excel
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown> -->
      <div class="mx-auto hidden text-slate-500 xl:block">
        <!-- Showing 1 to 10 of 150 entries -->
      </div>
      <div class="mt-3 flex w-full items-center xl:mt-0 xl:w-auto">
        <div class="relative w-56 text-slate-500">
          <input
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Search..."
            v-model="filterData.search"
            @input="searchFilter()"
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
        <!-- <select class="box form-select ml-2 w-56 xl:w-auto">
          <option>Status</option>
          <option>Active</option>
          <option>Inactive</option>
        </select> -->
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="staffHeader"
      :meta-data="metaData"
      :is-not-empty="staffData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="staff in staffData" :key="staff.user.id" class="intro-x">
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <!-- <Tippy
                  tag="img"
                  alt="Gromura - SCM"
                  class="rounded-lg border-white shadow-md"
                  :src="faker.users[0].image"
                  :content="`Uploaded at ${faker.dates[0]}`"
                /> -->
              <ImageLoader
                :image="staff.user.avatar"
                :alt="`${staff.user.first_name} ${staff.user.last_name}`"
                rounded-class="rounded-full"
              />
            </div>
            <div class="ml-4">
              <a href="#" class="whitespace-nowrap font-medium"
                >{{ staff.user.first_name }} {{ staff.user.last_name }}</a
              >
              <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                {{ staff.user.username }}
              </div>
            </div>
          </div>
        </td>
        <td class="text-center text-xs text-slate-600 dark:text-slate-500">
          {{ staff.user.email }}
        </td>
        <td class="text-center">
          {{ staff.user.phone ?? '-' }}
        </td>
        <td class="text-center capitalize">
          <Alert
            v-if="staff.user.gender === 'Male'"
            className="alert-secondary text-white rounded-xl text-xs py-1 px-2"
            >{{ staff.user.gender }}</Alert
          >
          <Alert
            v-else
            className="alert-danger text-white rounded-xl text-xs py-1 px-2"
            >{{ staff.user.gender }}</Alert
          >
        </td>
        <td class="text-center capitalize">
          <template v-if="storeRoles.includes(staff.user.role.code)">
            <div
              v-if="staff.user.role.code === 'HEAD_STORE'"
              className="text-secondary"
            >
              {{ staff.user.role.name }}
            </div>
            <div
              v-else-if="staff.user.role.code === 'HEAD_SALES'"
              className="text-warning "
            >
              {{ staff.user.role.name }}
            </div>
            <div
              v-else-if="staff.user.role.code === 'SALES'"
              className="text-pending "
            >
              {{ staff.user.role.name }}
            </div>
            <div
              v-else-if="staff.user.role.code === 'CASHIER'"
              className="text-success "
            >
              {{ staff.user.role.name }}
            </div>
            <div
              v-else-if="staff.user.role.code === 'DRIVER'"
              className="text-danger "
            >
              {{ staff.user.role.name }}
            </div>
            <div
              v-else-if="staff.user.role.code === 'CHECKER_PACKER'"
              className="text-danger "
            >
              {{ staff.user.role.name }}
            </div>
          </template>
          <template v-else>
            <!-- <Alert
              v-else
              className="alert-danger text-white rounded-xl text-xs py-1 px-2"
              >{{ staff.user.role.name }}</Alert
            > -->
            {{ staff.user.role.name }}
          </template>
        </td>
        <!-- <td class="w-40">
            <div
              class="flex items-center justify-center"
              :class="{
                'text-success': faker.trueFalse[0],
                'text-danger': !faker.trueFalse[0],
              }"
            >
              <CheckSquareIcon class="mr-2 h-4 w-4" />
              {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
            </div>
          </td> -->
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center text-success">
            <a class="mr-3 flex items-center" href="javascript:;">
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <!-- <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal> -->
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    staffHeader: [
      {
        item: 'STAFF',
        customClass: 'text-center',
      },
      {
        item: 'EMAIL',
        customClass: 'text-center',
      },
      {
        item: 'NO. TELEPON',
        customClass: 'text-center',
      },
      {
        item: 'GENDER',
        customClass: 'text-center',
      },
      {
        item: 'ROLE',
        customClass: 'text-center',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    },
    staffData: [],
    staffDataMapped: [],
    timer: undefined,
    storeId: null,
    isLoading: false,
  }),
  mounted() {
    this.activeUser = this.$store.getters['auth/activeUser']
    if (this.activeUser.store !== undefined) {
      this.storeId = this.activeUser.store.id
    }
    this.init()
  },
  methods: {
    init() {
      this.getAllUser()
    },
    async getAllUser() {
      if (this.filterData.page === 1) {
        this.isLoading = true
      }
      await this.store
        .dispatch('staff/all', {
          store_id: this.storeId,
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            this.staffData = responseData.data

            this.staffDataMapped = this.staffData.reduce((filtered, item) => {
              let key = item.id
              if (!filtered[key]) {
                filtered[key] = null
              }
              filtered[key] = item
              return filtered
            }, {})
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          if (this.filterData.page === 1) {
            this.isLoading = false
          }
        })
        .catch((e) => {
          this.message = e.message
          if (this.filterData.page === 1) {
            this.isLoading = false
          }
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async downloadUserData() {
      await this.store
        .dispatch('staff/download', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'staff-store-list.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllUser(false)
    },
    searchFilter() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getAllUser()
      }, 500)
    },
  },
}
</script>
