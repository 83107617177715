<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Metode Pembayaran</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <div class="flex w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-56 pr-8"
            placeholder="Cari metode pembayaran..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto -mr-4 h-4 w-4"
          />
        </div>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <button class="btn btn-primary mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary text-white">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="paymentMethodHeader"
      :meta-data="metaData"
      :is-not-empty="paymentMethodData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="item in paymentMethodData" :key="item.id" class="intro-x">
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="item.image"
                :alt="item.name"
                rounded-class="rounded-md"
                @click="imagePreview(item)"
              />
            </div>
          </div>
        </td>
        <td class="capitalize">
          <!-- <div v-if="item.code === 'CASH'" class="text-secondary">
            {{ item.code }}
          </div>
          <div v-else-if="item.code === 'DUE_DATE'" class="text-warning">
            {{ item.code }}
          </div>

          <div v-else>
            {{ item.code }}
          </div> -->
          {{ item.code }}
        </td>
        <td>
          {{ item.name }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.description }}
        </td>
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center text-success">
            <a class="mr-3 flex items-center" href="javascript:;">
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <div
        class="relative block flex-none before:block before:w-full before:pt-[100%]"
      >
        <div class="image-fit absolute top-0 left-0 h-full w-full">
          <ImageLoader
            :image="activePaymentMethod.image"
            :alt="activePaymentMethod.name"
            rounded-class="rounded-md"
          />
        </div>
      </div>
    </ModalBody>
  </Modal>

  <!-- BEGIN: Delete Confirmation Modal -->
  <!-- <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal> -->
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const imagePreviewModal = ref(false)
    const activePaymentMethod = reactive({})

    const imagePreview = async (paymentMethod) => {
      Object.assign(activePaymentMethod, paymentMethod)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      imagePreviewModal,
      activePaymentMethod,
      imagePreview,
    }
  },
  data: () => ({
    paymentMethodHeader: [
      {
        item: 'IMAGE',
        customClass: '',
      },
      {
        item: 'KODE',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      {
        item: 'DESKRIPSI',
        customClass: '',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    },
    paymentMethodData: [],
    timer: undefined,
    isLoading: false,
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getAllPaymentMethod()
    },
    async getAllPaymentMethod() {
      if (this.filterData.page === 1) {
        this.isLoading = true
      }
      await this.store
        .dispatch('master/paymentMethod', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            this.paymentMethodData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          if (this.filterData.page === 1) {
            this.isLoading = false
          }
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          if (this.filterData.page === 1) {
            this.isLoading = false
          }
          console.log(this.message)
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllPaymentMethod(false)
    },
    searchFilter() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getAllPaymentMethod()
      }, 500)
    },
  },
}
</script>
