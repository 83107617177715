<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Data Agen Sales</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center xl:flex-nowrap"
    >
      <button class="btn btn-primary mr-2 shadow-md" @click="addForm()">
        Tambah Agen Sales
      </button>
      <Dropdown>
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <PrinterIcon class="mr-2 h-4 w-4" /> Print
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to Excel
            </DropdownItem>
            <!-- <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem> -->
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <div class="mx-auto hidden text-slate-500 xl:block">
        <!-- Showing 1 to 10 of 150 entries -->
      </div>
      <div class="mt-3 flex w-full items-center xl:mt-0 xl:w-auto">
        <div class="relative w-56 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Search..."
            @input="searchFilter()"
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
        <!-- <select class="box form-select ml-2 w-56 xl:w-auto">
          <option>Status</option>
          <option>Active</option>
          <option>Inactive</option>
        </select> -->
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="user in tableData" :key="user.id" class="intro-x">
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="user.avatar"
                :alt="`${user.first_name} ${user.last_name}`"
                rounded-class="rounded-full"
              />
            </div>
          </div>
        </td>
        <td>
          <div class="">
            <a href="#" class="whitespace-nowrap font-medium"
              >{{ user.first_name }} {{ user.last_name }}</a
            >
            <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
              {{ user.username }}
            </div>
          </div>
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ user.email }}
        </td>
        <td class="">
          {{ user.phone ?? '-' }}
        </td>
        <td class="text-center capitalize">
          <span
            v-if="user.gender === 'Male'"
            class="mr-1 rounded-full bg-secondary px-3 py-1 text-white"
          >
            Laki - Laki
          </span>
          <span
            v-if="user.gender === 'Female'"
            class="mr-1 rounded-full bg-danger px-3 py-1 text-white"
          >
            Perempuan
          </span>
          <span> - </span>
        </td>
        <!-- <td class="w-40">
            <div
              class="flex items-center justify-center"
              :class="{
                'text-success': faker.trueFalse[0],
                'text-danger': !faker.trueFalse[0],
              }"
            >
              <CheckSquareIcon class="mr-2 h-4 w-4" />
              {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
            </div>
          </td> -->
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center text-success">
            <a class="mr-3 flex items-center" href="javascript:;">
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->
  </div>
  <!-- ADD & EDIT MODAL -->
  <Modal size="modal-lg" :show="addEditModal" @hidden="addEditModal = false">
    <ModalHeader>
      <h2 class="mr-auto text-base font-medium">{{ titleModal }}</h2>
    </ModalHeader>
    <ModalBody>
      <div class="w-full">
        <div class="image-fit relative h-20 w-20 flex-none lg:h-24 lg:w-24">
          <template v-if="customerImagePreview != ''">
            <ImageLoader
              :image="customerImagePreview"
              :alt="formData.name"
              rounded-class="rounded-full"
            />
          </template>
          <template v-else>
            <ImageLoader
              :image="formData.image"
              :alt="formData.name"
              rounded-class="rounded-full"
            />
          </template>

          <div
            class="absolute bottom-0 right-0 mb-1 flex cursor-pointer items-center justify-center rounded-full bg-primary p-1 lg:p-2"
            @click="selectFile()"
          >
            <input
              id="hidden-input"
              ref="imageUploader"
              type="file"
              accept="image/*"
              class="hidden"
            />
            <CameraIcon class="h-3 w-3 text-white lg:h-4 lg:w-4" />
          </div>
        </div>
        <div class="mt-8 grid grid-cols-12 gap-4">
          <div class="input-form col-span-6">
            <label
              for="form-firstName"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.firstName') }}
            </label>
            <input
              id="form-firstName"
              v-model.trim="validate.first_name.$model"
              type="text"
              name="firstName"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.first_name.$error }"
              :placeholder="$t('formInput.enterName')"
            />
            <template v-if="validate.first_name.$error">
              <div
                v-for="(error, index) in validate.first_name.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form col-span-6">
            <label
              for="form-lastName"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.lastName') }}
            </label>
            <input
              id="form-lastName"
              v-model.trim="validate.last_name.$model"
              type="text"
              name="lastName"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.last_name.$error }"
              :placeholder="$t('formInput.enterName')"
            />
            <template v-if="validate.last_name.$error">
              <div
                v-for="(error, index) in validate.last_name.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>

        <div class="input-form mt-3 w-full">
          <label
            for="form-email"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.email') }}
          </label>
          <input
            id="form-email"
            v-model.trim="validate.email.$model"
            type="email"
            name="email"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.email.$error }"
            :placeholder="$t('formLabel.email')"
          />
          <template v-if="validate.email.$error">
            <div
              v-for="(error, index) in validate.email.$errors"
              :key="index"
              class="mt-2 text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>

        <div class="input-form mt-3 w-full">
          <label
            for="form-phone"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.phone') }}
          </label>
          <input
            id="form-phone"
            v-model="validate.phone.$model"
            type="text"
            name="phone"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.phone.$error }"
            :placeholder="$t('formLabel.phone')"
          />
          <template v-if="validate.phone.$error">
            <div
              v-for="(error, index) in validate.phone.$errors"
              :key="index"
              class="mt-2 text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>

        <!-- <div class="input-form mt-5 w-full">
            <label
              for="form-code"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.code') }}
            </label>
            <input
              id="form-code"
              v-model.trim="validate.code.$model"
              type="text"
              name="code"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.code.$error }"
              :placeholder="$t('formInput.enterCode')"
            />
            <template v-if="validate.code.$error">
              <div
                v-for="(error, index) in validate.code.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div> -->
        <!-- <div class="input-form mt-3 w-full">
            <label
              for="form-npwp"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.npwp') }}
            </label>
            <input
              id="form-npwp"
              v-model.trim="validate.npwp.$model"
              type="text"
              name="npwp"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.npwp.$error }"
              :placeholder="$t('formInput.enterNpwp')"
            />
            <template v-if="validate.npwp.$error">
              <div
                v-for="(error, index) in validate.npwp.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-pic"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.pic') }}
            </label>
            <input
              id="form-pic"
              v-model.trim="validate.pic.$model"
              type="text"
              name="pic"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.pic.$error }"
              :placeholder="$t('formInput.enterPic')"
            />
            <template v-if="validate.pic.$error">
              <div
                v-for="(error, index) in validate.pic.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-phone"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.phone') }}
            </label>
            <input
              id="form-phone"
              v-model="validate.phone.$model"
              type="text"
              name="phone"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.phone.$error }"
              :placeholder="$t('formLabel.phone')"
            />
            <template v-if="validate.phone.$error">
              <div
                v-for="(error, index) in validate.phone.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-email"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.email') }}
            </label>
            <input
              id="form-email"
              v-model.trim="validate.email.$model"
              type="email"
              name="email"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.email.$error }"
              :placeholder="$t('formLabel.email')"
            />
            <template v-if="validate.email.$error">
              <div
                v-for="(error, index) in validate.email.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div> -->
      </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" class="btn btn-primary px-8" @click="addData">
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button>
    </ModalFooter>
  </Modal>

  <!-- DELETE MODAL -->
  <Modal :show="deleteModal" @hidden="cancelDelete">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5">Apakah anda yakin ingin menghapus data ini ?</div>
        <div class="mt-2 text-lg text-slate-600">
          {{ activeData.first_name }} {{ activeData.last_name }}
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary mr-4 w-24"
          @click="cancelDelete"
        >
          Batal
        </button>
        <button type="button" class="btn btn-danger w-32" @click="deleteData">
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Hapus
        </button>
      </div>
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const rolesCanManage = ref(['SUPERADMIN', 'HEAD_OPERATION'])

    const activeCustomer = reactive({})

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const timer = ref(undefined)

    const customerImagePreview = ref('')
    const imageFile = ref(null)

    const customerPreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)
    const deleteModal = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'AVATAR',
        customClass: 'text-center',
      },
      {
        item: 'NAMA LENGKAP',
        customClass: '',
      },
      {
        item: 'EMAIL',
        customClass: '',
      },
      {
        item: 'NO. TELEPON',
        customClass: '',
      },
      {
        item: 'GENDER',
        customClass: 'text-center',
      },
      // {
      //   item: 'USERNAME',
      //   customClass: '',
      // },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
      type: null,
    })

    const activeData = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      username: '',
      gender: '',
    })

    const formData = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      username: '',
      gender: '',
    })

    const firstNameRequired = helpers.withMessage(
      'formError.firstName.required',
      required
    )
    const emailRequired = helpers.withMessage(
      'formError.email.required',
      required
    )
    const emailValid = helpers.withMessage('formError.email.validEmail', email)
    const phoneRequired = helpers.withMessage(
      'formError.phone.required',
      required
    )
    const phoneValid = helpers.withMessage(
      'formError.phone.validPhone',
      integer
    )
    const phoneMinLength = helpers.withMessage(
      'formError.phone.minLength',
      minLength(9)
    )
    const phoneMaxLength = helpers.withMessage(
      'formError.phone.maxLength',
      maxLength(13)
    )

    let rules = reactive({
      first_name: {
        firstNameRequired,
      },
      last_name: {},
      email: {
        emailRequired,
        emailValid,
      },
      phone: {
        phoneRequired,
        phoneMinLength,
        phoneMaxLength,
        phoneValid,
      },
      username: {},
      gender: {},
    })

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      getAllData()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role.code
    })

    const addData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true

        const phone =
          formData.phone.slice(0, 1) === '0'
            ? `+${formData.phone.substring(1)}`
            : formData.phone.slice(0, 1) !== '+'
            ? `+${formData.phone}`
            : formData.phone

        let payload = {
          avatar: imageFile.value,
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone: phone,
          // username: formData.username,
          // gender: formData.gender,
          // password: formData.password,
          role_id: '8c941c17-1e06-4c84-83a7-959f954a4f29',
        }

        let endpoint = 'user/add'

        if (activeDataId.value !== null && activeDataId.value !== '') {
          payload.id = activeDataId.value

          endpoint = 'user/update'
        }

        await store
          .dispatch(endpoint, payload)
          .then((response) => {
            console.log('-response', response)
            if (response.statusCode === 200) {
              status.value = response.status
              message.value = response.message

              addEditModal.value = false
              validate.value.$reset()

              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              setTimeout(() => {
                getAllData()
              }, 500)
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isFormLoading.value = false
          })
          .catch((error) => {
            console.log('-error ', error)
            console.log('[-error.response ]', error.response)
            const responseData = error.response.data
            console.log('[-error.responseData ]', responseData)
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('user/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            validate.value.$reset()

            getAllData(true)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const resetForm = () => {
      validate.value.$reset()

      activeDataId.value = null

      customerImagePreview.value = ''
      imageFile.value = null

      formData.first_name = ''
      formData.last_name = ''
      formData.email = ''
      formData.phone = ''
      formData.username = ''
      formData.gender = ''
    }

    const addForm = async () => {
      resetForm()

      titleModal.value = 'Tambah Agen Sales'
      addEditModal.value = true
    }

    const editForm = async (data) => {
      resetForm()

      activeDataId.value = data.id

      formData.first_name = data.first_name
      formData.last_name = data.last_name
      formData.email = data.email
      formData.phone = data.phone
      formData.username = data.username
      formData.gender = data.gender

      titleModal.value = 'Ubah Agen Sales'
      addEditModal.value = true
    }

    const deleteForm = (data) => {
      activeData.first_name = data.first_name
      activeData.last_name = data.last_name

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.first_name = ''
      activeData.last_name = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        customerImagePreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        customerImagePreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('user/all', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          agent_only: true,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      filterData = event.filterData
      getAllData(true)
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData(true)
      }, 500)
    }

    const customerPreview = async (customer) => {
      Object.assign(activeCustomer, customer)
      customerPreviewModal.value = true
    }

    const imagePreview = async (customer) => {
      Object.assign(activeCustomer, customer)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeCustomer,

      roleAccess,
      rolesCanManage,

      customerImagePreview,
      imageFile,
      selectFile,
      previewImage,

      addEditModal,
      deleteModal,
      customerPreviewModal,
      imagePreviewModal,

      customerPreview,
      imagePreview,

      isFormLoading,
      isLoading,
      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,

      formData,
      validate,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      cancelDelete,
      activeData,
      deleteData,
      // editData,

      activeDataId,
      getAllData,

      showToast,
      changeFilter,
      searchFilter,
    }
  },
  // data: () => ({
  //   userHeader: [
  //     {
  //       item: 'CUSTOMER',
  //       customClass: 'text-center',
  //     },
  //     {
  //       item: 'NAMA LENGKAP',
  //       customClass: 'text-center',
  //     },
  //     {
  //       item: 'EMAIL',
  //       customClass: 'text-center',
  //     },
  //     {
  //       item: 'NO. TELEPON',
  //       customClass: 'text-center',
  //     },
  //     {
  //       item: 'USERNAME',
  //       customClass: 'text-center',
  //     },
  //     {
  //       item: 'AKSI',
  //       customClass: 'text-center',
  //     },
  //   ],

  //   metaData: {
  //     page: 1,
  //     take: 10,
  //     itemCount: 10,
  //     pageCount: 1,
  //     hasPreviousPage: false,
  //     hasNextPage: true,
  //   },
  //   filterData: {
  //     page: 1,
  //     take: 10,
  //     search: '',
  //     order: 'ASC',
  //   },
  //   userData: [],
  //   userDataMapped: [],
  //   timer: undefined,
  //   isLoading: false,
  // }),
  // mounted() {
  //   // this.init()
  // },
  // methods: {
  //   init() {
  //     this.getAllCustomer()
  //   },
  //   async getAllCustomer() {
  //     if (this.filterData.page === 1) {
  //       this.isLoading = true
  //     }
  //     await this.store
  //       .dispatch('user/all', {
  //         order: this.filterData.order,
  //         page: this.filterData.page,
  //         take: this.filterData.take,
  //         customer_only: true,
  //         q: this.filterData.search,
  //       })
  //       .then((response) => {
  //         const responseData = response.data

  //         if (responseData.data) {
  //           this.userData = responseData.data

  //           this.userDataMapped = this.userData.reduce((filtered, item) => {
  //             let key = item.id
  //             if (!filtered[key]) {
  //               filtered[key] = null
  //             }
  //             filtered[key] = item
  //             return filtered
  //           }, {})
  //         }
  //         if (responseData.meta) {
  //           this.metaData = responseData.meta
  //         }
  //         this.message = response.message
  //         if (this.filterData.page === 1) {
  //           this.isLoading = false
  //         }
  //       })
  //       .catch((e) => {
  //         this.message = e.message
  //         if (this.filterData.page === 1) {
  //           this.isLoading = false
  //         }
  //         // this.showToast('failed')
  //         console.log(this.message)
  //       })
  //   },
  //   async downloadCustomerData() {
  //     await this.store
  //       .dispatch('user/download', {
  //         start_date: this.filterDates.start,
  //         end_date: this.filterDates.end,
  //       })
  //       .then((response) => {
  //         const filename = this.extractFilename(
  //           response.dispotition,
  //           'user-list.csv'
  //         )
  //         saveAs(response.data, filename)

  //         this.message = response.message
  //         this.showToast('success')
  //       })
  //       .catch((e) => {
  //         this.message = e.message
  //         this.showToast('failed')
  //       })
  //   },
  //   changeFilter(event) {
  //     this.filterData = event.filterData
  //     this.getAllCustomer(false)
  //   },
  //   searchFilter() {
  //     clearTimeout(this.timer)
  //     this.timer = setTimeout(() => {
  //       this.getAllCustomer()
  //     }, 500)
  // },
  // },
}
</script>
