import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  all({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.store_id) queryParameters.store_id = payload.store_id
    if (payload.manager_only) queryParameters.manager_only = payload.manager_only
    // if (payload.staff_only) queryParameters.staff_only = payload.staff_only
    // if (payload.agent_only) queryParameters.agent_only = payload.agent_only
    if (payload.customer_only)
      queryParameters.customer_only = payload.customer_only
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/user?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  profile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/user/profile`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateProfile({ state, commit }, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}profile/`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  add({ state, commit }, payload) {
    const formData = new FormData()
    formData.append('avatar', payload.avatar)
    formData.append('first_name', payload.first_name)
    formData.append('last_name', payload.last_name)
    formData.append('email', payload.email)
    formData.append('phone', payload.phone)
    formData.append('type', payload.type)
    formData.append('role_id', payload.role_id)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/user/add`, formData, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  SET_ACTIVE_USER(state, payload) {
    state.activeUserId = payload
  },
  SET_NEED_VERIFY_STATUS_EDIT(state, status) {
    state.needVerifyStatusEdit = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
