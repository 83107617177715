<template>
  <vue-load-image class="flex">
    <template #image>
      <template v-if="image !== null">
        <img
          :src="image"
          class="inset-0 aspect-square w-full object-cover object-center"
          :class="[
            height !== '' ? height : '',
            disabled ? 'disabled-img' : '',
            roundedClass,
            customClass,
          ]"
          :alt="alt"
        />
      </template>
      <template v-else>
        <img
          src="@/assets/images/placeholders/image.png"
          class="inset-0 aspect-square w-full object-cover object-center"
          :class="[
            height !== '' ? height : '',
            disabled ? 'disabled-img' : '',
            customClass,
            roundedClass,
          ]"
        />
      </template>
    </template>
    <template #preloader>
      <ShimmerCard
        :height="height !== '' ? height : 'aspect-square'"
        :rounded-class="roundedClass"
      />
    </template>
    <template #error>
      <img
        src="@/assets/images/placeholders/image.png"
        class="inset-0 aspect-square w-full object-cover object-center"
        :class="[
          height !== '' ? height : '',
          disabled ? 'disabled-img' : '',
          customClass,
          roundedClass,
        ]"
      />
    </template>
  </vue-load-image>
</template>

<script>
import ShimmerCard from '@/components/shimmer/ShimmerCard.vue'
import MenuInfoIcon from '@/assets/svg/menu-info.svg'
import MenuInfoSmallIcon from '@/assets/svg/menu-info-small.svg'

export default {
  name: 'ImageLoader',
  components: {
    ShimmerCard,
    MenuInfoIcon,
    MenuInfoSmallIcon,
  },
  props: {
    image: {
      type: String,
      default: null,
      required: false,
    },
    alt: {
      type: String,
      default: '',
      required: false,
    },
    height: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
      required: false,
    },
    roundedClass: {
      type: String,
      default: 'rounded-lg',
      required: false,
    },

    isDefaultMenu: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
}
</script>
