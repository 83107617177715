<template>
  <div class="text-slate-500 sm:ml-auto sm:mt-0">
    <button
      :class="[wrapperClass]"
      class="btn box ml-3 flex items-center bg-black text-white dark:bg-white dark:text-black"
    >
      <slot name="icon"></slot>
      {{ title }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'ButtonComponent',
  props: {
    wrapperClass: { type: String, default: '' },
    title: { type: String, default: '' },
  },
}
</script>
