<template>
  <h2 class="intro-y mt-6 text-lg font-medium">List Transaksi</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center xl:flex-nowrap"
    >
      <div class="flex w-full sm:w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            type="text"
            class="form-control box w-48 pr-10"
            placeholder="Search by invoice..."
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
        <select class="box form-select ml-2">
          <option>Status</option>
          <option>Menunggu Pembayaran</option>
          <option>Terkonfirmasi</option>
          <option>Packing</option>
          <option>Dikirim</option>
          <option>Selesai</option>
        </select>
      </div>
      <div class="mx-auto hidden text-slate-500 xl:block">
        Menampilkan 1 sampai 10 dari 150 data
      </div>
      <div class="mt-3 flex w-full items-center xl:mt-0 xl:w-auto">
        <button class="btn btn-primary mr-2 shadow-md">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
        <Dropdown>
          <DropdownToggle class="dropdown-toggle btn box px-2">
            <span class="flex h-5 w-5 items-center justify-center">
              <PlusIcon class="h-4 w-4" />
            </span>
          </DropdownToggle>
          <DropdownMenu class="w-40">
            <DropdownContent>
              <DropdownItem>
                <ArrowLeftRightIcon class="mr-2 h-4 w-4" />
                Ubah Status
              </DropdownItem>
              <DropdownItem>
                <BookmarkIcon class="mr-2 h-4 w-4" /> Bookmark
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
      <table class="table-report -mt-2 table">
        <thead>
          <tr>
            <th class="whitespace-nowrap">
              <input class="form-check-input" type="checkbox" />
            </th>
            <th class="whitespace-nowrap">INVOICE</th>
            <th class="whitespace-nowrap">NAMA CUSTOMER</th>
            <th class="whitespace-nowrap text-center">TOKO</th>
            <th class="whitespace-nowrap text-center">PLATFORM</th>
            <th class="whitespace-nowrap">PEMBAYARAN</th>
            <th class="whitespace-nowrap text-center">TOTAL TRANSAKSI</th>
            <th class="whitespace-nowrap text-center">SALES SALES</th>
            <th class="whitespace-nowrap text-center">DRIVER</th>
            <th class="whitespace-nowrap text-center">STATUS</th>
            <th class="whitespace-nowrap text-center">AKSI</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(faker, fakerKey) in $_.take($f(), 9)"
            :key="fakerKey"
            class="intro-x"
          >
            <td class="w-10">
              <input class="form-check-input" type="checkbox" />
            </td>
            <td class="w-40 !py-4">
              <a
                href="#"
                @click="
                  $router.push({
                    path: 'transaction-detail',
                    params: {
                      slug: $route.params.slug,
                    },
                    query: {
                      lang: $route.query.lang,
                    },
                  })
                "
                class="whitespace-nowrap underline decoration-dotted"
                >{{ '#INV-' + faker.totals[0] + '807556' }}</a
              >
            </td>
            <td class="w-40">
              <a href="" class="whitespace-nowrap font-medium">{{
                faker.users[0].name
              }}</a>
              <div
                v-if="faker.trueFalse[0]"
                class="mt-0.5 whitespace-nowrap text-xs text-slate-500"
              >
                Chicago Street 1
              </div>
              <div
                v-else
                class="mt-0.5 whitespace-nowrap text-xs text-slate-500"
              >
                South Jakarta
              </div>
            </td>
            <td class="text-center">
              <a
                class="flex items-center justify-center underline decoration-dotted"
                href="javascript:;"
                >{{
                  [
                    'Gromura - Jakarta Indonesia',
                    'Gromura - Chicago, United States',
                    'Gromura - LA, United States',
                  ][$_.random(0, 2)]
                }}</a
              >
            </td>

            <td class="text-center">
              <div
                class="flex flex-col items-center justify-center whitespace-nowrap"
                :class="{
                  'text-warning': faker.trueFalse[0],
                  'text-info': !faker.trueFalse[0],
                }"
              >
                <!-- {{ faker.trueFalse[0] ? 'POS' : 'SO' }} -->
                <!-- <br /> -->
                <p class="text-xs text-gray-400">
                  {{ faker.trueFalse[0] ? 'Android' : 'Web' }}
                </p>
              </div>
            </td>
            <td>
              <template v-if="faker.trueFalse[0]">
                <div class="whitespace-nowrap">QRIS</div>
                <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                  22 November, 12:55
                </div>
              </template>
              <template v-else>
                <div class="whitespace-nowrap">Bank Transfer</div>
                <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                  22 November, 11:00
                </div>
              </template>
            </td>
            <td class="text-center">Rp{{ faker.totals[0] + ',000,00' }}</td>
            <td class="text-center">
              <div
                class="flex items-center justify-center whitespace-nowrap"
                :class="{
                  'text-success': faker.trueFalse[0],
                  'text-danger': !faker.trueFalse[0],
                }"
              >
                {{ faker.trueFalse[0] ? 'Completed' : 'Cancelled' }}
              </div>
            </td>
            <td class="text-center">
              {{
                faker.trueFalse[0]
                  ? ''
                  : ['Amanda', 'Novita', 'Christina', 'Nabila'][$_.random(0, 1)]
              }}
            </td>
            <td class="text-center">
              {{
                faker.trueFalse[0]
                  ? ''
                  : ['Ujang', 'Asep', 'Robert', 'Udin'][$_.random(0, 1)]
              }}
            </td>
            <td class="table-report__action">
              <div class="flex items-center justify-center">
                <a
                  class="mr-5 flex items-center whitespace-nowrap text-primary"
                  href="javascript:;"
                >
                  <EyeIcon class="mr-1 h-4 w-4" /> Lihat Detail
                </a>
                <a
                  class="flex items-center whitespace-nowrap text-primary"
                  href="javascript:;"
                  @click="deleteConfirmationModal = true"
                >
                  <ArrowLeftRightIcon class="mr-1 h-4 w-4" />
                  Ubah Status
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div>
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref } from 'vue'

const deleteConfirmationModal = ref(false)
</script>
