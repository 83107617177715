<template>
  <span
    v-if="status === 'PENDING'"
    class="rounded-full bg-amber-400 px-3 py-1 text-white"
  >
    Tertunda
  </span>
  <span
    v-else-if="status === 'PROCESS'"
    class="rounded-full bg-lime-400 px-3 py-1 text-white"
  >
    Diproses
  </span>
  <span
    v-else-if="status === 'DELIVERY'"
    class="rounded-full bg-purple-400 px-3 py-1 text-white"
  >
    Dikirim
  </span>
  <span
    v-else-if="status === 'PAID'"
    class="rounded-full bg-teal-400 px-3 py-1 text-white"
  >
    Dibayar
  </span>
  <span
    v-else-if="status === 'RECEIVED'"
    class="rounded-full bg-sky-400 px-3 py-1 text-white"
  >
    Diterima
  </span>
  <span
    v-else-if="status === 'COMPLETED'"
    class="rounded-full bg-green-400 px-3 py-1 text-white"
  >
    Selesai
  </span>
  <span
    v-else-if="status === 'CANCELLED'"
    class="rounded-full bg-red-400 px-3 py-1 text-white"
  >
    Dibatalkan
  </span>
</template>

<script>
export default {
  name: 'StatusTrx',
  props: {
    status: {
      type: String,
      default: 'PENDING',
      required: false,
      validator: function (value) {
        return [
          'PENDING',
          'PROCESS',
          'DELIVERY',
          'PAID',
          'RECEIVED',
          'COMPLETED',
          'CANCELLED',
        ].includes(value)
      },
    },
  },
}
</script>
