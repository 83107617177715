import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.q) queryParameters.q = payload.q
    if (payload.status && payload.status !== null) queryParameters.status = payload.status

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/transaction?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getByStore({ commit }, payload) {
    let queryParameters = {
      store_id: payload.store_id,
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.created_by) queryParameters.created_by = payload.created_by
    if (payload.q) queryParameters.q = payload.q
    if (payload.status && payload.status !== null) queryParameters.status = payload.status

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/transaction?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/transaction/` + id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  summary({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/transaction/summary`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/transaction/create`, payload)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // receive({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .put(
  //         `${routePathAPI()}/transaction/receive/` + payload.transaction_id,
  //         payload
  //       )
  //       .then(async (response) => {
  //         if (response.status === 200) {
  //           resolve(response.data)
  //         } else {
  //           reject(response.data)
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  cancel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/transaction/cancel/` + payload.transaction_id,
          payload
        )
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${routePathAPI()}/transaction/change-status/` + payload.transaction_id,
          payload
        )
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  upload({ commit }, payload) {
    const formData = new FormData()

    formData.append('type', payload.type)
    formData.append('document', payload.document)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(
          `${routePathAPI()}/transaction/upload/` + payload.transaction_id,
          formData,
          config
        )
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}/transaction/update/` + payload.id, payload)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/transaction/delete/` + id)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
