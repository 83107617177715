import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  paymentTerm({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/payment/term?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentMethod({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/payment/method?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
