<template>
  <div class="flex flex-col">
    <DetailImage
      v-if="activeProduct !== undefined"
      :images="activeProduct.images"
      :name="activeProduct.name"
      rounded-class="rounded-tr-md rounded-tl-md"
    />

    <div class="p-4">
      <div class="flex w-full truncate text-xl font-medium">
        {{ activeProduct.name }}
      </div>
      <!-- <div v-if="activeProduct.brand !== undefined">
        <span class="mt-3 text-xs text-green-600">{{
          activeProduct.brand.name
        }}</span>
      </div> -->

      <div
        class="mt-2 flex w-full"
      >
        <div v-if="activeProduct.brand !== undefined" class="rounded-md bg-green-100 pl-1 pr-3 py-1 text-xs flex items-center">
          <CloudIcon class="h-3 w-3 text-gray-600" /> &nbsp <span class="text-green-600">{{ activeProduct.brand.name }}</span>
        </div>
        <div
          v-if="activeProduct.sub_category !== undefined && activeProduct.sub_category.category !== undefined"
          class="ml-3 rounded-md bg-sky-100 px-3 py-1 text-xs text-sky-600"
        >
          {{ activeProduct.sub_category.category.name }}
        </div>
        <div v-if="activeProduct.sub_category !== undefined" class="ml-3 rounded-md bg-purple-100 px-3 py-1 text-xs text-purple-600">
          {{ activeProduct.sub_category.name }}
        </div>
      </div>

      <div
        v-if="basePrice !== null && salePrice !== null"
        class="mt-4 flex w-full flex-row border-dashed border-t-2 py-4"
      >
        <div class="flex w-full flex-col">
          <div class="flex">
            <span class="text-xs text-gray-600"> Harga Beli </span>
          </div>
          <div class="mt-1 font-bold text-green-600">
            <Currency :total-in-string="basePrice" class="text-lg" />
          </div>
        </div>
        <div class="flex w-full flex-col">
          <div class="flex">
            <span class="text-xs text-gray-600"> Harga Jual </span>
          </div>
          <div class="mt-1 font-bold text-red-600">
            <Currency :total-in-string="salePrice" class="text-lg" />
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
        <div class="flex font-bold text-red-600">
          <Currency :total-in-string="activeProduct.price" class="text-lg" />
        </div>
      </div>

      <div
        v-if="activeProduct.product_converters !== undefined"
        class="mt-4 flex w-full border-dashed border-t-2 pt-4"
      >
        <div
          v-for="item in activeProduct.product_converters"
          :key="item.id"
          class="flex w-1/2 flex-col"
        >
          <div class="flex">
            <template v-if="item.base === true">
              <span class="text-xs">Satuan Besar</span>
            </template>
            <template v-else>
              <span class="text-xs">Satuan Kecil</span>
            </template>
          </div>
          <div class="flex flex-col">
            <div class="flex">
              <BarcodeGenerator
                :value="item.barcode"
                :line-color="'#000'"
                :width="1"
                :height="40"
                :font-size="14"
                :element-tag="'svg'"
                class="-ml-3"
              />
            </div>
            <div class="flex">
              <div class="block truncate text-base font-medium">
                {{ item.product_unit.name.toUpperCase() }}
              </div>
              <template v-if="activeProduct.is_accessories === true">
                <span class="ml-2 mt-1 text-xs text-red-600">{{
                  item.converter
                }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="activeProduct.product_converters !== undefined && stock !== null"
        class="mt-2 flex w-full border-dashed border-t-2 pt-4"
      >
        <template v-if="activeProduct.is_accessories === true">
          <div class="flex w-1/2">
            Stock: &nbsp<span class="text-green-600 font-bold">{{ bigStock }}</span>
          </div>
          <div class="flex w-1/2">
            Stock: &nbsp<span class="text-green-600 font-bold">{{ smallStock }}</span>
          </div>
        </template>
        <template v-else>
          <div class="flex w-1/2">
            Stock: &nbsp<span class="text-green-600 font-bold">{{ bigStock }}</span>
          </div>
        </template>
      </div>
      <div class="mt-4 flex w-full border-dashed border-t-2 pt-4">
        <span class="text-gray-700" v-html="activeProduct.description" />
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from 'swiper/modules'
import BarcodeGenerator from '@/components/barcode/Generator.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import Currency from '@/components/Currency.vue'

export default {
  name: 'DetailProduct',
  components: {
    Pagination,
    BarcodeGenerator,
    DetailImage,
    Currency,
  },
  props: {
    activeProduct: {
      type: Object,
      default: null,
      required: true,
    },
    basePrice: {
      type: Number,
      default: null,
      required: false,
    },
    salePrice: {
      type: Number,
      default: null,
      required: false,
    },
    stock: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data: () => ({
    modulesImage: [Pagination],
    bigStock: 0,
    smallStock: 0,
  }),
  mounted() {
    this.convertStock()
  },
  methods: {
    convertStock() {
      const product = this.activeProduct
      const converters = product.product_converters

      if (product.is_accessories === true) {
        let unitBig = {}
        let unitSmall = {}
        let converter = 0

        converters.map((item) => {
          if (item.base === true) {
            unitBig = item.product_unit
          } else {
            unitSmall = item.product_unit
            converter = item.converter
          }
        })

        const divideNumber = this.stock / converter

        if (converter > this.stock) {
          return `${this.stock} ${unitSmall.name}`
        } else if (divideNumber.toString().split('.').length > 0) {
          const roundNumber = Math.floor(divideNumber.toString().split('.')[0])
          const leftNumber = this.stock - roundNumber * converter

          this.bigStock = `${roundNumber} ${unitBig.name}`
          this.smallStock = `${leftNumber} ${unitSmall.name}`
        } else {
          this.bigStock = `${this.stock} ${unitBig.name}`
        }
      } else {
        if (converters !== undefined && converters.length > 0) {
          // default for unit product
          const unit = converters[0].product_unit
          this.bigStock = `${this.stock} ${unit.name}`
        }
      }
    },
  },
}
</script>
