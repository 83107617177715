<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Term Pembayaran</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <div class="flex w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-56 pr-8"
            placeholder="Cari kecamatan..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto -mr-4 h-4 w-4"
          />
        </div>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <button class="btn btn-primary mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary text-white">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="districtHeader"
      :meta-data="metaData"
      :is-not-empty="districtData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="item in districtData" :key="item.id" class="intro-x">
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="capitalize">
          <div v-if="item.code === 'CASH'" class="text-secondary">
            {{ item.code }}
          </div>
          <div v-else-if="item.code === 'DUE_DATE'" class="text-warning">
            {{ item.code }}
          </div>

          <div v-else>
            {{ item.code }}
          </div>
        </td>
        <td>
          {{ item.name }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.longitude }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.latitude }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.alt_name }}
        </td>
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center text-success">
            <a class="mr-3 flex items-center" href="javascript:;">
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <!-- <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal> -->
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    districtHeader: [
      {
        item: 'KODE',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      {
        item: 'LONGITUDE',
        customClass: '',
      },
      {
        item: 'LATITUDE',
        customClass: '',
      },
      {
        item: 'ALT NAME',
        customClass: '',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    },
    cityCode: null,
    districtData: [],
    timer: undefined,
    isLoading: false,
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getAllDistrict()
    },
    async getAllDistrict() {
      if (this.filterData.page === 1) {
        this.isLoading = true
      }
      await this.store
        .dispatch('address/district', {
          city_code: this.cityCode,
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            this.districtData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (this.filterData.page === 1) {
            this.isLoading = false
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
          if (this.filterData.page === 1) {
            this.isLoading = false
          }
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllDistrict(false)
    },
    searchFilter() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.filterData.page = 1
        this.getAllDistrict()
      }, 500)
    },
  },
}
</script>
