<template>
  <h2 class="intro-y mt-6 text-lg font-medium">List Outlet</h2>
  <ButtonAdd />
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
    <div class="flex w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-56 pr-8"
            placeholder="Cari outlet..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto -mr-4 h-4 w-4"
          />
        </div>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <button class="btn btn-primary mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary text-white">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Users Layout -->
    <div v-for="item in tableData" :key="item.id" class="intro-y col-span-12">
      <div class="box">
        <div class="flex p-5">
          <div class="flex">
            <div class="image-fit zoom-in h-16 w-16">
              <ImageLoader
                :image="item.image"
                :alt="item.name"
                rounded-class="rounded-full"
                @click="imagePreview(item)"
              />
            </div>
          </div>
          <div
            class="ml-5 flex w-full flex-col text-slate-600 dark:text-slate-500"
          >
            <div class="flex">
              <span class="text-lg font-bold">
                {{ item.name }}
              </span>
            </div>
            <div class="flex">
              <span class="text-success">
                {{ item.category.name }}
              </span>
            </div>
            <div class="mt-4 flex">
              <div class="flex w-1/4">
                <LinkIcon class="mr-2 h-4 w-4" />
                Dibuat
              </div>
              <div class="flex w-3/4">
                <span>: {{ new Date(item.created).toLocaleDateString() }}</span>
              </div>
            </div>
            <div class="mt-2 flex">
              <div class="flex w-1/4">
                <UsersIcon class="mr-2 h-4 w-4" /> Jumlah Staff
              </div>
              <div class="flex w-3/4">
                <span>: {{ item.store_staffs.length }}</span>
              </div>
            </div>
            <div class="mt-2 flex">
              <div class="flex w-1/4">
                <MailIcon class="mr-2 h-4 w-4" /> Email
              </div>
              <div class="flex w-3/4">
                <span>: {{ item.email }}</span>
              </div>
            </div>
            <div class="mt-2 flex">
              <div class="flex w-1/4">
                <PhoneIcon class="mr-2 h-4 w-4" /> No Telpon
              </div>
              <div class="flex w-3/4">
                <span>: {{ item.phone }}</span>
              </div>
            </div>
            <div v-if="'address' in item" class="mt-2 flex">
              <div class="flex w-1/4">
                <MapPinIcon class="mr-2 h-4 w-4" /> Alamat
              </div>
              <div class="flex w-3/4">
                <span
                  >: {{ item.address.address }},
                  {{ item.address.postal_code }}</span
                >
              </div>
            </div>
            <div v-if="'address' in item" class="mt-1 flex">
              <div class="flex w-1/4"></div>
              <div class="flex w-3/4">
                &nbsp&nbsp<span
                  >{{ item.address.village.name }},
                  {{ item.address.district.name }},
                  {{ item.address.city.name }},
                  {{ item.address.province.name }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="mr-3 flex flex-col">
              <a class="flex" href="javascript:;">
                <CheckSquareIcon class="mr-1 h-4 w-4 self-center" /> Edit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Users Layout -->
    <!-- BEGIN: Pagination -->
    <!-- <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div> -->
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <!-- <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal> -->
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/button/ButtonAdd.vue'

export default {
  components: {
    ImageLoader,
    Currency,
    Multiselect,
    ButtonAdd
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const activeStore = reactive({})

    const storeImagePreview = ref('')
    const imageFile = ref(null)

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const timer = ref(undefined)

    const storePreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)
    const deleteModal = ref(false)

    const responseStatus = ref('')
    const responseMessage = ref('')

    const titleModal = ref('')

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    })

    const formData = reactive({
      image: null,
      name: '',
    })

    const activeData = reactive({
      image: null,
      name: '',
    })

    const nameRequired = helpers.withMessage(
      'formError.storeName.required',
      required
    )

    const rules = {
      name: {
        nameRequired,
      },
    }

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      getAllData()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role.code
    })

    const addData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true

        let payload = {
          image: imageFile.value,
          name: formData.name,
        }

        let endpoint = 'store/create'

        if (activeDataId.value !== null && activeDataId.value !== '') {
          payload.id = activeDataId.value

          endpoint = 'store/update'
        }

        await store
          .dispatch(endpoint, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              responseStatus.value = response.status
              responseMessage.value = response.message

              addEditModal.value = false

              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              validate.value.$reset()

              getAllData()
            } else {
              responseStatus.value = response.message.status
              responseMessage.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isFormLoading.value = false
          })
          .catch((error) => {
            const responseData = error.response.data
            isFormLoading.value = false
            responseStatus.value = responseData.message.status
            responseMessage.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('store/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            responseStatus.value = response.status
            responseMessage.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            validate.value.$reset()

            getAllData()
          } else {
            responseStatus.value = response.message.status
            responseMessage.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          responseStatus.value = responseData.message.status
          responseMessage.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const resetForm = () => {
      validate.value.$reset()

      storeImagePreview.value = ''
      imageFile.value = null

      formData.image = null
      formData.name = ''

      activeDataId.value = null
    }

    const addForm = () => {
      resetForm()

      titleModal.value = 'Tambah Toko'
      addEditModal.value = true
    }

    const editForm = (data) => {
      resetForm()

      activeDataId.value = data.id

      formData.image = data.image
      formData.name = data.name

      titleModal.value = 'Ubah Toko'
      addEditModal.value = true
    }

    const deleteForm = (data) => {
      activeData.name = data.name

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.name = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        storeImagePreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        storeImagePreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (success) => {
      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('store/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      filterData = event.filterData
      getAllData(false)
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData()
      }, 500)
    }

    const storePreview = async (store) => {
      Object.assign(activeStore, store)
      storePreviewModal.value = true
    }

    const imagePreview = async (store) => {
      Object.assign(activeStore, store)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeStore,

      roleAccess,

      storeImagePreview,
      imageFile,
      selectFile,
      previewImage,

      addEditModal,
      deleteModal,
      storePreviewModal,
      imagePreviewModal,

      storePreview,
      imagePreview,

      isFormLoading,
      isLoading,
      timer,

      responseStatus,
      responseMessage,

      titleModal,
      tableData,
      metaData,
      filterData,

      formData,
      validate,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      cancelDelete,
      activeData,
      deleteData,
      // editData,

      activeDataId,
      getAllData,

      showToast,
      changeFilter,
      searchFilter,
    }
  },
}
</script>
