<template>
  <h2 class="intro-y mt-6 text-lg font-medium">Good Stock</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center xl:flex-nowrap"
    >
      <div class="flex w-full sm:w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-48 pr-4"
            placeholder="Cari produk..."
            @input="searchFilter()"
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
        <!-- <select
          v-model="filterData.type"
          placeholder="Semua"
          class="box form-select ml-4"
          @change="getAllData"
        >
          <option
            v-for="method in productTypeOptions"
            :key="method.id"
            :value="method.id"
          >
            {{ method.name }}
          </option>
        </select> -->
      </div>
      <div class="mx-auto hidden text-slate-500 xl:block">
        Menampilkan {{ metaData.take * (metaData.page - 1) + 1 }} sampai
        {{
          metaData.take > metaData.itemCount
            ? metaData.itemCount
            : metaData.take * metaData.page
        }}
        dari {{ metaData.itemCount }} data
      </div>
      <div class="mt-3 flex w-full items-center xl:mt-0 xl:w-auto">
        <button class="btn btn-primary mr-2 shadow-md">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
        <!-- <Dropdown>
          <DropdownToggle class="dropdown-toggle btn box px-2">
            <span class="flex h-5 w-5 items-center justify-center">
              <PlusIcon class="h-4 w-4" />
            </span>
          </DropdownToggle>
          <DropdownMenu class="w-40">
            <DropdownContent>
              <DropdownItem>
                <ArrowLeftRightIcon class="mr-2 h-4 w-4" />
                Ubah Status
              </DropdownItem>
              <DropdownItem>
                <BookmarkIcon class="mr-2 h-4 w-4" /> Bookmark
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </Dropdown> -->
      </div>
    </div>
    <!-- <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <div class="flex">
        <button class="btn btn-primary mr-2 shadow-md">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn btn-primary mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>

      <div class="mx-auto hidden text-slate-500 md:block">
        Menampilkan {{ metaData.take * (metaData.page - 1) + 1 }} sampai
        {{
          metaData.take > metaData.itemCount
            ? metaData.itemCount
            : metaData.take * metaData.page
        }}
        dari {{ metaData.itemCount }} data
      </div>
      <div class="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <input
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Cari produk..."
            v-model="filterData.search"
            @input="searchFilter()"
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
      </div>
    </div> -->
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="item in tableData" :key="item.id" class="intro-x">
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="
                  item.product.images.length > 0
                    ? item.product.images[0].image
                    : null
                "
                :alt="item.product.name"
                rounded-class="rounded-md"
                @click="imagePreview(item)"
              />
            </div>
          </div>
        </td>
        <td>
          {{ item.product.name }}
        </td>
        <td class="w-40 text-green-600">
          <Currency :total-in-string="item.price" />
        </td>
        <td class="w-40 text-red-600">
          <Currency :total-in-string="item.sale_price" />
        </td>
        <td class="text-center">
          {{ item.stock }}
          <!-- {{ convertStock(item) }} -->
        </td>
        <td class="text-center">
          {{ item.sold_stock }}
        </td>
        <!-- <td v-if="filterData.type === null">
          <div class="flex justify-center text-center capitalize">
            <span
              v-if="item.product.is_accessories === true"
              class="mr-1 rounded-full bg-secondary px-3 py-1 text-white"
            >
              AKSESORIS
            </span>
            <span
              v-else
              class="mr-1 rounded-full bg-danger px-3 py-1 text-white"
            >
              UNIT
            </span>
          </div>
        </td> -->

        <td class="table-report__action w-32">
          <div class="flex items-center justify-center">
            <a
              class="flex items-center text-secondary"
              href="javascript:;"
              @click="productPreview(item)"
            >
              <EyeIcon class="mr-1 h-4 w-4" /> Detail
            </a>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct
        v-if="activeProductStock.product !== undefined"
        :active-product="activeProductStock.product"
        :base-price="activeProductStock.price"
        :sale-price="activeProductStock.sale_price"
        :stock="activeProductStock.stock"
      />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="activeProductStock.product !== undefined"
        :images="activeProductStock.product.images"
        :name="activeProductStock.product.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ responseStatus }}</div>
      <div class="mt-1 text-slate-500">
        {{ responseMessage }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ responseStatus }}</div>
      <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    DetailImage,
    DetailProduct,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const activeProductStock = reactive({})

    const isLoading = ref(false)
    const timer = ref(undefined)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)

    const responseStatus = ref('')
    const responseMessage = ref('')

    const tableHeader = ref([
      {
        item: 'GAMBAR',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      {
        item: 'HARGA BELI',
        customClass: '',
      },
      {
        item: 'HARGA JUAL',
        customClass: '',
      },
      {
        item: 'STOCK ON HAND',
        customClass: 'text-center',
      },
      {
        item: 'TERJUAL',
        customClass: 'text-center',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
      // type: null,
    })

    const productTypeOptions = ref([
      {
        id: null,
        name: 'Semua',
      },
      {
        id: 'UNITS',
        name: 'Unit',
      },
      {
        id: 'ACCESSORIES',
        name: 'Aksesoris',
      },
    ])

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role.code

      emitter.on("update-data", store => {
        console.log(`---updating page => ${route.name} for store Id ${store.name} `)
        getAllData(true)
      });

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getAllData()
        }, 2000)
      } else {
        getAllData()
      }

    })

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']

      await store
        .dispatch('productStock/getByStore', {
          store_id: storeId,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
          // is_accessories:
          //   filterData.type === 'ACCESSORIES'
          //     ? true
          //     : filterData.type === 'UNITS'
          //     ? false
          //     : null,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      filterData = event.filterData
      getAllData(false)
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData()
      }, 500)
    }

    const productPreview = async (product) => {
      Object.assign(activeProductStock, product)
      productPreviewModal.value = true
    }

    const imagePreview = async (product) => {
      Object.assign(activeProductStock, product)
      imagePreviewModal.value = true
    }

    const convertStock = (stock) => {
      const product = stock.product
      const converters = product.product_converters

      if (product.is_accessories === true) {
        let unitBig = {}
        let unitSmall = {}
        let converter = 0

        converters.map((item) => {
          if (item.base === true) {
            unitBig = item.product_unit
          } else {
            unitSmall = item.product_unit
            converter = item.converter
          }
        })

        const divideNumber = stock.stock / converter

        if (converter > stock.stock) {
          return `${stock.stock} ${unitSmall.name}`
        } else if (divideNumber.toString().split('.').length > 0) {
          const roundNumber = Math.floor(divideNumber.toString().split('.')[0])
          const leftNumber = stock.stock - roundNumber * converter

          return `${roundNumber} ${unitBig.name} ${leftNumber} ${unitSmall.name}`
        } else {
          return `${stock.stock} ${unitBig.name}`
        }
      }
      
      // default for unit product
      const unit = converters[0].product_unit
      return `${stock.stock} ${unit.name}`
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeProductStock,

      roleAccess,

      productPreview,
      imagePreview,

      productPreviewModal,
      imagePreviewModal,

      isLoading,
      timer,

      showToast,
      responseStatus,
      responseMessage,

      tableHeader,
      tableData,

      metaData,
      filterData,
      productTypeOptions,

      getAllData,

      changeFilter,
      searchFilter,
      convertStock,
    }
  },
}
</script>
