export default {
  appName: 'Kiraket',
  language: {
    id: 'ID',
    en: 'EN',
    languages: 'Languages',
    change: 'Change Language',
    bahasa: 'Indonesia',
    english: 'English',
  },
  menu: {
    home: 'Home',
    account: 'Account',
    dashboard: 'Dashboard',
    store: 'Store',
    report: {
      index: 'Report',
      summary: 'Report Summary',
      orderDetail: 'Detail Order',
      tenantSales: 'Tenant Sales',
      dailySales: 'Daily Sales',
      productSales: 'Product Sales',
      paymentMethod: 'Payment Method Report',
      withdrawal: 'Withdrawal Report',
      promotion: 'Promotion Report',
    },
    analytic: {
      index: 'Analytic',
      averageTransaction: 'Average Transaction',
      growthPercentage: 'Growth Percentage',
      stockTurnOver: 'Stock Turnover',
      ecommerceIndex: 'E-commerce Index',
      satisfactionIndex: 'Satisfaction Index',
    },
    profile: {
      index: 'Profile',
      account: 'Account Information',
      business: 'Bussiness Information',
      store: 'Store Information',
    },
  },
  action: {
    account: 'Profile',
    setting: 'Setting',
    logout: 'Logout',
  },
  page: {
    dashboard: {
      sale: 'Sale',
      grossProfit: 'Gross Profit',
      netProfit: 'Net Profit',
      transactionTotal: 'Transaction Total',
    },
    profile: {
      index: 'Profile',
      account: 'Profile Account',
      accessInformation: 'Access Rights Information',
      addressInformation: 'Address Information',
      changePassword: 'Change Password',
      accessRight: 'Access Rights',
    },
    business: {
      index: 'Bussiness',
      information: 'Bussiness Information',
      addressInformation: 'Address Information',
      socialMedia: 'Social Media',
    },
    store: {
      index: 'Store',
      information: 'Store Information',
      addressInformation: 'Address Information',
    },
    setting: 'Setting',
  },
  general: {
    greeting: 'Welcome!',
    hello: 'Hello',
    tnc: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    optional: 'optional',
  },
  info: {
    dataNotFound: 'Data not found',
    loginGreeting: 'A few more clicks to sign in to your account.',
    loginGreetingDetail: 'Manage your accounts in one place',
    currentVersion: 'Current Version Dashboard',
    pageMissing: 'Oops. This page has gone missing',
    pageMissingInstruction:
      'You may have mistyped the address or the page may have moved.',
  },
  confirmation: {
    logout: 'Are you sure want to logout?',
  },
  button: {
    submit: 'Submit',
    login: 'Login',
    logout: 'Logout',
    cancel: 'Cancel',
    register: 'Register',
    sendEmail: 'Send Email',
    sendOTP: 'Send OTP',
    resetPassword: 'Reset Password',
    forgotPassword: 'Forgot Password?',
    saveChanges: 'Save Changes',
    connect: 'Connect',
    connected: 'Connected',
    backToHome: 'Back to Home',
  },
  formInfo: {
    notLogin: 'You are not logged in',
    registerSuccess: 'You have successfully registered',
    registerFailed: 'Registration failed',
    loginSuccess: 'Login Success',
    loginFailed: 'Login Failed',
    saveChangesSuccess: 'Changes Saved Successfully',
    saveChangesFailed: 'Changes Failed to Save',
    loginDesc: 'Enter your email and password',
    loginCheck: 'By signin up, you agree to our',
  },
  formLabel: {
    optional: '(Optional)',
    code: 'Code',
    npwp: 'NPWP',
    pic: 'PIC',
    taxId: 'Tax ID',
    barcode: 'Barcode',
    converter: 'Converter',
    name: 'Name',
    price: 'Price',
    productName: 'Product Name',
    fullName: 'Full Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    businessName: 'Bussiness Name',
    storeName: 'Store Name',
    tenantName: 'Tenant Name',
    email: 'Email',
    phone: 'Phone Number',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    newPasswordConfirmation: 'New Password Confirmation',
    accessRight: 'Access Right',
    pin: 'OTP',
    description: 'Description',
    website: 'Website',
    province: 'Province',
    city: 'City',
    district: 'District',
    village: 'Village',
    postalCode: 'Postal Code',
    address: 'Address',
    productCategory: 'Product Category',
    productSubCategory: 'Product Sub Category',
    productPrinciple: 'Product Principle',
    productBrand: 'Product Brand',
    productSubBrand: 'Product Sub Brand',
    productUnit: 'Product Unit',
    product: 'Product',
    select: {
      province: 'Select Province',
      city: 'Select City',
      district: 'Select District',
      village: 'Select Village',
      productCategory: 'Select Product Category',
      productSubCategory: 'Select Product Sub Category',
      productPrinciple: 'Select Product Principle',
      productBrand: 'Select Product Brand',
      productSubBrand: 'Select Product Sub Brand',
      productUnit: 'Select Product Unit',
      product: 'Select Product',
    },
  },
  formInput: {
    enterCode: 'Please enter code',
    enterBarcode: 'Please enter barcode',
    enterConverter: 'Please enter converter',
    enterName: 'Please enter name',
    enterNpwp: 'Please enter NPWP',
    enterPic: 'Please enter PIC',
    enterPrice: 'Please enter price',
    enterProductName: 'Please enter product name',
    enterFullName: 'Please enter your full name',
    enterFirstName: 'Please enter your first name',
    enterLastName: 'Please enter your last name',
    enterBussinessName: 'Please enter your business name',
    enterTenantName: 'Please enter tenant name',
    enterDescription: 'Please enter desription',
    enterWebsite: 'Please enter website',
    enterEmail: 'Please enter your email',
    enterPhone: 'Please enter your phone number',
    enterPassword: 'Please enter your password',
    enterPIN: 'Please enter your PIN',
    enterAddress: 'Please enter your address',
    enterPostalCode: 'Please enter postal code',
  },
  formError: {
    code: {
      required: 'Code is required',
    },
    barcode: {
      required: 'Barcode is required',
    },
    converter: {
      required: 'Converter is required',
    },
    name: {
      required: 'Name is required',
    },
    price: {
      required: 'Price is required',
    },
    productName: {
      required: 'Product name is required',
    },
    fullName: {
      required: 'Full Name is required',
    },
    firstName: {
      required: 'First Name is required',
    },
    lastName: {
      required: 'Last Name is required',
    },
    businessName: {
      required: 'Bussiness Name is required',
    },
    tenantName: {
      required: 'Tenant Name is required',
    },
    email: {
      required: 'Email is required',
      validEmail: 'Email must be valid email address',
    },
    phone: {
      required: 'Phone number is required',
      validPhone: 'Phone number must be valid phone number',
      minLength: 'Phone number must be at least 9 digits',
      maxLength: 'Phone number maximum length is 13 digits',
    },
    pin: {
      required: 'PIN is required',
    },
    address: {
      required: 'Address is required',
    },
    postalCode: {
      required: 'Postal Code is required',
    },
    oldPassword: {
      required: 'Old password is required',
    },
    newPassword: {
      required: 'New password is required',
    },
    newPasswordConfirmation: {
      required: 'Password confirmation is required',
    },
    password: {
      required: 'Password is required',
      minLength: 'Password at least 6 characters',
      alphaNumeric: 'Password only allowed alphanumeric and special characters',
    },
    wrongEmailOrPassword: 'Wrong email or password',
    validEmailAddress: 'This field must be valid email address',
    validPhoneNumber:
      'Please enter valid WhatsApp number, min 10 digit numbers',
    fieldAlphabet: 'must only contain alphabetic characters',
    fieldAlphaNumeric: 'must only contain alphanumeric characters',
    fieldNumeric: 'must only contain numeric characters',
  },
}
