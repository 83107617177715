<template>
  <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <h2 class="mr-auto text-lg font-medium">Tabulator</h2>
    <div class="mt-4 flex w-full sm:mt-0 sm:w-auto">
      <button class="btn btn-primary mr-2 shadow-md">Add New Product</button>
      <Dropdown class="ml-auto sm:ml-0">
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <FilePlusIcon class="mr-2 h-4 w-4" /> New Category
            </DropdownItem>
            <DropdownItem>
              <UserPlusIcon class="mr-2 h-4 w-4" /> New Group
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
  <!-- BEGIN: HTML Table Data -->
  <div class="intro-y box mt-5 p-5">
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
      <form id="tabulator-html-filter-form" class="sm:mr-auto xl:flex">
        <div class="items-center sm:mr-4 sm:flex">
          <label class="mr-2 w-12 flex-none xl:w-auto xl:flex-initial"
            >Field</label
          >
          <select
            id="tabulator-html-filter-field"
            v-model="filter.field"
            class="form-select mt-2 w-full sm:mt-0 sm:w-32 sm:w-auto 2xl:w-full"
          >
            <option value="name">Name</option>
            <option value="category">Category</option>
            <option value="remaining_stock">Remaining Stock</option>
          </select>
        </div>
        <div class="mt-2 items-center sm:mr-4 sm:flex xl:mt-0">
          <label class="mr-2 w-12 flex-none xl:w-auto xl:flex-initial"
            >Type</label
          >
          <select
            id="tabulator-html-filter-type"
            v-model="filter.type"
            class="form-select mt-2 w-full sm:mt-0 sm:w-auto"
          >
            <option value="like" selected>like</option>
            <option value="=">=</option>
            <option value="<">&lt;</option>
            <option value="<=">&lt;=</option>
            <option value=">">></option>
            <option value=">=">>=</option>
            <option value="!=">!=</option>
          </select>
        </div>
        <div class="mt-2 items-center sm:mr-4 sm:flex xl:mt-0">
          <label class="mr-2 w-12 flex-none xl:w-auto xl:flex-initial"
            >Value</label
          >
          <input
            id="tabulator-html-filter-value"
            v-model="filter.value"
            type="text"
            class="form-control mt-2 sm:mt-0 sm:w-40 2xl:w-full"
            placeholder="Search..."
          />
        </div>
        <div class="mt-2 xl:mt-0">
          <button
            id="tabulator-html-filter-go"
            type="button"
            class="btn btn-primary w-full sm:w-16"
            @click="onFilter"
          >
            Go
          </button>
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary mt-2 w-full sm:mt-0 sm:ml-1 sm:w-16"
            @click="onResetFilter"
          >
            Reset
          </button>
        </div>
      </form>
      <div class="mt-5 flex sm:mt-0">
        <button
          id="tabulator-print"
          class="btn btn-outline-secondary mr-2 w-1/2 sm:w-auto"
          @click="onPrint"
        >
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <Dropdown class="w-1/2 sm:w-auto">
          <DropdownToggle class="btn btn-outline-secondary w-full sm:w-auto">
            <FileTextIcon class="mr-2 h-4 w-4" /> Export
            <ChevronDownIcon class="ml-auto h-4 w-4 sm:ml-2" />
          </DropdownToggle>
          <DropdownMenu class="w-40">
            <DropdownContent>
              <DropdownItem @click="onExportCsv">
                <FileTextIcon class="mr-2 h-4 w-4" /> Export CSV
              </DropdownItem>
              <DropdownItem @click="onExportJson">
                <FileTextIcon class="mr-2 h-4 w-4" /> Export JSON
              </DropdownItem>
              <DropdownItem @click="onExportXlsx">
                <FileTextIcon class="mr-2 h-4 w-4" /> Export XLSX
              </DropdownItem>
              <DropdownItem @click="onExportHtml">
                <FileTextIcon class="mr-2 h-4 w-4" /> Export HTML
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="scrollbar-hidden overflow-x-auto">
      <div
        id="tabulator"
        ref="tableRef"
        class="table-report table-report--tabulator mt-5"
      ></div>
    </div>
  </div>
  <!-- END: HTML Table Data -->
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import { createIcons, icons } from 'lucide'
import Tabulator from 'tabulator-tables'
import dom from '@left4code/tw-starter/dist/js/dom'

const tableRef = ref()
const tabulator = ref()
const filter = reactive({
  field: 'name',
  type: 'like',
  value: '',
})

const imageAssets = import.meta.globEager(
  `/src/assets/images/*.{jpg,jpeg,png,svg}`
)
const initTabulator = () => {
  tabulator.value = new Tabulator(tableRef.value, {
    ajaxURL: 'https://dummy-data.left4code.com',
    ajaxFiltering: true,
    ajaxSorting: true,
    printAsHtml: true,
    printStyled: true,
    pagination: 'remote',
    paginationSize: 10,
    paginationSizeSelector: [10, 20, 30, 40],
    layout: 'fitColumns',
    responsiveLayout: 'collapse',
    placeholder: 'No matching records found',
    columns: [
      {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        resizable: false,
        headerSort: false,
      },

      // For HTML table
      {
        title: 'PRODUCT NAME',
        minWidth: 200,
        responsive: 0,
        field: 'name',
        vertAlign: 'middle',
        print: false,
        download: false,
        formatter(cell) {
          return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().name
                }</div>
                <div class="text-slate-500 text-xs whitespace-nowrap">${
                  cell.getData().category
                }</div>
              </div>`
        },
      },
      {
        title: 'IMAGES',
        minWidth: 200,
        field: 'images',
        hozAlign: 'center',
        vertAlign: 'middle',
        print: false,
        download: false,
        formatter(cell) {
          return `<div class="flex lg:justify-center">
                  <div class="intro-x w-10 h-10 image-fit">
                    <img alt="Gromura - SCM" class="rounded-full" src="${
                      imageAssets[
                        '/src/assets/images/' + cell.getData().images[0]
                      ].default
                    }">
                  </div>
                  <div class="intro-x w-10 h-10 image-fit -ml-5">
                    <img alt="Gromura - SCM" class="rounded-full" src="${
                      imageAssets[
                        '/src/assets/images/' + cell.getData().images[1]
                      ].default
                    }">
                  </div>
                  <div class="intro-x w-10 h-10 image-fit -ml-5">
                    <img alt="Gromura - SCM" class="rounded-full" src="${
                      imageAssets[
                        '/src/assets/images/' + cell.getData().images[2]
                      ].default
                    }">
                  </div>
              </div>`
        },
      },
      {
        title: 'REMAINING STOCK',
        minWidth: 200,
        field: 'remaining_stock',
        hozAlign: 'center',
        vertAlign: 'middle',
        print: false,
        download: false,
      },
      {
        title: 'STATUS',
        minWidth: 200,
        field: 'status',
        hozAlign: 'center',
        vertAlign: 'middle',
        print: false,
        download: false,
        formatter(cell) {
          return `<div class="flex items-center lg:justify-center ${
            cell.getData().status ? 'text-success' : 'text-danger'
          }">
                <i data-lucide="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().status ? 'Active' : 'Inactive'
                }
              </div>`
        },
      },
      {
        title: 'ACTIONS',
        minWidth: 200,
        field: 'actions',
        responsive: 1,
        hozAlign: 'center',
        vertAlign: 'middle',
        print: false,
        download: false,
        formatter() {
          const a = dom(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-lucide="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>
                <a class="flex items-center text-danger" href="javascript:;">
                  <i data-lucide="trash-2" class="w-4 h-4 mr-1"></i> Delete
                </a>
              </div>`)
          dom(a).on('click', function () {
            // On click actions
          })

          return a[0]
        },
      },

      // For print format
      {
        title: 'PRODUCT NAME',
        field: 'name',
        visible: false,
        print: true,
        download: true,
      },
      {
        title: 'CATEGORY',
        field: 'category',
        visible: false,
        print: true,
        download: true,
      },
      {
        title: 'REMAINING STOCK',
        field: 'remaining_stock',
        visible: false,
        print: true,
        download: true,
      },
      {
        title: 'STATUS',
        field: 'status',
        visible: false,
        print: true,
        download: true,
        formatterPrint(cell) {
          return cell.getValue() ? 'Active' : 'Inactive'
        },
      },
      {
        title: 'IMAGE 1',
        field: 'images',
        visible: false,
        print: true,
        download: true,
        formatterPrint(cell) {
          return cell.getValue()[0]
        },
      },
      {
        title: 'IMAGE 2',
        field: 'images',
        visible: false,
        print: true,
        download: true,
        formatterPrint(cell) {
          return cell.getValue()[1]
        },
      },
      {
        title: 'IMAGE 3',
        field: 'images',
        visible: false,
        print: true,
        download: true,
        formatterPrint(cell) {
          return cell.getValue()[2]
        },
      },
    ],
    renderComplete() {
      createIcons({
        icons,
        'stroke-width': 1.5,
        nameAttr: 'data-lucide',
      })
    },
  })
}

// Redraw table onresize
const reInitOnResizeWindow = () => {
  window.addEventListener('resize', () => {
    tabulator.value.redraw()
    createIcons({
      icons,
      'stroke-width': 1.5,
      nameAttr: 'data-lucide',
    })
  })
}

// Filter function
const onFilter = () => {
  tabulator.value.setFilter(filter.field, filter.type, filter.value)
}

// On reset filter
const onResetFilter = () => {
  filter.field = 'name'
  filter.type = 'like'
  filter.value = ''
  onFilter()
}

// Export
const onExportCsv = () => {
  tabulator.value.download('csv', 'data.csv')
}

const onExportJson = () => {
  tabulator.value.download('json', 'data.json')
}

const onExportXlsx = () => {
  const win = window
  win.XLSX = xlsx
  tabulator.value.download('xlsx', 'data.xlsx', {
    sheetName: 'Products',
  })
}

const onExportHtml = () => {
  tabulator.value.download('html', 'data.html', {
    style: true,
  })
}

// Print
const onPrint = () => {
  tabulator.value.print()
}

onMounted(() => {
  initTabulator()
  reInitOnResizeWindow()
})
</script>
